import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { BasePage } from '../../shared/basePage';
import { ReportingService } from '../../_services/reporting.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountRoles } from '../../_enums/accountRoles';
import { CrawlData } from '../../_models/Requests/Results/crawlData';
import { CrawlStatus } from '../../_enums/crawlStatus';
import { TableUtil } from '../../_utilities/tableUtilities';
import { ColumnSettings } from '../../_models/common/editorData';
import { ListDetails } from '../../_models/Requests/Lists/listDetails';

@Component({
  selector: 'app-list-data',
  templateUrl: './list-data.component.html',
  styleUrls: ['./list-data.component.scss']
})
export class ListDataComponent extends BasePage implements OnInit, AfterViewInit, OnChanges {
  public dataSource: MatTableDataSource<any>;
  @Input() listId: number;
  @Output() gridDataChanged = new EventEmitter();


  private defaultDisplayedColumns: string[] = [];
  public displayedColumns: string[] = this.defaultDisplayedColumns;
  public colSettings: ColumnSettings[] = [];
  public extColumns: ColumnSettings[] = [];
  public responseCrawlData: any[];
  public tableElement: any;
  @ViewChild('tbl') tblEl: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public _router: Router, public _reportingService: ReportingService, private activatedRoute: ActivatedRoute) {

    super(AccountRoles.User, null, _router)
    this.isSubmitting = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    const listIdProp = changes['listId'];

    if (listIdProp && listIdProp.currentValue) {
      this.listId = listIdProp.currentValue;
    }

    if (listIdProp.previousValue != listIdProp.currentValue) {
      this.displayedColumns = this.defaultDisplayedColumns;
      this.colSettings = [];
      this.LoadData();
    } 
  } 
  populateGrid() {
    this.isSubmitting = true;
    this.callService(this._reportingService.getListDetails(this.listId), data => {
      let listData: ListDetails = data.listData;
      if (data.listData) {
        let listHeaders = this.parseHeaderData(listData.headersData);  //debug and make sure front end matches (use loop around displayColumns to generate table row cell els)

        // this.sort.sort({ id: 'crawlDataId', start: 'asc', disableClear: false });
        this.responseCrawlData = this.buildCrawlDataList(listData.listData, listHeaders, 'listData');
        let responseResults = this.responseCrawlData;

        //Add dynamic column headers to displayed columns
        this.displayedColumns = listHeaders? this.defaultDisplayedColumns.concat(Object.keys(listHeaders).map(p => { return listHeaders[p]; })) : this.displayedColumns;

        // //Replace ID property with name/id properties
        // responseResults = this.buildTypeValueRows(responseResults, CrawlStatus, 'crawlStatusId', 'crawlStatus');

        //Configure extra column setings or IDs/Dates and other known types
        this.colSettings = this.formatColumnSettings(this.displayedColumns);

        // //Configure column headers, buttons, filtering, etc
        // this.extColumns = this.formatExtColumnHeaders();

        //Set data/Sort/Pagination
        this.initializeTable(responseResults)
        //TODO: Add Column NgIF HERE SO we dont have premature rendering issues (client errors are periodically coming due to our dyanmic column generation)
      }
      else {
        this.dataSource = new MatTableDataSource([]);
        this.isSubmitting = false;
      }
    });
  }
  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.listId = params['id'] || this.listId;
      if (params['id']) {
        this.LoadData()
      }
    })
  }
  LoadData() {
    this.populateGrid();
  }

  initializeTable(data: any[], defaultPageSize: number = 10) {
    setTimeout(x => { 
      this.paginator._changePageSize(defaultPageSize);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sortChange.subscribe(x=> {
        this.emitGridData();
      });
      this.dataSource.paginator = this.paginator;
      this.isSubmitting = false;
      setTimeout(x => {
        this.tableElement = this.tblEl;
        this.emitGridData();
      }, 1000)
    }, 0);
  }

  emitGridData(){
    //Convert to property names (TODO: do this in intiailization and avoid point of failure on emit? )
    // this.dataSource.filteredData.map(x => {
    //   let rec = {};
    //   this.colSettings.forEach((col, i) => {
    //     rec[col.data] = x[i.toString()];
    //   })

    //   gridData.push(rec);
    // })
    let dataToEmit = {gridData: this.dataSource.filteredData, columnSettings: this.colSettings} //TODO: make this a class
    this.gridDataChanged.emit(dataToEmit)
  }
  parseHeaderData(headerData) {
    return JSON.parse(headerData);
  }
  parseCrawlDataRow(dataRow, dataPropertyName) {
    if (Array.isArray(dataRow)) {
      return JSON.parse('[' + dataRow.map(el => { return el[dataPropertyName] || '{}' }).join(',') + ']');
    }
    //string
    return JSON.parse(dataRow || '{}');
  }

  formatColumnSettings(displayedColumns): ColumnSettings[] {
    let firstIdCol = false;
    let settings:ColumnSettings[] = [];
    displayedColumns.forEach(c => {
      let newCol = { data: c, displayName: this.addSpaceBeforeCaps(this.capitalizeFirstLetter(c)), width: 100, type: 'text',dataFormat: null }

      //If contains 'status', remove everythign before status
      // let strIndexestoRemove = ['status'];
      // strIndexestoRemove.forEach(str => {
      //   let statusIndex = newCol.displayName.toLowerCase().indexOf(str);
      //   if (statusIndex > 0) {
      //     newCol.displayName = str;
      //   }
      // });

      //Handle IDs
      if (newCol.displayName.substring(newCol.displayName.length, newCol.displayName.length - 2) == 'Id') {
        if (!firstIdCol) {
          newCol.displayName = 'ID';
          firstIdCol = true;
        }
        else {
          newCol.displayName = newCol.displayName.substring(0, newCol.displayName.length - 2).trim();
        }

        newCol.type = 'numeric';
        newCol.width = 75;
        // newCol.numericFormat = {
        //   pattern: '0.0000'
        // }
      }

      //Handle dates
      if (c.substring(0, 2) == 'dt') {
        newCol.displayName = newCol.displayName.substring(2, newCol.displayName.length).trim();
        newCol.type = 'date';
      }
      settings.push(newCol)
    })

    return settings;
  }
  capitalizeFirstLetter(val) {
    return (val.charAt(0).toUpperCase() + val.substring(1));
  }
  addSpaceBeforeCaps(val) {
    return val.replace(/([A-Z])/g, ' $1').trim().replace('U R L', 'URL').replace('I D', 'ID');
  }
 
  buildCrawlDataList(responseDataRows: any, responseHeaderData: any, dataPropName: string) {
    if (responseHeaderData) {
      let responseCrawlDataRows = this.parseCrawlDataRow(responseDataRows, dataPropName);

      //Flatten array (negates all array checks)
      if (Array.isArray(responseCrawlDataRows[0])) {
        responseCrawlDataRows = responseCrawlDataRows.flat(Infinity);
      }
      
      Object.keys(responseHeaderData).forEach((p) => {
        responseCrawlDataRows.forEach((el, i) => {
          let newPropertyName = responseHeaderData[p];

          // Array data results were generated so step one level into array (if we havent flattened it already)
          if (Array.isArray(el)){

            //object has no values, need to reinitialize
            if (el.length > 0) {
              el.forEach(rec=>{
                this._reportingService.renameObjectProperty(rec, p, newPropertyName)
              })
            }
            //add static columns adjacent to response data
            this.displayedColumns.forEach(c => {
              el.forEach(r =>{
                r[c] = responseCrawlDataRows[i][c];
               })
            })
          }

          //Treat as one record
          else {
            //Rename existing properties in response data to friendly names using response headers
            if (el && typeof(el[p]) != 'undefined') {
              this._reportingService.renameObjectProperty(el, p, newPropertyName)
            }
            //object has no values, so we initiailize
            else {
              el = {};
              el[newPropertyName] = ''

            }

            //add static columns adjacent to response data (If data is not already set)
            this.colSettings.forEach(c => el[c.data] = el[c.data] || (responseCrawlDataRows[i]? responseCrawlDataRows[i][c.data] : ''))
          }
        })
      });
      return responseCrawlDataRows;
    }
    return responseDataRows
  };

  // buildTypeValueRows(data: any[], enums: any, oldPropertyName: string, newPropertyName: string) {
  //   data.filter(x => {
  //     if (Array.isArray(x)){
  //       x.forEach(r=>{
  //         this._reportingService.renameObjectProperty(r, oldPropertyName, newPropertyName);
  //         r.crawlStatus = { name: this.returnEnumFlags(enums, r[newPropertyName]), id: r[newPropertyName] };
  //       })
  //     }
  //     else{
  //       this._reportingService.renameObjectProperty(x, oldPropertyName, newPropertyName);
  //       x.crawlStatus = { name: this.returnEnumFlags(enums, x[newPropertyName]), id: x[newPropertyName] };
  //     }
      
  //   })
  //   this.displayedColumns[this.displayedColumns.indexOf(oldPropertyName)] = newPropertyName;
  //   return data;
  // }

  ngAfterViewInit() {
    //Highlight cells individually
    // $('tr td').attr('tabindex',"0");
  }
}
