import { Directive, HostListener, EventEmitter, Output, ElementRef, Input  } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Directive({
  selector: '[appDraganddrop]',
})

export class DraganddropDirective {
  @Input() set allowedExtensions(data: Array<string>){
    this._allowedExtensions = data ? data.filter(x => x.toLowerCase()) : data;
  }
  get allowedExtensions(): Array<string>{
    return this._allowedExtensions
  }
  
  @Output() private filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() private filesInvalidEmiter: EventEmitter<File[]> = new EventEmitter();
  public background: string;
  private droppingClass = 'dropping'
  private _allowedExtensions: Array<string>
  
  constructor(private _elemRef: ElementRef, private snackBar: MatSnackBar) { 
  }

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    this._elemRef.nativeElement.classList.add(this.droppingClass)
    evt.preventDefault();
    evt.stopPropagation();1
  }

  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    this._elemRef.nativeElement.classList.remove(this.droppingClass)
    evt.preventDefault();
    evt.stopPropagation();
  }

  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this._elemRef.nativeElement.classList.remove(this.droppingClass)
    let files = []
    files = evt.dataTransfer.files;
    let valid_files: Array<File> = [];
    let invalid_files: Array<File> = [];
    for(let i = 0; i < files.length; i++) {
      let file = files[i];
      let ext = file.name.split('.')[file.name.split('.').length - 1];
      if (this.allowedExtensions.lastIndexOf(ext) != -1) {
        valid_files.push(file);
      } else {
        invalid_files.push(file);        
        let extensionLbl = this.allowedExtensions.map((e,i, arr) => '.' + e.toUpperCase() +  ((i == (arr.length-2) ? ' or ': ( i < (arr.length - 2) ?  ', ' : '')))).join('')
        this.snackBar.open('Only ' + extensionLbl + ' files are accepted', '×', { panelClass: 'alert-danger', verticalPosition: 'top', duration: 3000 });
      }
    }
    
      this.filesChangeEmiter.emit(valid_files);
      this.filesInvalidEmiter.emit(invalid_files);
    }
}
