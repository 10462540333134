import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DefaultApplicationImagePath } from './../../_enums/organizations';
import { ComponentBase } from './../../_base/component.base';
import { AuthenticatedStorage } from './../../_helpers/localStorage';
@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent extends ComponentBase implements OnInit {
  color = 'defaultdark';
  showSettings = false;
  showMinisidebar = false;
  showDarktheme = false;

  public innerWidth: any;

  public config: PerfectScrollbarConfigInterface = {};

  public organizationImagePath: string;
  public defaultOrgImagePath = DefaultApplicationImagePath

  constructor(public router: Router) {
    super()
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard']);
    }
    let userData = new AuthenticatedStorage().userData();
    this.organizationImagePath = userData? userData.organizationImagePath : ''
    this.handleLayout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleLayout();
  }

  toggleSidebar() {
    this.showMinisidebar = !this.showMinisidebar;
  }

  handleLayout() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.showMinisidebar = true;
    } else {
      this.showMinisidebar = false;
    }
  }
}
