import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../shared/basePage';
import { AccountRoles } from '../../_enums/accountRoles';
import { ReportingService } from '../../_services/reporting.service';
import { CrawlDetails } from '../../_models/Requests/Results/crawlDetails';
import { ActivatedRoute } from '@angular/router';
import { CrawlerType } from '../../_enums/crawlerTypes';
import { NewList } from '../../_models/Requests/Lists/newList';
import { ListDetails } from '../../_models/Requests/Lists/listDetails';

@Component({
  selector: 'app-list-details',
  templateUrl: './list-details.component.html',
  styleUrls: ['./list-details.component.css']
})
export class ListDetailsComponent extends BasePage implements OnInit {
  @Input() listId: number;
  public list: ListDetails;
  constructor(private _reportingService: ReportingService, private activatedRoute: ActivatedRoute) {
    super(AccountRoles.User);
    this.isLoading = true;
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.listId = params['id'] || this.listId;
      this.callService(this._reportingService.getListDetails(this.listId), data => {
        this.list = data.listData;
        this.isLoading = false;
      });
    })
  }
}
