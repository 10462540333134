import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@root/environments/environment';
import { AuthenticatedStorage } from './localStorage';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  protected apiUrl: string = environment.apiEndPoint;
  constructor(private authenticatedStorage: AuthenticatedStorage) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(`Request for ${req.urlWithParams} started...`);
    let authenticatedUser = this.authenticatedStorage.userData();
    let newUrl = (req.url.indexOf('assets/') == -1? this.apiUrl + '/' + req.url : req.url)
    let newReq = req.clone({
      url: newUrl,
      setHeaders:
      {
        authToken: (authenticatedUser ? authenticatedUser.access_token : ''),
        accountId: (authenticatedUser ? authenticatedUser.accountId.toString() : '')
      }
    }) 

    return next.handle(newReq).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.status == 401){
          this.authenticatedStorage.clearData();
          window.location.reload;
        }
        // console.log(`Request for ${newReq.urlWithParams} completed...`);
      }
      return event;
    }),
      catchError((error: HttpErrorResponse) => {
        const started = Date.now();
        const elapsed = Date.now() - started;

        //Unathorized API endpoint was attempted, so clear the session
        if (error.status == 401){
          this.authenticatedStorage.clearData();
          location.reload(true);
        }
        
        console.log(`Request for ${req.urlWithParams} failed after ${elapsed} ms.`);
        // debugger;
        return throwError(error);
      })
    );

  }
}