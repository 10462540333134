import { Subject, Observable, of, from } from "rxjs";
import { map, tap, takeUntil } from 'rxjs/operators'
import { OnDestroy, Injectable } from "@angular/core";

export class ComponentBase implements OnDestroy {
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    public submitErrorMsg : string;
    public errorCode: number;
    public isLoading = false;
    public isSubmitting = false;
    public isSubmitted = false;
    public sub: any;
    public isDestroyed:boolean;
    constructor() { }

    protected callService<T>(service: Observable<T>,
        successHandler: (data) => void = null,
        errorHandler: (err) => void = null): void {
        service.pipe(takeUntil(this.ngUnsubscribe.asObservable()))
            .subscribe(
                data => {
                    if (successHandler) {
                        successHandler(data);
                    }
                },
                error => {
                    if (errorHandler) {
                        errorHandler(error);
                    }
                });
    }
  
    ngOnDestroy() {
        this.isDestroyed = true;
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
  
}