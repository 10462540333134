export class CommonUtil {
  public static returnEnumFlags(enums, value) {
    let res = [];
    for (var e in enums) {
      if (value & parseInt(e)) {
        res.push(enums[e]);
      }
    }
    return res.join(',');
  }

  public static flatDeep(arr, d = 1) {
    return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? this.flatDeep(val, d - 1) : val), []) : arr.slice();
  };

  public static flatSingleLevel(arr) {
    return arr.reduce((acc, val) => acc.concat(val), []);
  };
  
  public static objectContainsChild(objA, objB){
    return (JSON.stringify(objA).indexOf(JSON.stringify(objB)) > -1);
  }
  public static returnPropertyMatch(oldObj: object, newObj: object) {
    let invalidProps = [];
    if (oldObj == {}) {
      return Object.keys(newObj);

    }
    else if (newObj == {}) {
      return invalidProps;
    }
    //return only properties of new object are updated from the old object AND if each property has value and is string/integer
    else if (typeof(newObj) == 'undefined'){
      return invalidProps;
    }
    else if (typeof(oldObj) == 'undefined'){
        if (typeof(newObj) == 'undefined'){
          return invalidProps
        }
        else{          
          return Object.keys(newObj);
        }
    }
    Object.keys(newObj).forEach(prop => {
      // if (typeof(oldObj[prop]) == 'object' || typeof(newObj[prop] == 'object')){
        
      //   //loop through child properties
      //   console.log('property match loop', {oldObj: oldObj[prop], newObj: newObj[prop]})
      //   return this.returnPropertyMatch(oldObj[prop], newObj[prop])
      //   // newObj[prop] != "" && newObj[prop] != null && typeof(newObj[prop]) != undefined
      // }

      if (oldObj[prop] != newObj[prop]) {// && (newObj[prop] || Number.isInteger(newObj[prop]))) {
        invalidProps.push(prop);
      }
    })

    return invalidProps;
  }

}