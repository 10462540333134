import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CrawlerSettings } from '../../_helpers/crawlerSettings';
import { CrawlerProperties } from '../../_models/common/crawlerProperties';

@Component({
  selector: 'app-group-count-minimum',
  templateUrl: './group-count-minimum.component.html',
  styleUrls: ['./group-count-minimum.component.css']
})
export class GroupCountMinimumComponent implements OnInit, OnChanges {
  @Input() crawlerTypeId: number;
  @Input() disabled: boolean = false;
  public crawlerProperties: CrawlerProperties;
  public inputVal: number;
  public helpMsg: string = '';
  @Output() valueChanged = new EventEmitter();
  constructor(public crawlerSettings: CrawlerSettings) {
  }

  ngOnInit() {
    this.initializeCrawlerProperties();
  }
  ngOnChanges(changes: SimpleChanges) {
    const disabledProp = changes['disabled'];
    if (disabledProp && (disabledProp.currentValue == true || disabledProp.currentValue == false)){
      this.disabled = disabledProp.currentValue;
    }

  }
  initializeCrawlerProperties(){
    this.crawlerProperties = this.crawlerSettings.getCrawlerProperties(this.crawlerTypeId)
    this.inputVal = this.crawlerProperties.groupCountMinimum;
    this.helpMsg = 'Minimum ' + (this.crawlerProperties.unitName || '') + 'using a radius (requires origin zip code)';
  }
  ngAfterViewInit() {
    this.emitChanges();
  }
  
  inputChange(e) {
    if (e) {
      if (this.inputVal != null && this.inputVal < 1 && this.inputVal.toString() != '-'){
        this.inputVal = this.crawlerProperties.groupCountMinimum;
      }
      this.emitChanges();
    }
  }

  emitChanges() {
    this.valueChanged.emit(this.inputVal);
  }
}
