import { Component, OnInit } from '@angular/core';
import { CrawlerService } from '@services/crawlers.service';
import { Router } from '@angular/router';
import { BasePage } from '@shared/basePage';
import { AccountRoles } from '@enums/accountRoles';
import { CrawlerType } from '../../_enums/crawlerTypes';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-commodity-data',
  templateUrl: './commodity-data.component.html',
  styleUrls: ['./commodity-data.component.css']
})
export class CommodityDataComponent extends BasePage implements OnInit {
  public errorMessage: string = '';

  public showMapUrl: string = '';
  public crawlerType: CrawlerType = CrawlerType.CommodityData;
  public recordGroupLimit:number = null;
  public listId: number = null;
  public crawlRequestId: number = null;

  constructor(private _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar) {
    super(AccountRoles.User, null, router, snackbar);
  }
  ngOnInit() {
  }

  submit() {
  this.isSubmitting = true;
  this.errorMessage = '';
  let newHeaders = [];
  let newData = [{
  }];
  this.callService(this._crawlerService.submitCrawlRequest(this.crawlerType, newHeaders, newData, this.recordGroupLimit, this.listId, this.crawlRequestId), data => {
    if (data.isSuccess && data.crawlRequestId) {
      this.toggleToastMessage('Request submitted! Loading results...')
      //TODO: Fire request that doesnt care about getting it back
      setTimeout(x => {
        this.router.navigate(['/results/' + data.crawlRequestId])
      }, 1000);
    }
    else{
      this.toggleToastMessage('There was a problem with your submission, please try again later.', null, null, 'alert-warning ')
      console.log('crawl request submission error:', { request: { requestHeaders: newHeaders, requestData: newData }, response: data })
    }
  });
}
}
