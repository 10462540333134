import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChange, HostListener, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnChanges {
  modalIsShown: boolean = false;
  isLoading:boolean = false;
  @Input() visible: boolean = false;
  @Input() submitText: string;
  @Input() cancelText: string;
  @Input() bodyWidth: string;
  @Input() maxWidth: string;
  @Input() static: boolean = false;
  @Output() visibleChanged = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @ViewChild('modal') modal: any;
  constructor() {
  }

  // When the user clicks anywhere outside of the modal, close it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (event.target == this.modal.nativeElement) {
      this.toggleModal(false);
      this.togglCancelClose();
    }
  }

  //Trigger modal on input change
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.visible.currentValue && !this.modalIsShown) {
      this.toggleModal(true);
    }
    else if (!changes.visible.currentValue && this.modalIsShown) {
      this.toggleModal(false);
      this.togglCancelClose();
    }
  }

  toggleModal(show: boolean) {
    //Open the modal
    if (show) {
      //show
      this.isLoading = false;
      this.modal.nativeElement.style.display = "block";
    }

    //Close the modal
    else {
      //hide
      $(this.modal.nativeElement).fadeOut(150);
    }
    this.modalIsShown = show;
    this.visibleChanged.emit(show)
  }

  cancel(event){
   this.togglCancelClose();
  }
  submit(event){
    this.isLoading = true;
    this.onSubmit.emit(event);
  }
  ngOnInit() {
    
  }
  togglCancelClose(){
    this.isLoading = false;
    this.onCancel.emit(event);
  }

}
