import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { Router } from '@angular/router'; 
import { ActivatedRoute } from "@angular/router";
import { LoginService } from '../_services/login.service';
import { ComponentBase } from "../_base/component.base";
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent  extends ComponentBase implements OnInit{
  public loginForm: FormGroup;
  public hide = true;
  private redirectUrl: string = 'account';
  constructor(public fb: FormBuilder, private loginService: LoginService, private route: ActivatedRoute, private router:Router, private snackBar: MatSnackBar) {
    super();
  }

  ngOnInit() {
    //Get redirect url
    this.route.queryParams.subscribe(params => {
      let paramUrl = params["red"];
      if (paramUrl) {
        this.redirectUrl = paramUrl;
      }
    })

    //Login form
    this.initializeForm();
  }

  initializeForm() {
    this.loginForm = this.fb.group({
      emailAddress: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      rememberMe: false
    });
  }

  public onLoginFormSubmit(values:Object):void {
    if (this.loginForm.valid) {
      this.snackBar.open('Logging in', '×', { panelClass: 'success', verticalPosition: 'top', duration: 10000 });
      this.isSubmitting = true;
      this.callService(this.loginService.login(this.loginForm.controls["emailAddress"].value, this.loginForm.controls["password"].value, this.loginForm.controls["rememberMe"].value),
        res => {
          if (res.isSuccess){
            this.snackBar.open('Success', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
            setTimeout(x => {
              let trimmedRedirectUrl = this.stripTrailingSlash(this.redirectUrl.trim());
              if (trimmedRedirectUrl && trimmedRedirectUrl.substring(0,1) == '/'){
                trimmedRedirectUrl = trimmedRedirectUrl.substring(1);
              }
              document.location.href = this.stripBeginningSlash(document.location.origin) + '/' + trimmedRedirectUrl;
            }, 750)
            
          }
          else{
            this.snackBar.dismiss();
            this.submitErrorMsg = res.errorMsg;
            this.isSubmitting = false;
          }
        }
      )
    }
  }
  stripTrailingSlash = (str) => {
    return (str || '').endsWith('/') ?
        str.slice(0, -1) :
        str;
};
  stripBeginningSlash = (str) => {
    return (str || '').startsWith('/') ?
      str.slice(0, -1) :
      str;
  };

}
