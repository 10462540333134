import { AbstractControl, FormGroup, FormArray } from "@angular/forms";

export class FormUtilities{
    public static isValid(valueStatus: string){
        return (valueStatus == "VALID"? true : false);
    }
    
    public static triggerValidation(control: AbstractControl) {
        if (control instanceof FormGroup) {
            const group = (control as FormGroup);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c);
            }
        }
        else if (control instanceof FormArray) {
            const group = (control as FormArray);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c);
            }
        }

        control.updateValueAndValidity({ onlySelf: false });
    }
      
    public static removeFileNameExtension(fileName: string) {
        if (!fileName) {
            return fileName;
        }
        return fileName.substr(0, fileName.lastIndexOf('.')) || fileName;
    }
    
}