import { ReportingService } from "../_services/reporting.service";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { CrawlerService } from "../_services/crawlers.service";
import { CrawlStatus } from "../_enums/crawlStatus";

export class CrawlerUtilities{
    static nonCompletedRows(data: any[]): any[]{
        return data.filter(x => (x.crawlDataId > 0 && x.crawlDataId != '' && ((x.crawlStatus.id & CrawlStatus.Complete) != CrawlStatus.Complete) && ((x.crawlStatus.id & CrawlStatus.Failed) != CrawlStatus.Failed) ) ); //check against grid row object property ID value and string value
      }
      static isCompletedRequest(data: any[]):boolean {
        let filteredData = this.nonCompletedRows(data);
        return !(filteredData && filteredData.length > 0);
      }
      
}