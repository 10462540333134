import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import Handsontable from 'handsontable';
import { HotTableComponent } from '@handsontable/angular';
import { Observable } from 'rxjs';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { TableUtil } from '../../_utilities/tableUtilities';
import { HeaderSelection } from '../../_models/common/tableData';
import { EditorOutputData, ColumnSettings } from '../../_models/common/editorData';
import { ColumnData } from '../../_models/common/commonData';

@Component({
  selector: 'app-table-editor',
  templateUrl: './table-editor.component.html',
  styleUrls: ['./table-editor.component.css']
})
export class TableEditorComponent implements OnInit, OnChanges {
  @Input() headerSelecRowData: any[] = []
  @Input() headerColumnButton: string = null;
  @Input() rowHeaders: boolean = true;
  @Input() sorting: boolean = true;
  @Input() columns: ColumnSettings[] = []
  @Input() dataset: any[] = [];
  @Input() pageSize: number = 50;
  @Input() manualColumnMove: boolean = true;
  @Input() allowInsertColumn: boolean = true;
  @Input() useInfiniteScroll: boolean = true;
  @Input() columnRenderersToSkip: number = 1;
  @Input() minimumSpareRows: number = 1;
  @Input() minimumColumnCount: number = null;
  @Output() onHeaderSelectRowChanged = new EventEmitter<HeaderSelection[]>();
  @Output() onColumnButtonClicked = new EventEmitter();
  @Output() loaded = new EventEmitter();
  @Output() dataChanged = new EventEmitter<EditorOutputData>();

  @ViewChild('hot') hot: any;
  public headerSelectedValues: any[] = []
  public headerCheckedColumns: any[] = []
  public isInitialized: boolean = false;
  public settings: any = {};
  public scrollCallback;
  public currentPage = 0;
  public columnHeaders: string[] = []

  constructor() {
    this.scrollCallback = this.loadMoreRows.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {

    const headerSelectRowDataProp = changes['headerSelecRowData'];
    const rowHeadersProp = changes['rowHeaders'];
    const columnsProp = changes['columns'];
    const datasetProp = changes['dataset'];
    const sortingProp = changes['sorting'];
    const colRenderersToSkip = changes['columnRenderersToSkip'];
    const minimumColumnCountProp = changes['minimumColumnCount'];
    const useInfiniteScrollProp = changes['useInfiniteScroll'];
    const manualColumnMoveProp = changes['manualColumnMove'];
    const allowInsertColumnProp = changes['allowInsertColumn'];
    if (headerSelectRowDataProp && headerSelectRowDataProp.currentValue) {
      this.headerSelecRowData = headerSelectRowDataProp.currentValue;

      //initialize select values
      this.headerSelectedValues = [];
      this.headerSelecRowData.forEach((x,i) => {
        this.headerSelectedValues.push({ id: x.id, name: x.name, colIndex: null, required: x.required }); //TODO: Refactor to class
      })
    }

    if (rowHeadersProp && (rowHeadersProp.currentValue == true || rowHeadersProp.currentValue == false)) {
      this.rowHeaders = rowHeadersProp.currentValue
    }
    if (manualColumnMoveProp && (manualColumnMoveProp.currentValue == true || manualColumnMoveProp.currentValue == false)) {
      this.manualColumnMove = manualColumnMoveProp.currentValue
    }
    if (allowInsertColumnProp && (allowInsertColumnProp.currentValue == true || allowInsertColumnProp.currentValue == false)) {
      this.allowInsertColumn = allowInsertColumnProp.currentValue
    }
    if (useInfiniteScrollProp && (useInfiniteScrollProp.currentValue == true || useInfiniteScrollProp.currentValue == false)) {
      this.useInfiniteScroll = useInfiniteScrollProp.currentValue
    }
    if (sortingProp && (sortingProp.currentValue == true || sortingProp.currentValue == false)) {
      this.sorting = sortingProp.currentValue
    }
    if (sortingProp && (sortingProp.currentValue == true || sortingProp.currentValue == false)) {
      this.sorting = sortingProp.currentValue
    }
    if (colRenderersToSkip && colRenderersToSkip.currentValue && colRenderersToSkip.currentValue != null) {
      this.columnRenderersToSkip = colRenderersToSkip.currentValue;
    }
    if (minimumColumnCountProp && minimumColumnCountProp.currentValue && minimumColumnCountProp.currentValue != null) {
      this.minimumColumnCount = minimumColumnCountProp.currentValue;
    }
    if (columnsProp && columnsProp.currentValue && columnsProp.currentValue.length > 0) {
      let columnPropVal: ColumnSettings[] = columnsProp.currentValue;
      this.columns = columnPropVal.map(x => { return { data: x.data, displayName: x.displayName, type: x.type, width: (x.width || 0) + 100, dataFormat: (x.dataFormat != null ? x.dataFormat : (x.type == 'date' ? 'MM/DD/YYYY' : null)) } }); //numericFormat: x.type == 'numeric' { pattern: '0.00'} '0.00%'
    }
    if (datasetProp && datasetProp.currentValue) {
      this.dataset = datasetProp.currentValue
    }
    if (!this.isInitialized && this.dataset && this.columns) {
      this.isInitialized = this.initializeTable();
    }
    // else {
    //   if (columnsProp.currentValue != columnsProp.previousValue) {
    //     // this.hot.hotInstance.loadData(this.dataset);
    //     // this.hot.hotInstance.render();
    //   }
    // }


    //Destroy if there is no data
    if (this.dataset.length == 0 && this.columns.length == 0 && datasetProp.firstChange && columnsProp.firstChange) {
      $(this.hot.container.nativeElement).replaceWith('<div class="col-md-12 text-center"><i>No records to show</i></div>');
      this.loaded.emit(true);
    }

  }

  loadMoreRows() {
    return Observable.create(observer => {
      if (this.hot && this.hot.hotInstance && this.useInfiniteScroll) {
        this.currentPage++;
        var data = this.dataOffset(this.dataset.map(x => { return this.mapDataColumn(x) }), (this.pageSize * this.currentPage), 0);

        // Array.prototype.push.apply(dataSource, moreData);
        this.hot.hotInstance.loadData(data)
        this.hot.hotInstance.render();
      }

      observer.next();
      observer.complete();
    })
  }

  mapDataColumn(row: any) {
    let newRow = {};
    this.columns.forEach(x => {
      let rowProp = row[x.data];
      if (!rowProp){
        newRow[x.data] = '';
      }
      else {
        newRow[x.data] = (rowProp.name ? rowProp.name : rowProp)
      }
    })
    return newRow;
  }

  dataOffset(data: any[], limit: number, offset: number) {
    let newData = [];
    data.forEach((x, i) => {
      if (i > (offset || -1) && i <= (offset + limit)) {
        newData.push(x)
      }
    });
    return newData;
  }

  selectHeaderRenderer(_self, colIndex) {
    if (colIndex > this.columnRenderersToSkip - 1) {
      let el = document.createElement('select');
      let blankOptionEl = document.createElement('option');
      let existingSelection = this.headerSelectedValues.find(x=> x.colIndex == colIndex);
      let selectBlankVal = false;

      blankOptionEl.setAttribute('disabled','')
      el.appendChild(blankOptionEl);
      el.classList.add('form-control');
      el.setAttribute('required', '');
      el.setAttribute('onchange', 'document.getElementById("headerIndex").value = ' + colIndex + '; document.getElementById("headerValue").value = this.value; document.getElementById("selectChange").click()'); //'return co.optionChange($event, ' + colIndex + ')') //THIS SHOULD ATLEAST BE A UNQIUE ID W/ RANDOM NUMBER
      
      _self.headerSelecRowData.forEach(header => {
        let newOption = document.createElement('option');
        newOption.setAttribute('value', header.id);  //VALUE??? NOT ID
        newOption.innerText = header.name;
        
        //Use existing value if exists
        if (existingSelection && header.name == existingSelection.name){  //todo: make these classes (reuse header class but extend colIndex)
          newOption.setAttribute('selected', '');
          selectBlankVal = true;
        }

        el.appendChild(newOption);
      });
    
      //Set blank value as selected if no other value is present
      if (!selectBlankVal){
        blankOptionEl.setAttribute('selected', '');
      }

      if (!existingSelection && !this.selectionValuesValid()){
        el.classList.add('error');
      }
      let lbl = document.createElement('h5');
      lbl.innerText = this.columns[colIndex].displayName;
      return el.outerHTML + lbl.outerHTML;
    }
  }

  columnHeaderButtonRenderer(_self, colIndex) {
    let el = document.createElement('button');
    // el.style.width = '70%';
    el.style.height = '30px';
    el.style.padding = '4px';
    el.style.paddingLeft = '10px';
    el.style.paddingRight = '10px';
    el.className = 'btn btn-outline-primary btn-col-header';
    el.innerText = _self.headerColumnButton;
    el.setAttribute('onclick', 'document.getElementById("headerIndex").value = ' + colIndex + ';document.getElementById("headerClick").click()');
    let lbl = document.createElement('h5');
    lbl.innerText = this.columns[colIndex].displayName;
    return el.outerHTML + lbl.outerHTML;

  }


  public columnHeaderButtonClicked(e) {
    if (e) {
      let colIndex = parseInt(document.getElementById('headerIndex').getAttribute('value'));
      let col = this.columns[colIndex];

      let data = {  //todo: make this a class 
        name: col.data,
        displayName: col.displayName,
        data: this.hot.hotInstance.getDataAtCol(colIndex)
        // data: this.settings.data.map(x=> x[col.data]) 
      }
      this.onColumnButtonClicked.emit(data);
    }
    return true;
  }
  public optionChange(e) {
    if (e) {
      let tblColIndex = parseInt(document.getElementById('headerIndex').getAttribute('value'));
      let tblColValue = parseInt(document.getElementById('headerValue').getAttribute('value'));
      this.formatSelectOptionColData(tblColIndex, tblColValue)
    }
  }

  formatSelectOptionColData(tblColIndex, tblColValue){
    // let selectElColIndex = tblColIndex - this.columnRenderersToSkip;

    let selectEls = this.hot.container.nativeElement.querySelectorAll('select.form-control');
    //Could be duplicate select elements here so we want the last one
    // let colsWithSelectCount = (this.columns.length - (this.columnRenderersToSkip));
    let indexOfColValueSelect = tblColIndex - this.columnRenderersToSkip;
    let colsWIthSelectCount = (this.columns.length - this.columnRenderersToSkip);
    let lastSelectIndex = colsWIthSelectCount - 1;
    // let dupColDiffCount = (selectEls.length - colsWithSelectCount) || 0;
    
    //Loop through each selected value to take care of duplicates and validation
    selectEls.forEach((el,i) => {
      let selectElTrueIndex = (i > lastSelectIndex && (tblColIndex + (colsWIthSelectCount * parseInt((i / colsWIthSelectCount).toString()))) - 1)
      let isSrcElement = (i == indexOfColValueSelect) || (i == selectElTrueIndex );
      
      //If column value matches and it is not this current select element, reset the existing value and trigger error 
      if (el.value) {
        if (tblColValue == el.value && !isSrcElement) {

          //Check for a previous selected value before clearing
          let oldValCol = this.returnSelectColumnFromValue(el.value);  

          //If there is an existing val, loop back/reset that header colIndex so it is no longer valid
          if (oldValCol.colIndex >= 0 && oldValCol.colIndex != null) {
            this.formatSelectOptionColData(oldValCol.colIndex, '');

            this.returnSelectColumnFromValue(oldValCol.id).colIndex = null;
            
            //Clear dup element value
            el.value = -1
          }

          //reset header of new value so it does not contain an empty duplicate value 
          else {
            this.returnSelectColumnFromValue(el.value).colIndex = null;
          }

          $(el).addClass('error');
        }
        else {
          $(el).removeClass('error');
        }
      }
    })

    //Now that we have cleared/devalidated all duplicates, we can set new data column index
    if (tblColValue) {
      this.returnSelectColumnFromValue(tblColValue).id = tblColValue;  //TODO: Refactor to class
      this.returnSelectColumnFromValue(tblColValue).colIndex = tblColIndex;  //TODO: Refactor to class
    }

    //Clear all errors if we have all the columns we need selected (do we really need this or is it handled in the loop above?)
    if (this.selectionValuesValid()) {
      $(selectEls).removeClass('error');
    }
    this.emitHeaderSelectionChange();

    return true;
  }
  
  showExtHeaderRow() {
    return (this.headerColumnButton || (this.headerSelecRowData && this.headerSelecRowData.length > 0))
  }
  returnSelectColumnFromValue(value) {
    return this.headerSelectedValues.find(x => x.id == value);
  }

  selectionValuesValid() {
    return (this.headerSelectedValues.filter(x => x.colIndex >= 0 && x.colIndex != null).length == this.headerSelecRowData.length);
  }
  updateRecords(x) {
    if (x) {
      x.forEach(rec => {
        if (rec && rec.length > 3) {
          let rowNum = rec[0];
          let colName = rec[1];
          let oldVal = rec[2];
          let newVal = rec[3];
          this.dataset[rowNum][colName] = newVal
        }
      });
    }
  }
  insertBlankRecords(index, count){
    let newRecords = [];
    for(let newCount = 0; newCount < count; newCount++){
      let rec = {};
      // Object.keys(this.dataset[0]).forEach(prop=>{
      this.columns.forEach(col => {
        rec[col.data] = null
      })
      newRecords.push(rec);
    }
    TableUtil.insertArrayAt(this.dataset, index, newRecords)
  }
  public emitChanges() {
    if (this.hot.hotInstance) {
      // let headers: ColumnData[] = this.columns.map((x, i) => { return { index: i, name: x.data, displayName: x.displayName } });
      
      //Column position saving
      let tblColumnHeaders = this.hot.hotInstance.getColHeader();
      let headers: ColumnData[] = this.columns.map((x, i) => {
        let colIndex = tblColumnHeaders.indexOf(x.displayName);
        if (!(colIndex >= 0)) {
            colIndex = i;
        }
        return { index: colIndex, name: x.data, displayName: x.displayName }
      });


      //Sort header properties by colIndex
      headers = EditorUtilities.SortColumnsByIndex(headers) 

      let data = this.dataset;  //this does not get filterd Data!!
      // let data = this.hot.hotInstance.getData()
      //Removed since we are using listData instead of generic editor data
      // let data = this.hot.hotInstance.getData().map(x => { //TODO: Refactor w/ common method (also should be implemented in table-new)
      //   let newRow = {};
      //   x.forEach((element, i) => {
      //     newRow[i.toString()] = element;
      //   }); 
      //   return newRow;
      // });
      this.dataChanged.emit({ data: data, headers: headers });
    }
  }
  public emitHeaderSelectionChange(){
    this.onHeaderSelectRowChanged.emit(this.headerSelectedValues.map(x => { return { id: x.id, text: x.name, required: x.required, colIndex: x.colIndex, valid: Number.isInteger(this.headerSelectedValues.find(h => h.id == x.id).colIndex) } })); //TODO: Turn to class
  }
  initializeTable() {
    const _self = this;

    let maxColToAdd = 0;
    let extraColumstoAddCount = this.minimumColumnCount - this.columns.length;
    let blankRows = false;

    //Trim excess data  columns if there are blank values in array (from new tbl component)
    this.dataset.forEach(r => {
      if (r.length && typeof (r) != 'string') {
        r.forEach((x, i) => {
          if (x) {
            maxColToAdd = i;
          }
          else {
            blankRows = true;
          }
        });
      }
      
      // //Trim excess data  columns if there are blank properties (from new tbl component)
      // else if (Object.keys(r).length > 0) {
      //   Object.keys(r).forEach((prop, i) => {
      //     if (r[prop]) {
      //       maxColToAdd = i;
      //     }
      //     else {
      //       blankRows = true;
      //     }
      //   })
      // }
    })

    //Set data property names
    let newData = this.dataset.map(x => {
      let newRow = {};
      //Blank rows were not found so presume property mapping is from the DB and correct
      if (!blankRows) {
        newRow = x;
      }
      //Blank rows were found so map them
      else {
        for (let i = 0; i < x.length && i <= maxColToAdd; i++) {
          let colName = this.columns[i].data;
          newRow[colName] = x[i];
        }
      }
      return newRow;
    });
    
    //Should only fire if we have columns with data, otherwise it could be a blank list and we dont want to get rid of columns
    if (maxColToAdd) {
      // if (blankRows) {
      //   this.columns = this.columns.filter(((x, i) => i <= maxColToAdd))
      // }
      this.dataset = newData;
    }

    // //No max column to add (columns are entirely blank), so name them from scratch using index
    // else{
    //   this.dataset = this.dataset.map(x=> {
    //     let newRec = {};
    //     this.columns.forEach((col, colIndex)=> {
    //       newRec[col.data] = x[colIndex]
    //     })
    //     return newRec;
    //   })
    // }
    

    //Check if the data meets the minimum column count
    if (extraColumstoAddCount > 0 && this.minimumColumnCount > 0) {
      this.dataset = EditorUtilities.InsertColumnsIntoData(this.dataset, this.columns, extraColumstoAddCount);
    }

    this.columnHeaders = this.columns.map(x => x.displayName);
    this.settings = {
      afterInit: x => {
        if (this.allowInsertColumn) {
          EditorUtilities.fixContextColumnInsert(this.hot.hotInstance, this.columns);
        }
        this.loaded.emit(true);
        // this.emitChanges(); //already fires on data loaded
      },
      afterChange: x => {
        this.updateRecords(x);
        this.emitChanges();
        this.emitHeaderSelectionChange();
      },
      afterCreateRow: (i,count)=>{
        let oldRowCount = (this.hot && this.hot.hotInstance?  this.hot.hotInstance.getData().length : 0) - this.minimumSpareRows
        if (oldRowCount != this.dataset.length){
          this.insertBlankRecords(i, count)
        }
      },
      afterRemoveRow: i => {
        if (i >=0){
          this.dataset.splice(i,1)
          this.emitChanges();
        }
      },
      afterPaste: x => {
        // this.emitChanges(); already fires in afterChange
      },
      afterColumnMove: x => {
        this.emitChanges();
      },
      licenseKey: 'non-commercial-and-evaluation',
      data: this.dataOffset(this.dataset.map(x => { return this.mapDataColumn(x) }), this.pageSize, 0),
      columns: this.columns,
      colHeaders: colIndex => {
        if (this.headerSelecRowData && this.headerSelecRowData.length > (this.columnRenderersToSkip - 1)) {
          return this.selectHeaderRenderer(_self, colIndex);
        }
        if (this.headerColumnButton) {
          return this.columnHeaderButtonRenderer(_self, colIndex);
        }
        return this.columns.find((x, i) => i == colIndex).displayName;
      },
      rowHeaders: true,
      columnSorting: true,
      outsideClickDeselects: false,
      search: true,
      manualRowMove: true,
      manualColumnMove: this.manualColumnMove,
      fixedRowsTop: 1,
      allowInsertRow: true,
      allowRemoveColumn: this.allowInsertColumn,
      allowInsertColumn: this.allowInsertColumn, //Doesnt work so we use fixContextColumnInsert
      contextMenu: true,
      filters: true,
      renderAllRows: true,
      dropdownMenu: true,
      minSpareRows: this.minimumSpareRows,
      width: '70%'
    };
    // this.hot = new Handsontable(this.hot.container.nativeElement, config
    // );
    setTimeout(x => {
      //For some reason this class is causing issues
      $('.ht_master').addClass('ht_master2')
      $('.ht_master').removeClass('ht_master')
    }, 100)
    return true;
  }

  ngOnInit() {
  }

}
