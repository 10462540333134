import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { BasePage } from '../../shared/basePage';
import { AccountRoles } from '../../_enums/accountRoles';
import { ReportingService } from '../../_services/reporting.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateRecord } from '@models/Requests/Templates/templateRecord';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatChipInputEvent, MatChipSelectionChange, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-template-details',
  templateUrl: './template-details.component.html',
  styleUrls: ['./template-details.component.css']
})
export class TemplateDetailsComponent extends BasePage implements OnInit {
  @Input() templateId: number;
  public template: TemplateRecord;
  public editing: TemplateRecord;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('templateBodyEl') templateBodyEl: ElementRef;
  @ViewChild('subjectEl') subjectEl: ElementRef;
  selectedTag: string;
  tagSeparatorKeysCodes: number[] = [ENTER, COMMA];
  creatingTemplate: boolean;
  removeTags= {
    tags: [],
    showModal: false
  }
  saving: boolean = false;
  constructor(private _reportingService: ReportingService, public router: Router, private activatedRoute: ActivatedRoute, private reportingService: ReportingService, private snackbar: MatSnackBar) {
    super(AccountRoles.User, false, router, snackbar);
    this.isLoading = true;
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      const id = params['id'];
      if (id == 'new') {
        this.creatingTemplate = true;
        this.isLoading = false;
        this.editing = {...this.editing, tags: []}
      } else {
        this.templateId = id || this.templateId;
        this.callService(this._reportingService.getTemplates({ templateId: this.templateId }), data => {
          let res = data && data.templates ? data.templates[0] : null;
          this.template = res;
          this.isLoading = false;
        });
      }
    })
  }
  cancelChanges(){
    if (this.creatingTemplate){
      this.router.navigate(['/templates']);
    }
    else{
      this.editing = null
    }
  }
  editTemplate(){
    //TODO: edit this.editing.templateData to find all squigly char and replace with child element
    this.editing = { ...this.template };
    setTimeout(x=> {
      this.editableContainer_keyup(this.templateBodyEl.nativeElement, null);
      this.editableContainer_keyup(this.subjectEl.nativeElement, null);
    }, 100)
  }
  removeTag(tagIndex: number){
    this.editing.tags.splice(tagIndex, 1)
  }
  addTag_click(event: MatChipInputEvent): void {
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    // if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.addTag(value.trim())
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }
    // }
  }

  selectTag(tag: string): void {
    this.selectedTag = tag;
  }
  addTag(value){
    this.editing.tags.push(value.trim());
    this.selectedTag = null;
  }
  insertTag(tag, senderElement: ElementRef<any>){
    senderElement.nativeElement.innerHTML += this.returnStaticTagHtml(tag);

    //make tag elements whole chips that cant be edited
    // this.setTagsToContentEditable();
  }
  returnStaticTagHtml(tagName){
    return ('<div class="mat-chip mat-primary mat-standard-chip template-body-chip">' + tagName + '</div>')
  }
  setTagsToContentEditable(){
    setTimeout(x=> {
      let chipEls = document.querySelectorAll('.template-body-chip');
      chipEls.forEach(x=> {
        x.setAttribute('contenteditable', 'false')
      })
    }, 50)
  }
  editableContainer_keyup(targetEl, keyCode: number) {
    if (targetEl.innerHTML.indexOf('}') > -1) {
      let startTagIndex;
      let endTagIndex;
      for (var i = 0; i < targetEl.innerHTML.length; i++) {
        const char = targetEl.innerHTML[i];
        if (char == '{') {
          startTagIndex = i;
          endTagIndex = -1;
        }
        else if (char == '}') {
          endTagIndex = i;

          if (startTagIndex > -1){
            let newTag = targetEl.innerHTML.substring(startTagIndex+1, endTagIndex)
            // let newTagElHtml = this.returnStaticTagHtml(newTag);

            if (this.editing.tags.findIndex(x=> x == newTag) == -1){
              this.editing.tags.push(newTag);
            }
            // targetEl.innerHTML= targetEl.innerHTML.substring(0, startTagIndex)
            //  + newTagElHtml
            //  + targetEl.innerHTML.substring(endTagIndex + 1)
            // startTagIndex = -1;
            // endTagIndex = -1;
            // this.setTagsToContentEditable();
          }
        }
      }
    }
  }
  replaceTagsWithIdentifier(){
    this.templateBodyEl.nativeElement.querySelectorAll('.template-body-chip').forEach((chipEl, index) => {
      chipEl.replaceWith(document.createTextNode('{' +  chipEl.innerText + '}'));
      // this.templateBodyEl.nativeElement.replaceChild(document.createTextNode('{' +  chipEl.innerText + '}'), chipEl);
    });
    this.subjectEl.nativeElement.querySelectorAll('.template-body-chip').forEach((chipEl, index) => {
      chipEl.replaceWith(document.createTextNode('{' +  chipEl.innerText + '}'));
      // this.subjectEl.nativeElement.replaceChild(document.createTextNode('{' +  chipEl.innerText + '}'), chipEl);
    });
  }
  toggleSave(){
    // this.replaceTagsWithIdentifier();
    this.removeTags.tags = [];
    this.editing.tags.forEach(tag => {
      const tagIdentifier = '{' + tag + '}';
      if (this.templateBodyEl.nativeElement.innerText.indexOf(tagIdentifier) == -1 && this.subjectEl.nativeElement.innerText.indexOf(tagIdentifier) == -1){
        this.removeTags.tags.push(tag);
      }
    });

    if (this.removeTags.tags.length > 0){
      this.removeTags.showModal = true;
    }
    else{
      this.saveChanges();
    }
  }
  saveChanges(){ //TODO: Remove tags that arent used in body!
    if (!this.editing.templateName){
      return false;
    }
    this.saving = true;
    // this.replaceTagsWithIdentifier();
    this.editing = {
      ...this.editing,
      subject: this.subjectEl.nativeElement.innerText,
      templateData: this.templateBodyEl.nativeElement.innerHTML,
    }
    this.removeTags.tags.forEach(tag=> {
      let tagIndex = this.editing.tags.indexOf(tag);
      this.editing.tags.splice(tagIndex, 1);
    });
    if (this.creatingTemplate){
      this.callService(this.reportingService.createTemplate({...this.editing, tags: JSON.stringify(this.editing.tags)}), data => {
        this.saving = false;
        this.removeTags.showModal = false;
        if (data.isSuccess){
          this.template = { ...this.editing }
          this.editing = null;

          if (this.creatingTemplate){
            this.toggleToastMessage('Template created!');
            this.creatingTemplate = false;
            this.template.templateId = data.returnCode;
          }
          else{
            this.toggleToastMessage('Changes saved!'); 
          }
        }
        else{
          switch(data.returnCode){
            case -3:
              this.toggleToastMessage('This template name is being used. Please choose another.', null, null, 'alert-warning');
              break;
          }
        }
      })
    }
    else {
      this.callService(this.reportingService.saveTemplate({ ...this.editing, tags: JSON.stringify(this.editing.tags) }), data => {
        this.saving = false;
        this.removeTags.showModal = false;
        if (data.isSuccess) {
          this.template = { ...this.editing }
          this.editing = null;
          this.toggleToastMessage('Changes saved!');
        }
        else {
          switch (data.returnCode) {
            case -3:
              this.toggleToastMessage('This template name is being used. Please choose another.', null, null, 'alert-warning');
              break;
          }
        }
      })
    }
    
  }
}
