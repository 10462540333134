import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// import { TableData } from '@models/common/tableData';
import { CrawlerService  } from '@services/crawlers.service';
import { AccountRoles } from '@enums/accountRoles';
import { Router } from '@angular/router';
import { BasePage } from '@app/shared/basePage';
import { CrawlerType } from '../../_enums/crawlerTypes';
import { MatSnackBar } from '@angular/material';
import { NewList } from '../../_models/Requests/Lists/newList';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { CrawlerPage } from '../../shared/crawlerPage';
import { ReportingService } from '../../_services/reporting.service';
import { ICrawlerPage, ISelectionCrawlerPage } from '../../shared/icrawlerpage';
import { TypeValue } from '../../_helpers/typeValue';
import { SelectionCrawlerPage } from '../../shared/selectionServicePage';
import { CrawlerSettings } from '../../_helpers/crawlerSettings';

@Component({
  selector: 'app-employeesearch',
  templateUrl: './employeesearch.component.html',
  styleUrls: ['./employeesearch.component.css','./../../../assets/css/crawler.common.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeesearchComponent extends SelectionCrawlerPage implements ISelectionCrawlerPage, OnInit {
  public selectedColumnValues: any;

  public fileToUpload: File[] = [];
  constructor(public _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar, public _reportingService?: ReportingService) {
    super(_crawlerService, router, snackbar, _reportingService);
    this.crawlerType = CrawlerType.EmployeeData;
    this.crawlerSettings = new CrawlerSettings().getCrawlerProperties(this.crawlerType);
  }
  ngOnInit() {
  }
  
}
