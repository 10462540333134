// import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// // import { Question, Option } from './_models/question';
// @Injectable({
//     providedIn: 'root'
// })
// export class HttpService {
//     constructor(private http: HttpClient) { }

//     Get(request) {
//         let url = apiUrl + '/api/questions';
//         return this.http.get<any>(apiUrl + '/api/upload/findShowMapCompanies?mapPageUrl=' + mapUrl, httpOptions)
//     }
// }
export const apiUrl: string = 'http://localhost:31626';
export const httpOptions = {
    headers: new HttpHeaders({
        //   'Authorization': 'Bearer 9362fd89c866e6bce917c36be051e129'
    })
}