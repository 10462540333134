import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, EventEmitter, Output, SimpleChanges, OnChanges, OnDestroy, TemplateRef } from '@angular/core';

import { BasePage } from '../../shared/basePage';
import { ReportingService } from '../../_services/reporting.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountRoles } from '../../_enums/accountRoles';
import { CrawlData } from '../../_models/Requests/Results/crawlData';
import { CrawlStatus } from '../../_enums/crawlStatus';
import { TableUtil } from '../../_utilities/tableUtilities';
import { SocketService } from '../../_services/socketService';
import { TypeValue } from '../../_helpers/typeValue';
import { CrawlDetails } from '../../_models/Requests/Results/crawlDetails';
import { CommonUtil } from '../../_utilities/common';
import { Hub } from 'ngx-signalr-hubservice';
import { ninvoke } from 'q';
import { CrawlerUtilities } from '../../_utilities/crawlerUtiltiies';
import { ObjectPropertyChange } from '../../_models/common/objectPropertyChange';
import { RequestAddtionalColumnSuffix } from '../../_helpers/crawlerSettings';
import { HeaderChecked, TableOutput } from '../../_models/common/tableData';
import { ColumnData } from '../../_models/common/commonData';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-result-data',
  templateUrl: './result-data.component.html',
  styleUrls: ['./result-data.component.scss']
})
@Hub({ hubName: 'Requestlog' })
export class ResultDataComponent extends BasePage implements OnInit, AfterViewInit, OnChanges {
  public dataSource: MatTableDataSource<any>;
  public datasourceInitialized: boolean = false;
  private resultsData: any[];
  public resultsDataExport: TableOutput;

  @Input() crawlRequestId: number;
  @Input() headerData?: CrawlDetails;
  @Input() headerSelecRowData: any[] = []
  @Input() headerCheckboxRow: boolean = false;
  @Input() headerColumnButton: string = null;
  @Output() onHeaderSelectRowChanged = new EventEmitter<Array<HeaderChecked>>();
  @Output() onHeaderCheckedRowChanged = new EventEmitter<Array<HeaderChecked>>();
  @Output() onColumnButtonClicked = new EventEmitter<HeaderChecked>();
  @Output() gridDataChanged = new EventEmitter<TableOutput>();
  
  public headerSelectedValues: HeaderChecked[] = []
  public headerCheckedColumns: HeaderChecked[] = []
  public selectedCrawlDataId: number;
  
  private defaultDisplayedColumns: string[] = ['crawlDataId', 'crawlStatusId', 'dtCreated'];
  private endColumns = ['responseMessage'];
  private requestHeaders: any[]

  public displayedColumns: string[] = this.defaultDisplayedColumns;
  public colSettings: ColumnData[] = []; //TODO: Make Class
  public extColumns: any[] = [];
  public responseCrawlData: any[];
  public noRecords: boolean = false;

  public newRowsAdded: ObjectPropertyChange[] = [];

  private socketDataPresent:boolean;
  private refreshLoop: ()=> void = null;
  
  @ViewChild('tbl') tblEl: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('mdlViewDetails') mdlViewDetails: TemplateRef<any>;
  public viewDetailsData = {
    htmlContent: ''
  }
  constructor(public _router: Router, private modalService: NgbModal, public _reportingService: ReportingService, private activatedRoute: ActivatedRoute, private socketService: SocketService) {
    super(AccountRoles.User, null, _router)
    this.isSubmitting = true;

    //Subsbribe to socket hook for result changes
    this.socketService.onQueueItemCompleted.pipe().subscribe(data => {
      if (!this.isDestroyed && data.crawlRequestId == this.crawlRequestId && data.crawlDataIds && data.crawlDataIds.length > 0) {
        let valid = this.datasourceIsInitialized();
        console.log('QueueItemCompleted', { data: data, valid: valid });

        if (valid) {
          this.socketDataPresent = true;
          data.crawlDataIds.forEach(crawlDataId => {
            let row = this.dataSource.data.find(row => row.crawlDataId == crawlDataId);
            // if (TableUtil.getCrawlDataGridParentRow(this.dataSource, crawlDataId, CrawlStatus.InProcess)) {

            //Check if CrawlData is completed before refreshing crawlData //NOTE: Removing this check infers a brute-force type of refresh integrity (multiple calls to already-completed data)
            if (row && ((row.crawlStatusId & CrawlStatus.Complete) != CrawlStatus.Complete)) {
              this.reloadGridRowData(crawlDataId);
            }
          })
        }
      }
    })

    //Subscribe to socket hook for queue record starting
    this.socketService.onQueueItemStarted.subscribe(data => {
      if (!this.isDestroyed) {
        let valid = (this.datasourceIsInitialized() && data.crawlRequestId == this.crawlRequestId && data.crawlDataId > 0);
        console.log('onQueueItemStarted', { data: data, valid: valid });

        if (valid) {
          this.socketDataPresent = true;
          let row = this.dataSource.data.find(row => row.crawlDataId == data.crawlDataId && !((row.crawlStatusId & CrawlStatus.Complete) == CrawlStatus.Complete));
          if (row) {
            row.crawlStatus = {
              id: CrawlStatus.InProcess,
              name: CrawlStatus[CrawlStatus.InProcess]
            }
            this.refreshGridData();
          }
        }
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const crawlRequestIdProp = changes['crawlRequestId'];
    const headerSelectRowDataProp = changes['headerSelecRowData'];
    const headerCheckboxRowProp = changes['headerCheckboxRow'];
    const headerDataProp = changes['headerData'];
    if (crawlRequestIdProp && crawlRequestIdProp.currentValue) {
      this.crawlRequestId = crawlRequestIdProp.currentValue;
    }
    if (headerDataProp && headerDataProp.currentValue) {
      this.headerData = headerDataProp.currentValue;
      this.crawlRequestId = this.headerData.crawlRequestId;
    }
    if (headerSelectRowDataProp && headerSelectRowDataProp.currentValue) {
      this.headerSelecRowData = headerSelectRowDataProp.currentValue;
    }
    if (headerCheckboxRowProp && (headerCheckboxRowProp.currentValue == true || headerCheckboxRowProp.currentValue == false)) {
      this.headerCheckboxRow = headerCheckboxRowProp.currentValue;
    }

    if (this.crawlRequestId && ((crawlRequestIdProp && crawlRequestIdProp.previousValue != crawlRequestIdProp.currentValue) || (headerDataProp && headerDataProp.previousValue != headerDataProp.currentValue))) {
      if (!this.datasourceIsInitialized()) {
        this.displayedColumns = this.defaultDisplayedColumns;
        this.colSettings = [];
        this.LoadData();
      }
    }
  }
  datasourceIsInitialized(){
    return (this.datasourceInitialized && this.dataSource && typeof(this.dataSource.data) != 'undefined');
  }
  intializeRefreshLoop(waitMs: number) {
    //depreciated this one to try the one below in an effort to stop refresh crash for multiple records
    // setTimeout(data => {
    //   //Refresh data every 10 seconds if socket refresh is still failing
    //   if (this.refreshLoop) {
    //     this.refreshLoop();
    //   }
    // }, waitMs)

    console.log('results refresh loop initialized');

    //This one really isnt any better though and we really need one big grid refresh every 10 seconds
     //If socket data is initialized but data is not refreshing, initialize 10 second timeout to refresh data
     if (this.refreshLoop) {
       setTimeout(x => {
         while (this.refreshLoop) {
             this.refreshLoop(); //TODO: Remove initialzie 
           
         }
       }, waitMs);
     }


  }

  forceSoftRefresh() {
    if (this.socketService.connectionIsEstablished && !this.socketDataPresent && !this.isDestroyed) {
      if (this.datasourceIsInitialized()) {
        CrawlerUtilities.nonCompletedRows(this.dataSource.data).forEach(rec => {
          this.reloadGridRowData(rec.crawlDataId);
          console.log('soft refresh triggered', { crawlDataId: rec.crawlDataId, gridData: this.dataSource.data });
        })
      }
    }
  }
  reloadGridRowData(crawlDataId: number) {
    if (this.dataSource && this.dataSource.data) {

      //Get Crawl data from API and if newData doesnt exist in exisitng Rows then we append 
      this.callService(this._reportingService.GetCrawlData(this.crawlRequestId, crawlDataId), newData => {
        let responseDataRows = newData.results;
        let responseHeaders = this.parseHeaderData(responseDataRows[0].responseHeaders);

        //add response column data to rows
        let formattedRows = this.buildCrawlDataList(responseDataRows, responseHeaders, 'responseData', this.defaultDisplayedColumns.concat(this.endColumns));


        //Add request column data to rows
        formattedRows = this.buildRequestData(this.requestHeaders, responseDataRows, formattedRows); //should we do this in buildCrawlDataList()? We wont have to check crawlDataID > 0 if we do (kind of jankey)

        //Replace ID property with name/id properties
        formattedRows = this.buildTypeValueRows(formattedRows, 'crawlStatusId', 'crawlStatus');


        //Remove Response Data columns if they are present (would have been formatted in buildCrawlList() )
        formattedRows.forEach(row=> {
          delete row.ResponseColumns
          delete row.ResponseData
        })

        let mismatchingProperties:ObjectPropertyChange[] = [];
        let oldCrawlRow = this.dataSource.data[this.dataSource.data.indexOf(TableUtil.getCrawlDataGridParentRow(this.dataSource, crawlDataId, CrawlStatus.InProcess))];
        let newCrawlRow = formattedRows.find(first => first.crawlDataId > 0);
        
        //check if object is different and mark properties that have changed to flish
        let mismatchingCrawlRowProps = CommonUtil.returnPropertyMatch(oldCrawlRow, newCrawlRow);
        if (mismatchingCrawlRowProps.length > 0){
          mismatchingProperties.push({index: this.dataSource.data.indexOf(oldCrawlRow), properties: mismatchingCrawlRowProps});
        }
        
        //Sync parent crawlDataID row with new data
        this.dataSource.data[this.dataSource.data.indexOf(oldCrawlRow)] = newCrawlRow;

        //loop through child rows of a crawlData record, and add them if they exist within current grid rows already
        let formattedChildRows = formattedRows.filter(x => !(x.crawlDataId > 0));  //rows that do not have crawlDataID (i.e. second row +)
        let existingDataRows = this.getGridRowsByCrawlDataId(this.dataSource.data, crawlDataId);
        let addedCount = 0;
        formattedChildRows.forEach((newRow, nI) => {

          //Checking for duplicate child row before adding
          if (CommonUtil.objectContainsChild(existingDataRows, newRow) ) { 

            //WE COUULD UPDATE EXISTING DATA HERE
            // EVERYTHING BELOW IS USELESS
            // let nextCrawlDataRow = this.dataSource.data.find(d => d.crawlDataId != crawlDataId && d.crawlDataId > 0);
            // let lastIndex;

            // //End of rows, so use length for splicing
            // if (!nextCrawlDataRow) {
            //   lastIndex = this.dataSource.data.length - 1;
            // }
            // //Next crawlDataId found, so splice to that
            // else {
            //   lastIndex = this.dataSource.data.indexOf(nextCrawlDataRow);
            // }
            // let newIndex = lastIndex + nI;
            // let mismatchingDataRowProps = CommonUtil.returnPropertyMatch({}, this.dataSource.data[lastIndex]);

            // //Update row column cells to flash if prior record exists
            // let previousMatchingProperties = mismatchingProperties.find(x=> x.index == newIndex);
            // if (previousMatchingProperties){
            //   previousMatchingProperties.properties = mismatchingDataRowProps;
            // }

            // //Or add all propertries of new row instead
            // else {
            //   mismatchingProperties.push({ index: newIndex, properties: mismatchingDataRowProps, fadeIn: true })
            // }

            // //Insert new data response rows
            // this.dataSource.data.splice(newIndex, 0, newRow);
            
            // //Keep adding 1 to accomodate new row added
            // nI++;
            // addedCount++;
          }

          //Insert rows after the last parent row + current index, then flash row column cells
          else{
            let lastIndex = (existingDataRows && existingDataRows.length > 0? this.dataSource.data.indexOf(existingDataRows[existingDataRows.length-1]) : this.dataSource.data.length - 1);
            let newIndex = lastIndex + 1 + addedCount;// + addedCount; //+ nI
            
            this.dataSource.data.splice(newIndex, 0, newRow);
            this.newRowsAdded.push({ index: this.dataSource.data.indexOf(newRow), properties: CommonUtil.returnPropertyMatch({}, newRow), fadeIn: true  });
            addedCount++;
          }
        })
        
        //Trigger grid refresh
        this.refreshGridData();

        
        //If socket data is initialized but data is not refreshing, initialize 10 second timeout to refresh data
        this.refreshLoop = !CrawlerUtilities.isCompletedRequest(this.dataSource.data)? this.refreshLoop : null;
        
        //Cant do this with a ton of records, it will keep firing so we moved it to parent call
        // if (this.refreshLoop){
        //   this.intializeRefreshLoop(10000);
        // }
        this.emitGridData();
        
        //Flash rows
        this.newRowsAdded = this.newRowsAdded.concat(mismatchingProperties);

        // setTimeout(x=> {
        //   mismatchingCrawlRowProps.forEach(i => {
        //     let rowElIndex = ((this.paginator.pageSize * (this.paginator.pageIndex+1)) + i.index) //(pageSize * currentPage) + index
        //     let rowEl =  $(this.tableElement._elementRef.nativeElement.querySelectorAll('tr:nth-child(' + (rowElIndex + 1) + ')' + ' .' + textOverflowParentClass))
        //     if (row)
        //     $(tr: nth - child(i)).toggleClass('flash');
        //   }, 100)
        // })
        
      })
    }

  }

  cellShouldFlash(rowElIndex: number, propName: string) {
    let dataRowIndex = TableUtil.getDataIndexByRowElementIndex(rowElIndex, this.paginator.pageIndex, this.paginator.pageSize);
    let propValue = this.dataSource.data[dataRowIndex][propName];
    let flashRow = (this.newRowsAdded.find(x => x.index == dataRowIndex));
    


    //if value is blank or value has name property that is a string then we will skip it since it is a crawl
    if (!propValue || typeof (propValue.name) != 'undefined') {
      return false;
    }

    //row is from socket data and flashRow is false, so we will toggle the flash class
    else if (flashRow) { //&& !flashRow.hasFlashed) {
      let flashProperty = (flashRow && flashRow.properties.indexOf(propName) > -1);

      if (flashProperty) {
        return true;
      }
      else {
        return false;
      }
    }

    //Data is from initial load, no flash present
    else {
      return false;
    }
  }
  // rowShouldFade(rowElIndex: number){  //uses pure CSS/ngClass "fade-in" class
  //   let dataRowIndex = TableUtil.getDataIndexByRowElementIndex(rowElIndex, this.paginator.pageIndex, this.paginator.pageSize);  
  //   let row = this.dataSource.data[dataRowIndex];
  //   let fadeRow = (this.newRowsAdded.find(x => x.index == dataRowIndex && x.fadeIn));

  //   if (fadeRow){
      
  //     setTimeout(x=> {
  //       this.newRowsAdded.find(x => x.index == dataRowIndex).fadeIn = false;
  //     }, 251)   //250 ms is the fade timeout for .fade-in class (CSS)
  //   }
  //   return fadeRow;
    
  // }
  rowShouldFade(rowElIndex: number) : boolean{  //uses jQuery/uses "ng-hide" in ngClass to fire this function
    let dataRowIndex = TableUtil.getDataIndexByRowElementIndex(rowElIndex, this.paginator.pageIndex, this.paginator.pageSize);
    let row = this.dataSource.data[dataRowIndex];
    let fadeRow = (this.newRowsAdded.find(x => x.index == dataRowIndex));

    //row is present in socketdata list 
    if (fadeRow) {
      //fade is false, so toggle class to hide visibiltiy
      if (!fadeRow.fadeIn) {
        return false;
      }

      //fade is true, so toggle class to hide visibility after firing a timed fadeIn()
      else {
        $('.result-row:nth(' + rowElIndex + ')').show(400, () => {
          this.newRowsAdded.find(x => x.index == dataRowIndex).fadeIn = false;
        });

        //ensure visibility is still hidden
        return true;
      }
    }

    //row is not present in socketdata and therefore came in with the original grid data
    else {
      return false;
    }
    
  }
  
  refreshGridData() {
    this.paginator._changePageSize(this.paginator.pageSize);
    this.dataSource = new MatTableDataSource(this.dataSource.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.setDefaultGridSort();   
  }
  setDefaultGridSort(){
    // this.dataSource.sort.sort({ id: 'crawlDataId', start: 'desc', disableClear: false });
  }
  populateGrid(headerData: CrawlDetails, CrawlData: any) {
      if (CrawlData.results && CrawlData.results.length > 0) {
        let responseDataRows = CrawlData.results;   
        this.resultsData = responseDataRows;

        let responseHeaders = this.parseHeaderData(responseDataRows[0].responseHeaders);
        
        //Set global header data
        this.requestHeaders = JSON.parse(headerData.requestHeaders);
        
        // this.tbl.sort({ id: 'crawlDataId', start: 'asc', disableClear: false });
        //add response column data to rows
        this.responseCrawlData = this.buildCrawlDataList(responseDataRows, responseHeaders, 'responseData', this.displayedColumns.concat(this.endColumns));

        //Add request column data to rows
        // this.responseCrawlData = this.buildRequestData(requestHeaders, this.responseCrawlData);
        this.responseCrawlData = this.buildRequestData(this.requestHeaders, responseDataRows, this.responseCrawlData);
        
        //initialize seperate instance of data for table load
        let responseResults = this.responseCrawlData;

        //Add dynamic column headers to displayed columns
        
        let displayColStart = 0;
        this.displayedColumns = responseHeaders ? TableUtil.insertArrayAt((Object.keys(responseHeaders).map(p => { return responseHeaders[p]; })), displayColStart, this.defaultDisplayedColumns) : this.displayedColumns;

        //Add response data columns if they are present (properties would have been added in buildCrawlDataList())
        if (this.displayedColumns.indexOf("ResponseColumns") > -1 && this.displayedColumns.indexOf("ResponseData") > -1) {
          let columnsToAdd = [];

          //We should populate responsedata here instead of buildCrawlDataList() since were already iterating over the loop
          //BUT then we wouldnt have it in a common place for refresh as well
          this.responseCrawlData.forEach(row => {
            row.ResponseColumns.forEach(col => {
              if (columnsToAdd.indexOf(col) == -1)
                columnsToAdd.push(col);
            })
            delete row.ResponseColumns
            delete row.ResponseData
          })
          columnsToAdd.forEach(col => this.displayedColumns.push(col))
          this.displayedColumns.splice(this.displayedColumns.indexOf("ResponseColumns"),1);
          this.displayedColumns.splice(this.displayedColumns.indexOf("ResponseData"),1);
        }

        // this.displayedColumns = responseHeaders? this.defaultDisplayedColumns.concat(Object.keys(responseHeaders).map(p => { return responseHeaders[p]; })) : this.displayedColumns;

        //Create array of  column header indexes for static/default columns that we can identify later
        let staticColumnColIndexes = [];
        for (let i = 0; i < this.defaultDisplayedColumns.length; i++){
          staticColumnColIndexes.push(displayColStart + i)
        }
        
        //Create array of request column header indexes so we can identify them
        let requestColStartIndex = this.displayedColumns.indexOf(this.defaultDisplayedColumns[this.defaultDisplayedColumns.length-1])+1;
        let requestColHeaders = this.requestHeaders.filter(h => this.displayedColumns.indexOf(h) == -1);
        let requestHeaderDisplayColIndexes = [];
        for (let i = 0; i < requestColHeaders.length; i++){
          requestHeaderDisplayColIndexes.push(requestColStartIndex + i)
        }   

        //Add request column headers to displayed columns (after last default column) and skip dup column names that match response data col name
        this.displayedColumns = TableUtil.insertArrayAt(this.displayedColumns, requestColStartIndex , requestColHeaders);

        //Add end columns
        this.displayedColumns = TableUtil.insertArrayAt(this.displayedColumns, this.displayedColumns.length, this.endColumns);

        //Replace ID property with name/id properties
        responseResults = this.buildTypeValueRows(responseResults, 'crawlStatusId', 'crawlStatus');

        //Configure extra column setings or IDs/Dates and other known types
        this.colSettings = this.formatColumnSettings(this.displayedColumns, staticColumnColIndexes, requestHeaderDisplayColIndexes);

        //Configure column headers, buttons, filtering, etc
        this.extColumns = this.formatExtColumnHeaders();

        //If there are rows not completed, then we set the refresh loop
        this.refreshLoop = !CrawlerUtilities.isCompletedRequest(responseResults)? this.forceSoftRefresh : null;

        //If socket data is initialized but data is not refreshing, initialize 10 second timeout to refresh data
        // if (this.refreshLoop) {
        //     this.intializeRefreshLoop(10000);
        // }

        //Set data/Sort/Pagination
        this.initializeTable(responseResults)

        //TODO: Add Column NgIF HERE SO we dont have premature rendering issues (client errors are periodically coming due to our dyanmic column generation)
      }

      //No rows
      else {
        if (!(this.dataSource && this.dataSource.data && this.dataSource.data.length > 0)){
          this.dataSource = new MatTableDataSource([]);
          this.isSubmitting = false;
          console.log('ISSUBMITTING = false, no data', { dataSOurceInitailzied: this.datasourceInitialized, data: this.dataSource.data })
          this.noRecords = true;
        }
      }
  }
  toggleAllRequestHeaders(show: boolean) {
    let indexOfId = null;
    let newData = [];
    this.dataSource.data.forEach((row, i) => {
      let rec = row;
      if (rec.crawlDataId > 0 && rec.crawlDataId != '') {
        indexOfId = i;
      }
      else {
        this.requestHeaders.forEach(header => {
          if (show) {
            rec[header] = this.dataSource.data[indexOfId][header];
          }
          else{
            rec[header] = '';
          }
        })
      }
      newData.push(rec);
    })
    this.dataSource.data = newData;
    this.resultsDataExport = this.formatGridEmittedData();
    this.refreshGridData();
  }
  getGridRowsByCrawlDataId(rows: any[], crawlDataId: number){
    let firstIndex = rows.indexOf(rows.find(d=> d.crawlDataId == crawlDataId));
    let nextCrawlObjIndex = rows.indexOf(rows.find((d,i)=> d.crawlDataId != crawlDataId && i > firstIndex && (d.crawlDataId)))
    let rowsToReturn = [];

    //There isnt another crawldataID past the one passed in so we will loop to the end of the array
    if (nextCrawlObjIndex == -1){
      nextCrawlObjIndex = rows.length;
    }
    for(let i = firstIndex; i < nextCrawlObjIndex; i++){  
      rowsToReturn.push(this.dataSource.data[i]);//Skip firstIndex to avoid including the same data again
    }
    return rowsToReturn;
  }

  rowIsInProcessStatus(row) {
    return (this.rowHasCrawlStatus(row, CrawlStatus.InProcess));
  } 
  rowIsInCompletedStatus(row){
    return (this.rowHasCrawlStatus(row, CrawlStatus.Complete));
  }
  rowIsCompletedWithErrorsStatus(row){
    return (this.rowHasCrawlStatus(row, CrawlStatus.HasErrors, true));
  }
  rowIsInFailedStatus(row){
    return (this.rowHasCrawlStatus(row, CrawlStatus.Failed, true));
  }

  rowHasCrawlStatus(row: any, crawlStatusId: number, allowMultipleFlags: boolean = false) {
    let colName = 'crawlStatus'
    if (allowMultipleFlags) {
      return (row[colName].id & crawlStatusId);
    }
    else{
      return (row[colName].id == crawlStatusId);
    }
    
  }

  ngOnInit() {
    // setTimeout(x=> {
    //   this.newRowsAdded.push({index: 0, properties: ['query', 'dtCreated'], fadeIn: false})
    // }, 5000)
  }
  ngOnDestroy(){
    this.isDestroyed = true; //STOP SOCKETS FROM COMING IN HERE! Its slows things down ith tons of records//STOP SOCKETS FROM COMING IN HERE! Its slows things down ith tons of records
    // this.socketService.onQueueItemCompleted.unsubscribe();
  }
  LoadData() {
    this.isSubmitting = true;
    //Use header data, retrieve crawl data
    if (this.headerData && this.headerData.crawlRequestId){
      this.callService(this._reportingService.GetCrawlData(this.crawlRequestId), data => this.populateGrid(this.headerData, data))
    }

    //Get new header data and crawl data
    else{
      this.callService(this._reportingService.getAllData(this.crawlRequestId), data => this.populateGrid(data[0], data[1]))
    }

    //initialize select values
    if (this.headerSelectedValues.length != this.headerSelecRowData.length) {
      this.headerSelecRowData.forEach(x => {
        this.headerSelectedValues.push({name: x.name, displayName: null, data: null});
      })
    }
  }

  initializeTable(data: any[], defaultPageSize: number = 10) {
    if (!this.datasourceIsInitialized()) {
      this.datasourceInitialized = true;
      setTimeout(x => {
        this.paginator.page.subscribe(event=>{
          // let pageRows = this.newRowsAdded.filter(x=> x.index < event['length'] && x.index < event.pageSize);
          
          //Clear previous page changedRows so we dont reflash/refade
          for (let i = 0; i < event['length'] && i < event.pageSize; i++) {
            let rowIndex = i + (event.previousPageIndex * event.pageSize);
            let newRowAdded = this.newRowsAdded.filter(x => x.index == rowIndex);

            if (newRowAdded) {
              newRowAdded.filter(x=> x.properties = [] );
            }
          }
        })
        this.paginator._changePageSize(defaultPageSize);
        this.dataSource = new MatTableDataSource(data);
        this.bindSortChangeToTable();
        this.setDefaultGridSort();
        this.dataSource.paginator = this.paginator;
        this.isSubmitting = false;
        console.log('ISSUBMITTING = false, dataSource inialized', data)
        setTimeout(x => {

          if (this.headerSelecRowData && this.headerSelecRowData.length > 0) {
            $(this.tblEl._elementRef.nativeElement.querySelectorAll('.mat-sort-header-arrow')).css('margin-top', '-80px');
            $(this.tblEl._elementRef.nativeElement.querySelectorAll('.mat-sort-header-arrow')).css('margin-left', '25px');
          }
          this.emitGridData();
        }, 1000)
      }, 0);
    }
  }

  bindSortChangeToTable(){
    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sortChange.subscribe(x => {
        this.emitGridData();
      });
    }
    else{
      setTimeout(x=> {
        this.bindSortChangeToTable();
      }, 100)
    }
  }

  formatExtColumnHeaders(){
    let extCols: string[] = [];
        
    if (this.showExtHeaderRow()) {
      for (let i = 0; i < this.colSettings.length; i++) {
        extCols.push(i.toString());
      }
    }
    return extCols;
  }
  showExtHeaderRow(){
    return (this.headerColumnButton || (this.headerSelecRowData && this.headerSelecRowData.length > 0))
  }
  emitGridData(){
    this.resultsDataExport = this.formatGridEmittedData();
    this.gridDataChanged.emit(this.resultsDataExport);
  }
  formatGridEmittedData() {
    //flatten crawlstatus column with name property    
    const filteredData = this.dataSource.filteredData.map(x => TableUtil.flattenObjectTypeProperty(x, 'crawlStatus', 'name'));
    let newColSettings: ColumnData[] = JSON.parse(JSON.stringify(this.colSettings));
    let rowsWithDataCount = 0;
    //Map friendly names for additional columns
    newColSettings.forEach(newCol => {
      //If request column is additional
      if (TableUtil.columnNameHasAdditionalSuffix(newCol.name)) {
        let hasIncriment = TableUtil.columnNameHasIncrimentSuffix(newCol.name);
        //Adding +1 to incriment to account for original column that does not have incriment number
        let incriment = hasIncriment ? (parseInt(TableUtil.returnIncrimentSuffix(newCol.name))) : '1';

        //Trim column name portion
        newCol.displayName = this.addSpaceBeforeCaps(this.capitalizeFirstLetter(newCol.name.substring(0, newCol.name.indexOf(RequestAddtionalColumnSuffix))));

        //Add incrimental number in parenthesis if avail
        newCol.displayName += ' (' + incriment + ')';
      }
    })

    //Get count of rows with data records
    this.resultsData.forEach((x, i) => {
      if (x.crawlStatusId && x.responseData && x.responseData != '[]') {
        rowsWithDataCount++;
      }
    })
    let dataToEmit: TableOutput = { data: filteredData, columns: newColSettings, rowsWithDataCount: rowsWithDataCount }
    //this.resultsDataExport = dataToEmit;
    return dataToEmit;
  }
  parseHeaderData(headerData) {
    let headerCols = JSON.parse(headerData);
    this.displayedColumns.forEach((col,i)=> {
      if (typeof(headerCols[col]) != 'undefined'){
        this.displayedColumns[i] = col + '_Parent';
      }
    })

    return headerCols;
  }
  parseCrawlDataRow(dataRow, dataPropertyName) {
    if (Array.isArray(dataRow)) {
      return JSON.parse('[' + dataRow.map(el => { return el[dataPropertyName] || '{}' }).join(',') + ']');
    }
    else if (typeof (dataRow) == 'object' ) {
      //string
      if (dataRow[dataPropertyName]){
        return JSON.parse(dataRow[dataPropertyName]);
      }
      else{
        return {};
      }
      
    }  
    //string
    return JSON.parse(dataRow || '{}');
  }

  formatColumnSettings(displayedColumns: string[], staticColumnColIndexes: number[], requestHeaderDisplayColIndexes: number[]) {
    let firstIdCol = false;
    let settings = [];
    displayedColumns.forEach( (c,colIndex) => {
      let newCol = { name: c, displayName: this.addSpaceBeforeCaps(this.capitalizeFirstLetter(c)), isAdditional: false, isRequest: false, isStatic: false, width: 100, type: 'text', class: '' } //TODO: Make class

      //If contains 'status', remove everythign before status
      // let strIndexestoRemove = ['status'];
      // strIndexestoRemove.forEach(str => {
      //   let statusIndex = newCol.displayName.toLowerCase().indexOf(str);
      //   if (statusIndex > 0) {
      //     newCol.displayName = str;
      //   }
      // });

      // //Handle if displayName ends with requestColumnIndex
      // if (TableUtil.columnNameHasAdditionalSuffix(newCol.name)) {
      //   newCol.displayName = this.addSpaceBeforeCaps(newCol.name.substring(0, newCol.name.indexOf(RequestAddtionalColumnSuffix))) + RequestColumnDisplaySuffix;
      // }

      //Handle request columns
      if (typeof(requestHeaderDisplayColIndexes.find(x=> x == colIndex)) != 'undefined') {

        //If request column is additional
        if (TableUtil.columnNameHasAdditionalSuffix(newCol.name)) {
          let hasIncriment = TableUtil.columnNameHasIncrimentSuffix(newCol.name);
          newCol.isAdditional = true;

          //Trim column name portion
          newCol.displayName = this.addSpaceBeforeCaps(this.capitalizeFirstLetter(newCol.name.substring(0, newCol.name.indexOf(RequestAddtionalColumnSuffix))));

          //Add incrimental number in parenthesis if avail
          if (hasIncriment) {
            newCol.displayName += (' (' + (newCol.name.substring(newCol.name.indexOf(RequestAddtionalColumnSuffix) + RequestAddtionalColumnSuffix.length)) + ')');
          }
          // + newCol.name.split(RequestAddtionalColumnSuffix) > 1? '(' + newCol.name.split(RequestAddtionalColumnSuffix).length + ')'  //This would count duplicate RequestAddtionalColumnSuffix stacking up from previous results so we could use incriment instead - i.e. "Address(4)(1)"

          // newCol.displayName = '(Additonal) ' + newCol.displayName;
        }
        else{
          // newCol.displayName = '(Request) ' +  newCol.displayName;
          newCol.isRequest = true;
        }
        //Column is request header (required column)

      }

      //Handle default static columns
      else if (typeof(staticColumnColIndexes.find(x=> x == colIndex)) != 'undefined'){
        newCol.isStatic = true;
      }

      //Handle IDs
      if (newCol.displayName.substring(newCol.displayName.length, newCol.displayName.length - 2) == 'Id') {
        if (!firstIdCol) {
          newCol.displayName = 'ID';
          firstIdCol = true;
        }
        else {
          newCol.displayName = newCol.displayName.substring(0, newCol.displayName.length - 2).trim();
        }

        newCol.type = 'numeric';
        newCol.width = 75;
        
        // newCol.numericFormat = {
        //   pattern: '0.0000'
        // }
      }

      //Handle dates
      else if (c.substring(0, 2) == 'dt') {
        newCol.displayName = newCol.displayName.substring(2, newCol.displayName.length).trim();
        newCol.type = 'date';
      }

      //Handle Crawl Status Column
      else if (newCol.name == 'crawlStatus') {
          newCol.class = 'text-center'; //TEMPORARY UNTIL WE FIX LINE BELOW
        // if (c.align) {
        //   newCol.class = newCol.class.trim() + ' text-' + c.align.toLowerCase();  //TODO: add properties to displayedColumns obj so we can get text-class from it)
        // }
      }
      else if (newCol.name == 'responseMessage'){
        newCol.class = 'text-overflow-parent response-msg-lbl-container';
      }
      settings.push(newCol)
    })

    return settings;
  }
  capitalizeFirstLetter(val) {
    return (val.charAt(0).toUpperCase() + val.substring(1));
  }
  addSpaceBeforeCaps(val) {
    // let cIndex = 0;
    // for(let c of val){
    //   if (c == c.toUpperCase() && c != ' '){
    //     let nextChar = cIndex < val.length-1?  val[cIndex+1] : null
    //     if (!nextChar || nextChar != ' ' && nextChar != nextChar.toUpperCase()){
    //       val.substring(0, cIndex + 1) + ' ' + val.substring(cIndex + 1)
    //     }
    //   }
    //   cIndex++;
    // }
    return val.replace(/([A-Z])/g, ' $1').trim().replace('U R L', 'URL').replace('I D', 'ID');
  }
  mapCrawlRequestHeaderData(row: any, requestHeaders: string[], requestData: any){
      requestHeaders.forEach((header,i)=>{
        row[header] = row.crawlDataId > 0? requestData[i.toString()] : '';
      })

    return row;
  }
  // buildRequestData(requestHeaders: string[], crawlDataRows: any[]) { debugger;
  //   let newData = [];
  //   crawlDataRows.forEach((r, rowIndex) => {
      
  //     let requestData = JSON.parse(crawlDataRows[rowIndex].requestData);
  //     let newRowData = this.mapCrawlRequestHeaderData(r, requestHeaders, requestData);
  //     // let newRowData =  responseCrawlData.map((x, i) => {
  //     //   let requestData = JSON.parse(crawlDataRows[rowIndex].requestData);
  //     //   return this.mapCrawlRequestHeaderData(r, requestHeaders, requestData);
  //     // });
  //     newData = newData.concat(newRowData);
  //   });
  //   return newData;
  // }

  buildRequestData(requestHeaders: string[], crawlDataRows: any[], responseData: any[]) {
    let newData = [];
    let lastRowIndex = null;
      let newRowData =  responseData.map((x, i) => {

        //Get the index of the original row by the crawlDataID 
        let rowIndex = crawlDataRows.indexOf(crawlDataRows.find(d=> d.crawlDataId == x.crawlDataId))
        
        //Row will not have crawlDataId (empty string) to index of we have an array of results, so we use the last one
        //This could be avoided by adding parentCrawlDataId to these rows instead of a blank CrawlDataID
        if (rowIndex == -1){
          rowIndex = lastRowIndex;
        }
        else{
          lastRowIndex = rowIndex;
        }
        let requestData = JSON.parse(crawlDataRows[rowIndex].requestData);
        return this.mapCrawlRequestHeaderData(x, requestHeaders, requestData);
      });
      
      // let newRowData =  responseCrawlData.map((x, i) => {
      //   let requestData = JSON.parse(crawlDataRows[rowIndex].requestData);
      //   let data = this.mapCrawlRequestHeaderData(x, requestHeaders, requestData);
      //   requestHeaders.forEach((rh,i)=> {
      //     data = this.moveElementObject(data, Object.keys(data).indexOf(rh), Object.keys(data).indexOf(this.defaultDisplayedColumns[this.defaultDisplayedColumns.length-1 + i]))
      //   })
      //   return data;
      // });

      newData = newData.concat(newRowData);
    
    return newData;
  }
  moveElementObject(object, from, to) {
    var newObjects = [];
    var newObject = {};
    var oldObject = {};
    var firstObject = {};
    var lastObject = {};
    var toMoveKey = "";
    var toMoveValue;
    oldObject = object;
    var objLength = Object.keys(oldObject).length;
    var keyNo = 1;
    for (var key in oldObject) {
        if (keyNo == from) {
            toMoveKey = key;
            toMoveValue = oldObject[key];
        }
        keyNo++;
    }
    console.log(oldObject);

    keyNo = 1;
    for (var key in oldObject) {
        if (keyNo < to) {
            firstObject[key] = oldObject[key];
            newObject[key] = firstObject[key];
        }
        keyNo++;
    }
    console.log(firstObject);

    keyNo = 1;
    for (var key in oldObject) {
        if (to <= objLength) {
            lastObject[key] = oldObject[key];
        }
        keyNo++;
    }
    delete lastObject[toMoveKey];
    newObject[toMoveKey] = toMoveValue;

    for (var key in lastObject) {
        newObject[key] = lastObject[key];
    }
    return newObject;
  }
  buildCrawlDataList(responseDataRows: any[], responseHeaderData: any, propName: string, staticColumnsToAdd: any[] = null) {
    if (responseHeaderData) {
      let newRows = [];
      let hasResponseDataColumns = false;
      responseDataRows.forEach((crawlData, rowI) => {
        let responseCrawlDataRows = this.parseCrawlDataRow(crawlData, propName);

        //Flatten array (negates all array checks)
        if (Array.isArray(responseCrawlDataRows)) {
          responseCrawlDataRows = CommonUtil.flatDeep(responseCrawlDataRows);
        }
        else{
          responseCrawlDataRows = [responseCrawlDataRows];
          responseCrawlDataRows = CommonUtil.flatDeep(responseCrawlDataRows);
        }

        //Iterate through each row to build row properties
        if (responseCrawlDataRows.length == 0 && crawlData.crawlDataId){
          responseCrawlDataRows.push({})
        }
        responseCrawlDataRows.forEach((el, i) => {
          Object.keys(responseHeaderData).forEach((p) => {

            let newPropertyName = responseHeaderData[p];

            // // Array data results were generated so step one level into array (if we havent flattened it already)
            // if (Array.isArray(el)) { 

            //   //if length is not greater than 0, then we need to reinitialize
            //   if (el.length > 0) {
            //     el.forEach(rec => {
            //       this._reportingService.renameObjectProperty(rec, p, newPropertyName)
            //     })
            //   }
            //   //add static columns adjacent to response data
            //   if (staticColumnsToAdd) {
            //     staticColumnsToAdd.forEach(c => {
            //       el.forEach(r => {
            //         r[c] = responseDataRows[i][c];
            //       })
            //     })
            //   }

            // }

            //Treat as one record THIS IS NOT FIRING FOR RECORDS THAT HAVE NO RESPONSEDATA (no responseCrawlDataRows to loop through)
            // else {
              //Rename existing properties in response data to friendly names using response headers
              if (el && (el[p] || el[p] == null)) {
                this._reportingService.renameObjectProperty(el, p, newPropertyName)
              }
              //object has no values, so we initiailize
              else {
                if (el == null || typeof (el) != 'object') {
                  el = {};
                }
                el[newPropertyName] = ''
              }
              //add static columns adjacent to response data
              if (staticColumnsToAdd && i == 0) {
                staticColumnsToAdd.forEach(c => el[c] = crawlData ? crawlData[c] : '')
              }

              if (newPropertyName == 'ResponseColumns' && el[newPropertyName]){
                  hasResponseDataColumns = true;
              }           
            // }

          })

            //Response data contains columns so we must append those columns to the data and remove the old ones
            if (hasResponseDataColumns && el.ResponseData){
              el = {...el, ...el.ResponseData};
              // delete el.ResponseColumns;
              // delete el.ResponseData;
            }
            newRows.push(el)
        });

      });
      return newRows;
    }
    return responseDataRows
  };

  buildTypeValueRows(data: any[], oldPropertyName: string, newPropertyName: string) {
    data.filter(x => {
      if (Array.isArray(x)) {
        x.forEach(r => {
          this._reportingService.renameObjectProperty(r, oldPropertyName, newPropertyName);
          r.crawlStatus = this.returnCrawlStatusObjectFromID(r[newPropertyName]);
        })
      }
      else {
        this._reportingService.renameObjectProperty(x, oldPropertyName, newPropertyName);
        x.crawlStatus = this.returnCrawlStatusObjectFromID(x[newPropertyName]);
      }

    })
    this.displayedColumns[this.displayedColumns.indexOf(oldPropertyName)] = newPropertyName;
    return data;
  }

  returnCrawlStatusObjectFromID(crawlStatusId: number): TypeValue {
    let enums: any = CrawlStatus;
    let res = { name: CommonUtil.returnEnumFlags(enums, crawlStatusId), id: crawlStatusId };
    
    //response data does not have crawlstatus ID unless we want to take the parent 
    if (!res.name) {
      res.name = ' '
    }
    return res;
  }

  onRowClick(crawlDataId, elementIndex) {
    let textOverflowParentClass = 'text-overflow-parent';
    let textOverflowClass = 'text-overflow';
    let preventTextOverflowClass = 'show-text'
    let fixedRowHightClass = 'height-50';
    let preventFixedRowHeightClass = 'expanded-height';
    // let rowEl = this.dataSource.data.find(x=> x.crawlDataId == crawlDataId)      //This doesnt work because we dont have classes for a given row

    //$('td.height-50 *').animate({height: "initial"}, 500) THIS MAY be the only option for transition
    // Reset old cells back to text overflow
    $(this.tblEl._elementRef.nativeElement.querySelectorAll('.' + preventTextOverflowClass)).removeClass(preventTextOverflowClass);
    $(this.tblEl._elementRef.nativeElement.querySelectorAll('.' + preventFixedRowHeightClass)).removeClass(preventFixedRowHeightClass);
    // $(this.tableElement._elementRef.nativeElement.querySelectorAll('.' + preventTextOverflowClass)).removeClass(preventTextOverflowClass);
    // $(this.tableElement._elementRef.nativeElement.querySelectorAll('.' + preventFixedRowHeightClass)).removeClass(preventFixedRowHeightClass);
    
    
    //Loop through row cells and add text overflow prevention class
    if (elementIndex >= 0) {
      $(this.tblEl._elementRef.nativeElement.querySelectorAll('tr:nth-child(' + (elementIndex + 1) + ')' + ' .' + textOverflowParentClass)).addClass(preventTextOverflowClass);
      $(this.tblEl._elementRef.nativeElement.querySelectorAll('tr:nth-child(' + (elementIndex + 1) + ')' + ' .' + textOverflowClass)).addClass(preventTextOverflowClass);
      $(this.tblEl._elementRef.nativeElement.querySelectorAll('tr:nth-child(' + (elementIndex + 1) + ')' + ' .' + fixedRowHightClass)).addClass(preventFixedRowHeightClass);
    }

    this.selectedCrawlDataId = crawlDataId || null;
  }
  textIsLink(text) {
    if (text && typeof(text) == 'string') {
      return ((text.substring(0, 4) == 'http') && (text.substring(4, 7) == '://' || text.substring(4, 8) == 's://'))
    }
    return false;
  }
  selectRowChange(e, col) {
    if (e) {
      //Loop through each selected value to take care of duplicates and validation
      let selectEls = this.tblEl._elementRef.nativeElement.querySelectorAll('select.form-control');
      selectEls.forEach(el=> {

          //If column value matches and it is not this current select element, reset the data to null/value to -1 and trigger error 
            if (e.srcElement.value == el.value && el != e.srcElement){
                this.returnSelectColumnFromValue(el.value).data = null;
                el.value = -1
                $(el).addClass('error');
            }
            if (el.value){
              $(el).removeClass('error');
            }
      })
      //Set new data column value
      this.returnSelectColumnFromValue(e.srcElement.value).data = this.dataSource.filteredData.map(x => { return x[col.name]; })

      // //Clear all errors if we have all the columns we need selected
      // if (this.selectionValuesValid()) {
      //   $(selectEls).removeClass('error');
      // }

      this.onHeaderSelectRowChanged.emit(this.headerSelectedValues);
    }
  }

  columnButtonClicked(e, col){
    if (e){
      let data: HeaderChecked = {  //todo: make this a class
        name: col.name,
        displayName: col.displayName,
        data:  this.dataSource.filteredData.map(x => { return x[col.name] }) 
      }
      this.onColumnButtonClicked.emit(data);
    }
  }
  checkRowChange(e, col){
    if (e){
      // $(e.srcElement.querySelector('.column-checkbox')).toggleClass('checked');
      let existingCol: HeaderChecked = this.headerCheckedColumns.find(x=> x.name == col.displayName);
      if (existingCol){
        this.headerCheckedColumns.splice(this.headerCheckedColumns.indexOf(existingCol), 1)
      }
      else{ //todo: make this a class 
        this.headerCheckedColumns.push({
          name: col.name,
          displayName: col.displayName,
          data:  this.dataSource.filteredData.map(x => { return x[col.name] }) 
        });
      }
      this.onHeaderCheckedRowChanged.emit(this.headerCheckedColumns);
    }
  }
  selectionValuesValid(){
   return (this.headerSelectedValues.filter(x=> x.data && x.data.length > 0).length == this.headerSelecRowData.length); 
  }
  returnSelectColumnFromValue(value){
    let paramColumn = this.headerSelecRowData.find(x=> x.id == value);
    return this.headerSelectedValues.find(x => x.name == paramColumn.name)
  }
  parseTextFormatting(text){
    if (!text){
      return '';
    }
    if (typeof(text) != 'string'){
      return text;
    }
    return text.replace("&amp;","")
  }
  toggleCellDetails(text: string){
    if (text && text.length >= 400){
      this.viewDetailsData.htmlContent = text;
      this.modalService.open(this.mdlViewDetails, {});
    }
  }
  ngAfterViewInit() {
    //Highlight cells individually
    // $('tr td').attr('tabindex',"0");
  }
}
