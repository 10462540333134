import { Component, OnInit, Input } from '@angular/core';
import { BasePage } from '../../shared/basePage';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '../../../../node_modules/@angular/material';
import { LoginService } from '../../_services/login.service';
import { BaseResponse } from '../../_models/Responses/baseResponse';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent extends BasePage implements OnInit {
  public passwordForm: FormGroup;
  public recoveryForm: FormGroup
  public isLoading: boolean = false;
  public uidReset: string;
  @Input() buttonsRight: string;

  public sub: any;
  constructor(public formBuilder: FormBuilder, public router: Router, public snackBar: MatSnackBar, private loginService: LoginService, private activatedRoute: ActivatedRoute) {
    super(null, null, router, null);
    this.sub = this.activatedRoute.params.subscribe(params => {   
      this.uidReset = params['r'];
    });
  }

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      currentPassword: ['', this.userData.forcePasswordReset ? null : Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required]
    }, { validator: matchingPasswords('newPassword', 'confirmNewPassword') });

    this.recoveryForm = this.formBuilder.group({
      emailAddress: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  } 

  public onPasswordFormSubmit(values: Object): void {
    if (this.passwordForm.valid) {
      this.isLoading = true;
      this.callService<BaseResponse>(this.loginService.resetPassword(this.passwordForm.controls.newPassword.value, this.passwordForm.controls.currentPassword.value, this.uidReset), data => {
        if (data.isSuccess){
          this.snackBar.open('Your password was successfully!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.passwordForm.reset();
          this.passwordForm.disable();

          setTimeout(x=> {
            this.router.navigate(['/dashboard'])
          }, 2000)
        }
        else{
          this.submitErrorMsg = data.errorMsg;
          this.errorCode = data.errorCode
        }
        this.isLoading = false;
      })
    }
  }
  public onRecoveryForm(values: Object): void {
    if (this.recoveryForm.valid) {
      this.isLoading = true;
      this.callService<BaseResponse>(this.loginService.submitPasswordReset(this.recoveryForm.controls.emailAddress.value), data => {
        if (data.isSuccess){
          this.snackBar.open('Email sent!', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
          this.passwordForm.reset();
          this.passwordForm.disable();
        }
        else{
          this.submitErrorMsg = data.errorMsg;
          this.errorCode = data.errorCode
        }
        this.isLoading = false;
      })
    }
  }
  

}

export function emailValidator(control: FormControl): {[key: string]: any} {
  var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;    
  if (control.value && !emailRegexp.test(control.value)) {
      return {invalidEmail: true};
  }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
  return (group: FormGroup) => {
      let password= group.controls[passwordKey];
      let passwordConfirmation= group.controls[passwordConfirmationKey];
      if (password.value !== passwordConfirmation.value) {
          return passwordConfirmation.setErrors({mismatchedPasswords: true})
      }
  }
}