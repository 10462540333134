import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { ListsComponent } from './lists/lists.component';
import { ResultsComponent } from './results/results.component';
import { ResultDataComponent } from './results/result-data/result-data.component';
import { CrawlersComponent } from './services/crawlers.component';
import { BusinesslistComponent } from './services/businesslist/businesslist.component';
import { ExhibitorsbymapComponent } from './services/exhibitorsbymap/exhibitorsbymap.component';
import { EmployeesearchComponent } from './services/employeesearch/employeesearch.component';
import { CommodityDataComponent } from './services/commodity-data/commodity-data.component';
import { PropertyListingsComponent } from './services/property-listings/property-listings.component';
import { ResultDetailsDataComponent } from './results/result-details-data/result-details-data.component';
import { LocationDataQueryComponent } from './services/location-data-query/location-data-query.component';
import { ListDetailsDataComponent } from './lists/list-details-data/list-details-data.component';
import { RecoverComponent } from './recover/recover.component';
import { ProfileSettingsComponent } from './profile/profile-settings/profile-settings.component';
import { MarketplaceDataComponent } from './services/marketplace-data/marketplace-data.component';
import { PageScraperComponent } from './services/page-scraper/page-scraper.component';
import { ContactDataComponent } from './services/contactdata/contactdata.component';
import { EmailerComponent } from './services/emailer/emailer.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplateDetailsComponent } from './templates/template-details/template-details.component';
// import { ProfileSettingsComponent } from './profile/profile-settings/profile-settings.component';
const dashboardPage = { title: 'Dashboard', url: '/dashboard' };
export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'login',
        loadChildren: './login/login.module#LoginModule'
      },
      // { path: '', redirectTo: '/crawlers', pathMatch: 'full' },
      {
        path: 'services',
        data: {
          title: 'Services Page',
          urls: [
            dashboardPage,
            { title: 'Services' }
          ]
        },
        component: CrawlersComponent
      },
      {
        path: 'services/businesslist',
        component: BusinesslistComponent,
        data: {
          title: 'Retrieve business data and shipping rates from a list of businesses',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Business Data Search' }
          ]
        }
      },
      {
        path: 'services/locationquery',
        component: LocationDataQueryComponent,
        data: {
          title: 'Retrieve business data based on a Google Maps Search query',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Location Data Query' }
          ]
        }
      },
      {
        path: 'services/exhibitorsbymap',
        component: ExhibitorsbymapComponent,
        data: {
          title: 'Retrieves booth and business information from a mapyourshow URL',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Exhibitors By Map' }
          ]
        }
      },
      // {
      //   path: 'services/employeesearch',
      //   component: EmployeesearchComponent,
      //   data: {
      //     title: 'Retrieve business contact information',
      //     urls: [
      //       dashboardPage,
      //       { title: 'Services', url: '/services' },
      //       { title: 'Employees by business' }
      //     ]
      //   }
      // },
      {
        path: 'services/contactsearch',
        component: ContactDataComponent,
        data: {
          title: 'Retrieve business contact information',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Contacts by business' }
          ]
        }
      },
      {
        path: 'services/commodity-data',
        component: CommodityDataComponent,
        data: {
          title: 'Retrieve commodity categories and shipping classes',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Commodity Data' }
          ]
        }
      },
      
      {
        path: 'services/property-listings',
        component: PropertyListingsComponent,
        data: {
          title: 'Retrieves real estate listing data',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Property Listings' }
          ]
        }
      },
      {
        path: 'services/marketplace-data',
        component: MarketplaceDataComponent,
        data: {
          title: 'Search marketplaces for a given query',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Marketplace Data' }
          ]
        }
      },
      {
        path: 'services/page-scraper',
        component: PageScraperComponent,
        data: {
          title: 'Scrape dynamic data from a list of URLs ',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Business Data Search' }
          ]
        }
      },
      {
        path: 'services/emailer',
        component: EmailerComponent,
        data: {
          title: 'Send emails in bulk from a list of email addresses',
          urls: [
            dashboardPage,
            { title: 'Services', url: '/services' },
            { title: 'Emailer' }
          ]
        }
      },
      // { path: '', redirectTo: '/results', pathMatch: 'full' },
      // { path: '', redirectTo: '/results', pathMatch: 'full' },
      {
        path: 'results',
        component: ResultsComponent,
        data: {
          title: 'View all results for crawls made within your organization',
          urls: [
            dashboardPage,
            { title: 'Results', url: '/results' },
          ]
        }
      },
      {
        path: 'results/:id',
        component: ResultDetailsDataComponent,
        data: {
          title: 'View results from a given request',
          urls: [
            dashboardPage,
            { title: 'Results', url: '/results' },
          ]
        }
      },
      {
        path: 'lists',
        component: ListsComponent,
        data: {
          title: 'View all lists created within your organization',
          urls: [
            dashboardPage,
            { title: 'Lists', url: '/lists' },
          ]
        }
      },
      {
        path: 'lists/:id',
        component: ListDetailsDataComponent,
        data: {
          title: 'View and update list details',
          urls: [
            dashboardPage,
            { title: 'Lists', url: '/lists' },
          ]
        }
      },
      {
        path: 'templates',
        component: TemplatesComponent,
        data: {
          title: 'View all templates created within your organization',
          urls: [
            dashboardPage,
            { title: 'Templates', url: '/templates' },
          ]
        }
      },
      {
        path: 'templates/:id',
        component: TemplateDetailsComponent,
        data: {
          title: 'View and update template data',
          urls: [
            dashboardPage,
            { title: 'Templates', url: '/templates' },
          ]
        }
      },
      {
        path: 'recover',
        component: RecoverComponent
      },
      {
        path: 'profile',
        component: ProfileSettingsComponent,
        data: {
          title: 'Profile settings',
          urls: [
            dashboardPage,
            { title: 'Profile', url: '/profile' },
          ]
        }
        // loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'accounts',
        loadChildren: './accounts/accounts.module#AccountsModule',
        data: {
          title: 'View and modify accounts in your organization',
          urls: [
            dashboardPage,
            { title: 'Accounts', url: '/accounts' },
          ]
        }
      },
      {
        path: 'organizations',
        loadChildren: './organizations/organizations.module#OrganizationsModule',
        data: {
          title: 'View and modify organizations',
          urls: [
            dashboardPage,
            { title: 'Organizations', url: '/organizations' },
          ]
        }
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/services'
  }
];
