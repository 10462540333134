import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthenticatedStorage } from '../_helpers/localStorage';
import { AccountRoles } from '../_enums/accountRoles'
import { LoginResponse } from '../_models/Responses/Account/loginResponse';
import { MatSnackBar, MatHorizontalStepper } from '@angular/material';
import { ComponentBase } from '../_base/component.base';
import { BaseResponse } from '../_models/Responses/baseResponse';
import { CrawlerService } from '../_services/crawlers.service';
import { BasePage } from './basePage';
import { ReportingService } from '../_services/reporting.service';
import { NewList } from '../_models/Requests/Lists/newList';
import { EditorUtilities } from '../_utilities/editorUtilities';
import { CrawlerProperties } from '../_models/common/crawlerProperties';
import { ColumnSettings, EditorOutputData, EditorInputData } from '../_models/common/editorData';
import { CrawlerSettings } from '../_helpers/crawlerSettings';
import { TableUtil } from '../_utilities/tableUtilities';
import { CrawlRequest } from '../_models/Requests/Results/crawlRequest';
import { ColumnData } from '../_models/common/commonData';
import { TableOutput } from '../_models/common/tableData';
import { LocationData } from '../_models/common/google';

export class CrawlerPage extends BasePage implements OnInit {
    @ViewChild(MatHorizontalStepper) stepper: MatHorizontalStepper;
    @ViewChild('lists') public lists;
    @ViewChild('results') public results;

    @ViewChild('listEditor') public listEditor;
    @ViewChild('resultEditor') public resultEditor;

    @ViewChild('format') public format;
    
    public crawlerSettings: CrawlerProperties;
    public staticSettings: CrawlerSettings = new CrawlerSettings();

    public dataSources: any[] = [ { tabId: 0, title: 'List', details: 'Use data from a saved list', enabled: true},{ tabId: 1, title: 'Results', details: 'Use results from a prior crawl request', enabled: true},{ tabId: 2, title: 'New *', details: 'Create new data', enabled: false} ];
    public checkedRowData: ColumnData[] = [];

    public selectedSource: any = {sourceId: 0, crawlRequestId: null, listId: null  }  //turn to class if not already (crawelerProperties.ts)
    public sourceOutputData: EditorInputData = new EditorInputData();
    public sourceSelected: boolean = false;
    public headersSelected: boolean = false;
    public requestHeaderData: ColumnData[] = [];
    public requestListData: any[] = [];
    public stepIndex: number = 0; //wtf do i need this for?
    
    public editorOutputData: EditorOutputData = new EditorOutputData()
    public resultTblEditorLoaded: boolean = false;
    public listTblEditorLoaded: boolean = false;
    public newblEditorLoaded: boolean = false;

    public additionalColumns: any[];

    public recordGroupLimit: number = null;
    public searchOrigin: LocationData = null;
    public groupCountMinimum: number = null;

    public submitModalVisible: boolean = false;
    public submitModalText: string = 'Are you sure you want to submit this crawl request?';
    public globalErrorMsg: string;
    public globalErrorModalVisible: boolean;
    
    constructor(public _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar, public _reportingService?: ReportingService) {
        super(AccountRoles.User, null, router, snackbar);
      }

      ngOnInit() {
      }
      
      setStepIndex(i) {  //wtf do i need this for?
        this.stepIndex = i;

        //Step 1 reinitialization
        if (i == 0) {
          this.resetSelectedSource();
        }
        
      }

      onStepChanged(e){
      }

      setSource(dataSource: any){  //turn to class if not already (crawelerProperties.ts)
        this.selectedSource.sourceId = (dataSource.enabled? dataSource.tabId : this.selectedSource.sourceId )
      
        //New Source
        if (this.selectedSource.sourceId == 2){
          this.sourceSelected = true;
        }

      }
      resetSelectedSource() {
        this.resultTblEditorLoaded = false;
        this.listTblEditorLoaded = false;
        this.newblEditorLoaded = false;

        this.sourceSelected = false;
        
         //IF we dont set 'render' to false then there will be a bug if the user selects a list/next, and then clicks back/selects a result instead
        this.sourceOutputData.render = false;
        this.selectedSource.crawlRequestId = null;
        this.selectedSource.listId = null;

        // this.sourceOutputData.data = [];
        // this.sourceOutputData.columns = [];
        this.sourceOutputData.render = false;

        // let selectedList = this.lists.selectedRow;
        // let selectedResult = this.results.selectedRow;

        this.lists.selectedRow = null;
        this.results.selectedRow = null;

        // setTimeout(x=> { //cant use this to reselect the same source list/result, for some reason it is sending NULL to listRowClicked() (searchCrawlerPage.ts/selectionCrawlerPage.ts) which is throwing an error
        //   this.lists.rowClicked(selectedList)
        //   this.results.rowClicked(selectedResult)
        // }, 250)
        
      }
      listsAdded(lists: NewList[]) {
        if (lists.length == 1) {
          this.selectListAsSource(lists[0]);
        }
        // this.lists.refreshGrid();
      }

      selectListAsSource(list: NewList):Boolean{
        this.selectedSource.listId = list.listId;
        if (list.listData) {
          this.sourceOutputData.data = EditorUtilities.parseDBJSON(list.listData);
          
          this.requestHeaderData =  EditorUtilities.parseDBJSON(list.headersData).map(this.mapColumnNameToColumnData);
          
          this.sourceOutputData.columns = this.requestHeaderData.map(this.mapColumnDataToSettings);
          // this.sourceOutputData.columns = EditorUtilities.parseDBJSON(list.headersData).map((x,i)=>{ //TODO: Make Column Class 
          //   let col: ColumnData = {
          //     displayName: x,
          //     name: i.toString(), //DO NOT USE ORIGINAL PROP NAME AS NAME, USE INDEX  //TODO: rename to dataName? or propName?
          //     index: i,
          //   }
          //   return col;
          // });
          this.requestListData = this.sourceOutputData.data;
        
          this.sourceOutputData.render = true;
          this.sourceSelected = true;
          return true;
          // this.setStepIndex(1);
          // this.stepper.next();
      }

      return false;
    }

    selectResultAsSource(crawlRequest: CrawlRequest): boolean {
      //Check if request is present
      if (!(crawlRequest && crawlRequest.crawlRequestId)) {
        return false;
      }

      //Check if request is still pending completion
      else if (!crawlRequest.dtCompleted) {
        this.toggleToastMessage('Results can not be used until the previous request is complete.', null, null, 'alert-warning');
        this.results.selectedRow = null;
        this.sourceSelected = false;
        return false;
      }

      else {
        this.selectedSource.crawlRequestId = crawlRequest.crawlRequestId;
        this.selectedSource.listId = null;
        this.selectedSource.sourceId = 1; //TODO: Turn to const  
        this.sourceSelected = true;

        //We need to clear the old data
        this.sourceOutputData.data = [];
        this.sourceOutputData.columns = [];

        //We would usually get data here and set render to true BUT instead we are going to let app-results-data component get the data/set render to true instead
        this.sourceOutputData.render = false;
        // this.requestHeaderData = [];
        // this.requestListData = [];
        return true;
      }
    }

    resultsChanged(data: TableOutput){
      this.requestHeaderData = data.columns;
      this.requestListData = data.data;
      this.formatSourceOutputData();
    }
    formatSourceOutputData() {
      setTimeout(x => {
          this.sourceOutputData.columns = this.requestHeaderData.map(this.mapColumnDataToSettings);
          this.sourceOutputData.data = this.requestListData;
          this.sourceOutputData.render = true;
      });
    }
  columnChecked(e: ColumnData[]) {
    this.checkedRowData = e;
  }

  mapColumnNameToColumnData(name: string, index: number): ColumnData{
    return {
      name: index.toString(),
      displayName: name,
      index: index
    }
  }

  mapColumnDataToSettings(column: ColumnData): ColumnSettings {
    return {
      data: column.name,
      displayName: column.displayName,
      type: 'text',
      width: null,
      dataFormat: null
    }
  }
  resultEditorDataChanged(e: EditorOutputData) {
    setTimeout(x => {
      this.editorOutputData = e;
      this.resultTblEditorLoaded = true;
    })

  }
  resultEditorLoaded(){
    setTimeout(x=> {
      this.resultTblEditorLoaded = true;
    })
    
  }
  listEditorLoaded(){
    setTimeout(x=> {
      this.listTblEditorLoaded = true;
    })
    
  }
  newEditorLoaded(){
    setTimeout(x=> {
      this.newblEditorLoaded = true;
    })  
  }
  listEditorDataChanged(e: EditorOutputData){
    setTimeout(x=> {
      this.editorOutputData = e;
      this.listTblEditorLoaded = true;
    })
  }

  formatRequestAdditionalColumnNameStr(existingHeaders: string[], displayName: string, name: string){
    let newDisplayName = EditorUtilities.formatPropertyNameStr(displayName);
    let headers = [].concat(existingHeaders);
    let dupRequestCols = headers.find(x=> x == newDisplayName);
    let dupName = '';
    let dupIndex = 0;

    //Add property to end of list of incrimental properties if duplicate name
    while (dupRequestCols){
      dupIndex++;
      dupName = newDisplayName + this.staticSettings.returnRequestPropNameSuffix(dupIndex.toString());
      dupRequestCols = headers.find(x => x == dupName);    
    }
    
    if (dupIndex > 0){
      headers.push(dupName);
    }

    //No duplicates, just add to list
    else {
      let newSuffix = this.staticSettings.returnRequestPropNameSuffix('');
      let newDisplayName = '';
      // newDisplayName = EditorUtilities.formatPropertyNameStr(newDisplayName);

      //If suffix isnt already present, add it
      if (TableUtil.columnNameHasAdditionalSuffix(name)) {
        let inc = '1';
        if (TableUtil.columnNameHasIncrimentSuffix(name)) {
          inc = (parseInt(TableUtil.returnIncrimentSuffix(name))).toString();
          // inc = (parseInt(TableUtil.returnIncrimentSuffix(name)) + 1).toString();
        }
        newDisplayName = name.substring(0, name.indexOf(newSuffix)) + newSuffix + inc;
      }

      else {
        newDisplayName = name + newSuffix;
      }
      headers.push(newDisplayName);
    }

    return headers
  }
  confirmSubmit(showConfirmationModal: boolean) {
    switch (this.selectedSource.sourceId) {

      //List editor 
      case 0:
        this.listEditor.emitChanges();
        this.listEditor.emitHeaderSelectionChange();
        break;
      //Results editor
      case 1:
        this.resultEditor.emitChanges();
        this.resultEditor.emitHeaderSelectionChange();
        break;
    }
    if (showConfirmationModal){
      this.submitModalVisible = true;
    }
  }
  returnSourceByTabId(tabId){
    return this.dataSources.find(x=> x.tabId == tabId)
  }
  
}
