import { Component, OnInit, AfterViewInit, ViewChild, Output, Input } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { BasePage } from '../../shared/basePage';
import { AccountRoles } from '../../_enums/accountRoles';
import { Router } from '@angular/router';
import { ReportingService } from '../../_services/reporting.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { TableNewComponent } from '../../component/table-new/table-new.component';
import * as XLSX from 'xlsx';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormUtilities } from '../../_utilities/formUtilities';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { TableUtil } from '../../_utilities/tableUtilities';
import { TemplateRecord } from '@models/Requests/Templates/templateRecord';

@Component({
  selector: 'app-templates-list',
  templateUrl: './templates-list.component.html',
  styleUrls: ['./templates-list.component.css']
})
export class TemplatesListComponent extends BasePage implements OnInit, AfterViewInit {
  public dataSource: MatTableDataSource<TemplateRecord>;
  public displayedColumns: string[] = ['templateId', 'templateName', 'tags', 'creatorName', 'dtCreated'];
  public newListsValid: boolean = false;
  public savedLists: TemplateRecord[] = [];
  public focusSavedLists: boolean = false;
  public selectedRow: TemplateRecord;
  
  public newListModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('new') new: any;
  @ViewChild('tbl') tblEl: any;
  @Input() preventRouting: boolean = false;
  @Output() onRowClicked = new EventEmitter<TemplateRecord>();
  @Output() onRowDblClicked = new EventEmitter<TemplateRecord>();
  @Output() onListsAdded = new EventEmitter<TemplateRecord[]>();
  constructor(public _router: Router, private modalService: NgbModal, private reportingService: ReportingService, public snackbar: MatSnackBar) {
    super(AccountRoles.User, null, _router, snackbar);
  }

  ngOnInit() {
    //Move default selected to top
    // this.newListTabs.sort((a,b)=> (a.id == this.selectedRow)? 1 : -1);
  }
  ngAfterViewInit() {
    this.refreshGrid();
  }
  
  refreshGrid(focusTemplateId: number = null) {
    this.populateGrid(focusTemplateId);
  }
  
  rowIsSelected(row: TemplateRecord) {
    let rowIsSelected = (this.selectedRow && this.selectedRow.templateId == row.templateId)
    let rowWasCreated = (this.focusSavedLists && this.savedLists.map(x => x.templateId).indexOf(row.templateId) > -1)
    if (rowIsSelected || rowWasCreated) {
      return true;
    }
    return false;
  }
  // public requestDataFromMultipleSources(): Observable<any[]> {
  //   let response1 = this.http.get(requestUrl1);
  //   let response2 = this.http.get(requestUrl2);
  //   let response3 = this.http.get(requestUrl3);
  //   // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
  //   return forkJoin([response1, response2, response3])
  // }
  openNewList(){
    this.router.navigate(['/templates/new']);
  }
  rowClicked(row:TemplateRecord) {
    this.selectedRow = row;
    this.onRowClicked.emit(row);
    this.selectedRow = row;

    if (!this.preventRouting) {
      this.routeList(row.templateId);
    }
  }
  rowDoubleClicked(row: TemplateRecord){
    this.onRowDblClicked.emit(row);
  }
  
  routeList(listId) {
    this._router.navigate(['/templates/' + listId])
  }
  populateGrid(focusTemplateId: number = null) {
    setTimeout(x => {
      this.isLoading = true;
      this.callService(
        this.reportingService.getTemplates(),
        res => {
          if (!this.sort.active) {
            this.sort.sort({ id: 'templateId', start: 'desc', disableClear: false });
          }
          this.dataSource = new MatTableDataSource(res.templates);

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          //Select row from new data
          if (focusTemplateId){
            this.rowClicked(this.dataSource.data.find(x=> x.templateId == focusTemplateId))
          }
          this.isLoading = false;
        });
    })

  }
}
