import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { TableUtil } from '../../_utilities/tableUtilities';
import { MatTableDataSource } from '../../../../node_modules/@angular/material';
import { LoopCallback } from '../../_utilities/loopCallback';
import { ComponentBase } from '../../_base/component.base';
@Component({
  selector: 'app-table-export',
  templateUrl: './table-export.component.html',
  styleUrls: ['./table-export.component.css']
})
export class TableExportComponent extends ComponentBase implements OnInit, OnChanges {
@Input() data: any[];
@Input() fileName: any;
public dataSource: MatTableDataSource<any>;
   //SHOULD BE OBSERCABLE CUZ IT HOGS UP THE SITE
  constructor() {super(); }

  ngOnInit() {
  }

ngOnChanges(changes: SimpleChanges){
  let fileNameProp = changes['fileName'];
  let dataProp = changes['data'];

  if (dataProp && dataProp.currentValue){
    this.data = dataProp.currentValue
  }
  if (fileNameProp && this.fileName.currentValue){
    this.fileName = fileNameProp.currentValue;
  }
}

  exportTable() {
    TableUtil.jsonToExcel(this.data, this.fileName )
  }
}
