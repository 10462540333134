import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { CrawlerService } from '../_services/crawlers.service';
import { ReportingService } from '../_services/reporting.service';
import { NewList } from '../_models/Requests/Lists/newList';
import { EditorUtilities } from '../_utilities/editorUtilities';
import { CrawlerPage } from './crawlerPage';
import { TypeValue } from '../_helpers/typeValue';
import { ISelectionCrawlerPage, SubmitData } from './icrawlerpage';
import { HeaderSelection, RequestCustomParameter } from '../_models/common/tableData';
import { TableUtil } from '../_utilities/tableUtilities';
import { CrawlRequest } from '../_models/Requests/Results/crawlRequest';

export class SelectionCrawlerPage extends CrawlerPage implements ISelectionCrawlerPage {
  public crawlerType:number;
  public selectedColumnValues: HeaderSelection[];
  public editColRenderersToSkipCount: number = 0;
  public customRequestProperties:RequestCustomParameter[] = [];
  @ViewChild('submitModal') public submitModal;

  constructor(public _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar, public _reportingService?: ReportingService) {
    super(_crawlerService, router, snackbar, _reportingService);
      }

      submit() {
        // let queryColName = 'query';
        let newData = this.mapSubmitData(true);
        this.callService(this._crawlerService.submitCrawlRequest(this.crawlerType, newData.headers, newData.data, this.recordGroupLimit, this.selectedSource.listId, this.selectedSource.crawlRequestId, this.searchOrigin, this.groupCountMinimum), data => {
    
          if (data.isSuccess && data.crawlRequestId) {
            this.toggleToastMessage('Request submitted! Loading results...')
            //TODO: Fire request that doesnt care about getting it back
            setTimeout(x => {
              this.router.navigate(['/results/' + data.crawlRequestId])
            }, 1000);
          }
          else{
            this.toggleToastMessage('There was a problem with your submission, please try again later.', null, null, 'alert-warning ')
            console.log('crawl request submission error:', { request: { requestHeaders: newData.headers, requestData: newData.data }, response: data })
          }
        })
      }

    mapSubmitData(ignoreBlankColumns: boolean): SubmitData{
      let newHeaders = [];
        let newData = [];

        let colCount = this.crawlerSettings.selectColumns.length
        let additionalColCount = this.checkedRowData.length;
        let columnCellCounts: number[] = this.selectedColumnValues.map((x, i) => 0); //index, count

        //Add new headers from selected col values
        const existingRequestData = this.editorOutputData.data;// JSON.parse(JSON.stringify(this.editorOutputData.requestData));

        this.crawlerSettings.selectColumns.forEach((request, i) => {
          newHeaders.push(request.name);
        });

        //Add action header
        if (this.customRequestProperties && this.customRequestProperties.length > 0) {
          for (let i = 0; i < this.customRequestProperties.length; i++) {
            if (newHeaders.findIndex(x => x === this.customRequestProperties[i].name) == -1) {
              newHeaders.push(this.customRequestProperties[i].name);
            }
          }
        }

        //Loop through required columns and make sure there arent any that are entirely blank
        for(let i = 0; i < this.selectedColumnValues.length; i++){
          let columnData = this.editorOutputData.headers.find(x => x.index == this.selectedColumnValues[i].colIndex);
          const columnSettings = this.crawlerSettings.selectColumns.find(c=> c.id.toString() ==  this.selectedColumnValues[i].id);

          if (ignoreBlankColumns && columnSettings.required && existingRequestData.filter(x => x[columnData.name]).length == 0) {
            let blankColumns = this.selectedColumnValues.filter((x, i) => columnCellCounts.filter(c => c == i).length > 0);
            let columnsStr = blankColumns.map((x, i) => { return (i > 0 && i == (blankColumns.length - 1) ? " and " : "") + '"' + x.text + '"' }).join(",");
            this.globalErrorMsg = "One of the required columns contains entirely blank values. Plese check the " + columnsStr + " column" + (columnCellCounts.filter(c => c == 0).length > 1 ? "s" : "") + ".";
            this.submitModalVisible = false;
            this.globalErrorModalVisible = true;
            return;
          }
        };

        //Add formatted record data
        existingRequestData.forEach((rec, recIndex) => {
          let newRec = {};
          let blanklColCount = 0;
          let blankAdditonalRowCount = 0;

          //Loop through select value and generate request
          this.selectedColumnValues.forEach((request, i) => {
            const columnSettings = this.crawlerSettings.selectColumns.find(c=> c.id.toString() == request.id);
            if (Number.isInteger(request.colIndex) && request.colIndex != null){
              let columnData = this.editorOutputData.headers.find(x=> x.index == request.colIndex);
              
              let newVal = rec[columnData.name];
              newRec[i.toString()] = newVal;

              //Check if cell is blank value
              if (!newVal && columnSettings.required){
                  blanklColCount++;
              }
              
              else{
                columnCellCounts[i]++;
              } 
            }
          })
          // //If columns match our select list length, presume successful
          // if (Object.keys(this.requestHeaderData).length == this.selectColumns.length && Object.keys(this.requestListData.filter(x => x && x.length > 0)).length == this.selectColumns.length) {
          //   this.headersSelected = true;
          // }
          // else {
          //   this.headersSelected = false;
          // }


          //Add addition columns to be included as properties
          this.checkedRowData.forEach((c, checkedIndex) => {
            let columnData = this.editorOutputData.headers.find(x => x.index == c.index); //this.editorOutputData.headers.indexOf(this.editorOutputData.headers.find(x=> x.name == c.name));
            let newIndex = (checkedIndex + colCount)  //Has to come after select col index
            let newVal = rec[columnData.name];
            newRec[newIndex.toString()] = newVal;

            //Check if cell is blank value
            if (!newVal) {
              blankAdditonalRowCount++;
            }
          })

          //Loop through custom request properties (if any)
          this.customRequestProperties.forEach((c, i) => {
            if (c.name && c.values.length > 0) {
              newRec[(i + colCount + additionalColCount)] = '[' + c.values.join(',') + ']';
            }
          })

          if ((blanklColCount + blankAdditonalRowCount) != (colCount + additionalColCount)) {
            newData.push(newRec);
          }
        });

        //Add formatted header data
        this.checkedRowData.forEach((c) => {
          //Add request suffix
          // let newName = EditorUtilities.formatPropertyNameStr(c.displayName);// + this.staticSettings.returnRequestPropNameSuffix('1');

          //Check for duplicate header names
          let newHeaderData =  this.formatRequestAdditionalColumnNameStr(newHeaders, c.displayName, c.name);
          newHeaders = newHeaderData;
        })
        //TODO: Remove "To-Fill" Columns From "Required" properties in API
        // //FOreach checke ROW ADD FROM Previous data (OutputData)  RENAME THIS

      return { headers: newHeaders, data: newData }
    }
    resultRowClicked(e: CrawlRequest) {
      //If there is an existing record, clear the row so that we can reload the results data component/fire resultsChanged()
      if (this.selectedSource.crawlRequestId) {
        this.selectedSource.crawlRequestId = null;
        setTimeout(x => {
          this.selectResultAsSource(e);
        });
        return true;
      }

      else {
        this.selectResultAsSource(e);
        return true;
      }
      return false
    }

    resultRowDoubleClicked(e: CrawlRequest){
      if (this.resultRowClicked(e)) {
        this.setStepIndex(1);
      }
      this.stepper.next()
    }

    listRowClicked(e: NewList) {
        this.selectListAsSource(e)
      // this.format.expanded = true;
      // const element = document.getElementById(this.format.id).parentElement as HTMLElement;
      // element.scrollIntoView({behavior: 'smooth'});
    }

    listRowDoubleClicked(e: NewList) {
        this.listRowClicked(e);
        this.setStepIndex(1);
        this.stepper.next()

    }

    listsAdded(lists: NewList[]) {
      if (lists.length == 1) {
        this.selectListAsSource(lists[0]);
      }
      // this.lists.refreshGrid();
    }
    returnAdditionalColumns(){
      if (this.editorOutputData && this.editorOutputData.headers && this.selectedColumnValues){
        return this.editorOutputData.headers.filter(h=> !this.selectedColumnValues.find(s=> s.text.replace(' ','').toLowerCase() == h.displayName.replace(' ','').toLowerCase()));
      }
      return null;
    }
    resultHeaderDataChanged(data: HeaderSelection[]) {
      setTimeout(x => {
        this.selectedColumnValues = data;
        let badColumns = 0;
        //If columns match our select list length, presume successful
        this.crawlerSettings.selectColumns.filter(col=> col.required).forEach(col=> {
          let columnIndex = this.selectedColumnValues.findIndex(x => x.colIndex > (this.editColRenderersToSkipCount - 1) && x.colIndex != null && col.id.toString() == x.id);
          if (columnIndex == -1){
            badColumns++;
          }
        })
        if (badColumns > 0) {
          this.headersSelected = false;
        }
        else {
          this.headersSelected = true;
        }
      })

    }
}
