import { Component, ViewChild, AfterViewInit, OnInit, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrawlRequest } from '@models/Requests/Results/crawlRequest';
import { BasePage } from '@shared/basePage';
import { AccountRoles } from '@enums/accountRoles';
import { Router } from '@angular/router';
import { ReportingService } from '@services/reporting.service';
import { MatSort, MatPaginator, MatPaginatorModule } from '@angular/material';
import { EventEmitter } from '@angular/core';
import { CrawlDetails } from '../../_models/Requests/Results/crawlDetails';
import { ResultDetails, TableOutput } from '../../_models/common/tableData';
@Component({
  selector: 'app-result-details-data',
  templateUrl: './result-details-data.component.html'
})
export class ResultDetailsDataComponent extends BasePage {
  public gridData: TableOutput;
  public headerData: CrawlDetails
  constructor(public _router: Router, public _reportingService: ReportingService) {
    super(AccountRoles.User, null, _router)
  } 
  gridDataChanged(data: TableOutput) {
    if (data) {
      this.gridData = data;
    }
  }
  headerDataChanged(data: CrawlDetails){
    if (data){
      this.headerData = data;
    }
  }
}
