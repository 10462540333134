import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// import { TableData } from '@models/common/tableData';
import { CrawlerService  } from '@services/crawlers.service';
import { AccountRoles } from '@enums/accountRoles';
import { Router } from '@angular/router';
import { BasePage } from '@app/shared/basePage';
import { CrawlerType } from '../../_enums/crawlerTypes';
import { MatSnackBar, MatHorizontalStepper } from '@angular/material';
import { NewList } from '../../_models/Requests/Lists/newList';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { ReportingService } from '../../_services/reporting.service';
import { ISelectionCrawlerPage } from '../../shared/icrawlerpage';
import { SelectionCrawlerPage } from '../../shared/selectionServicePage';
import { CrawlerSettings } from '../../_helpers/crawlerSettings';
import { RequestColumn } from '../../_models/common/crawlerProperties';
import { HeaderSelection } from '../../_models/common/tableData';
import { Observable } from 'rxjs';
import { EmailAddress, ToEmailResponse } from '../../_models/Responses/Account/toEmailResponse';
import { map } from 'rxjs/operators';
import { TemplateRecord } from '../../_models/Requests/Templates/templateRecord';
@Component({
  selector: 'app-emailer',
  templateUrl: './emailer.component.html',
  styleUrls: ['./emailer.component.css','./../../../assets/css/crawler.common.css'],
  encapsulation: ViewEncapsulation.None
})
export class EmailerComponent extends SelectionCrawlerPage implements ISelectionCrawlerPage, OnInit {
  public editColRenderersToSkipCount: number = 0;
  public fileToUpload: File[] = [];
  public templates: MailerPageRequest[] = [];
  public toEmails$: Observable<EmailAddress[]> = this.getAccountEmailAddressesObs();
  public addingEmailForm: AddingEmailForm = this.returnNewEmailModel();
  public editTemplate: MailerPageTemplate = null;
  public emailColumnId: string;
  public fromEmailAddressId: number;
  public fromEmails: EmailAddress[];
  public lastTemplateRowCount: number = 0;
  public templatesRowCount: number = 0;
  public disgardChangesModal: boolean;
  public saving: boolean;
  public showEmailModal: boolean;
  public newEmailError: string;
  constructor(public _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar, public _reportingService?: ReportingService) {
    super(_crawlerService, router, snackbar, _reportingService);
    
    this.crawlerType = CrawlerType.Emailer;
    this.crawlerSettings = new CrawlerSettings().getCrawlerProperties(this.crawlerType);
  }

  ngOnInit() {
    this.submitModalText = 'Are you sure you want to submit this email request?';
  }
  getAccountEmailAddressesObs(){
    return this._reportingService.getMailerAccountEmails().pipe(map((data: ToEmailResponse) => {
      if (data.emails.length > 0) {
        this.fromEmailAddressId = data.emails[0].emailAddressId;
        this.fromEmails = data.emails;
        return data.emails;
      }
      return null;
    }));
  }
  emailerListRow_doubleClick(event) {
    this.listRowDoubleClicked(event);
    this.addNewTemplate();
  }
  emailResultRow_doubleClick(event) {
    this.resultRowDoubleClicked(event)
    this.addNewTemplate();
  }
  prepareDataStep() {
    this.addNewTemplate();
    this.setStepIndex(1)
  }
  toggleEmailModal(showModal: boolean){
    if (showModal){
      this.addingEmailForm.saving = false;
      this.showEmailModal = true;
    }
    else{
      this.showEmailModal = false;
    }
  }
  saveNewEmail(){
    if (this.newEmailValid()){
      this.addingEmailForm.saving = true;
      this.callService(this._reportingService.addMailerForwardAddress({
        fromEmailAddress: this.addingEmailForm.fromEmailAddress,
        firstName: this.addingEmailForm.firstName,
        lastName: this.addingEmailForm.lastName,
        isActive: true
      }), x=> {
        if (x.isSuccess){
          this.addingEmailForm = this.returnNewEmailModel();
          this.toEmails$ = this.getAccountEmailAddressesObs();
          this.showEmailModal = false;
        }
        else switch (x.returnCode){
          case -1:
            this.newEmailError = "This email address has already been taken. Please choose another"
            break;
        }
      })
    }
  }
  returnNewEmailModel(): AddingEmailForm{
    this.newEmailError = null;
    return  {
      firstName: null,
      lastName: null,
      fromEmailAddress: null,
      saving: false
    }
  }
  newEmailValid(){
    return this.addingEmailForm.fromEmailAddress && this.addingEmailForm.firstName && this.addingEmailForm.lastName
  }
  addNewTemplate() {
    let emailColIndex = -1;
    let assumedEmailColIndex = this.sourceOutputData.columns.findIndex(x => x.displayName && x.displayName.toLowerCase().replace(' ', '').indexOf('email') > -1)
    if (assumedEmailColIndex > -1) {
      emailColIndex = assumedEmailColIndex;
    }
    this.editTemplate = {
      id: null,
      template: null,
      requiredColumns: [],
    }
    this.emailColumnId = emailColIndex > -1 ? this.sourceOutputData.columns[emailColIndex].data : this.sourceOutputData.columns[0].data;
  }

  cancelNewTemplate() {
    this.editTemplate = null;
  }
  removeMailerTemplate(index){
    this.templates.splice(index, 1);
    if (this.templates.length > 0) {
      this.calculateTemplateRowCount(this.templates[0].data);
    }
  }
  templateHeaderDataChanged(data: HeaderSelection[]) {
    setTimeout(x => {
      this.selectedColumnValues = data.map(x => { return { ...x, required: true } });
      let badColumns = 0;
      //If columns match our select list length, presume successful
      this.crawlerSettings.selectColumns.filter(col=> col.required).forEach(col=> {
        let columnIndex = this.selectedColumnValues.findIndex(x => x.colIndex != null && col.name.toLowerCase().toString() == x.text.toLowerCase().toString());
        if (columnIndex == -1){
          badColumns++;
        }
      })
      if (badColumns > 0) {
        this.headersSelected = false;
      }
      else {
        this.headersSelected = true;
      }
    })

  }
  selectSavedTemplate(template: TemplateRecord) {
    // this.editTemplate.templateData 
    this.editTemplate.template = template;
    this.editTemplate.requiredColumns = template.tags? template.tags.map((x: string, i: number) => {
      return {
        id: i + 1,
        name: x,
        required: true
      }
    }) : this.editTemplate.requiredColumns;
    this.crawlerSettings.selectColumns = this.editTemplate.requiredColumns;
    return true;
  }
  emailColumnSelect_onChange(event){
   this.emailColumnId = event.target.value;
  }
  save() {
    this.confirmSubmit(false);
    this.saving = true;
    const emailColumnIndex = this.editorOutputData.headers.findIndex((x,i) => x.name == this.emailColumnId);

    if (emailColumnIndex == -1){
      this.globalErrorMsg = "Unable to find the email column in the input data";
      this.globalErrorModalVisible = true;
      this.saving = false;
      return false;
    }
    //TODO: Make CheckedRowData ignore adding 'additionalColumn' str suffix
    this.checkedRowData.push({ index: emailColumnIndex, name: this.editorOutputData.headers[emailColumnIndex].displayName, displayName: this.sourceOutputData.columns[emailColumnIndex].displayName })
    setTimeout(x => {
      let newData = this.mapSubmitData(false);
      // let newTemplate = { ...this.editTemplate, id: this.templates.length + 1, headers: newData.headers, data: newData.data };
      let requestData: any[] = [];
      // let requestHeaders = ['To Email', 'Template Name', 'Message Body'];
      if (newData.data.length == 0){
        this.globalErrorMsg = "There are no rows with data to process";
        this.globalErrorModalVisible = true;
        this.saving = false;
        return false;
      }
      const newEmailColIndex = newData.headers.findIndex(h=> {
        let displayName = this.editorOutputData.headers[emailColumnIndex].displayName.toLowerCase();

        if (h.toLowerCase() == displayName){
          return true;
        }
        if (h.toLowerCase().indexOf(displayName + '_additionalcolumn') > -1){
          return true;
        }
        return false;
      })
      newData.data.forEach(record=> {
        // const ToEmail = record[this.sourceOutputData.columns[emailColumnIndex].data]; 
        const toEmail = record[newEmailColIndex.toString()];

        let messageBody = this.editTemplate.template.templateData;
        let messageSubject = this.editTemplate.template.subject;

        newData.headers.forEach((h: string, i: number) => {
              const replacementTag = '{' + h + '}';
              const replacementVal = record[i.toString()];
              while (messageBody.indexOf(replacementTag) > -1){
                messageBody = messageBody.replace(replacementTag, replacementVal)
              }

              while (messageSubject.indexOf(replacementTag) > -1){
                messageSubject = messageSubject.replace(replacementTag, replacementVal)
              }
            })
        requestData.push({ 0: null, 1: toEmail, 2: messageSubject, 3: this.editTemplate.template.templateName, 4: messageBody })
      })
      this.templates.push({ data: requestData, template: this.editTemplate.template })
      this.calculateTemplateRowCount(requestData);

      this.editTemplate = null;
      this.saving = false;
    }, 250)
  }
  calculateTemplateRowCount(requestData: any[]){
    
    this.templatesRowCount = requestData.length / this.templates.length;
    this.lastTemplateRowCount = 0;
    let extraRows = 0;
    if (!Number.isInteger(this.templatesRowCount)){
      this.templatesRowCount = Math.floor(this.templatesRowCount);
      extraRows = requestData.length - (this.templatesRowCount * this.templates.length);
    }
    this.lastTemplateRowCount = this.templatesRowCount + extraRows;
  }
  confirmDiscardChanges(){
    if (this.templates.length > 0){
      this.disgardChangesModal = true;
    }
    else{
      this.returnToFirstStep();
    }
  }
  submitTemplates(){
    let requestHeaders = ['From Email', 'To Email', 'Subject', 'Template Name', 'Message Body'];
    let requestData = [];

    //Split rows evenly between the templates
    this.templates.forEach((requestTemplate, index) => {
      let isLastTemplate = index == this.templates.length - 1;
      let startIndex = (index * this.templatesRowCount);
      let endIndex = (startIndex + (isLastTemplate? this.lastTemplateRowCount : this.templatesRowCount));
      let splicedRows = requestTemplate.data.slice(startIndex, endIndex);

      requestData.push(...splicedRows.map(x => {
        x[0] = this.fromEmails.find(e => e.emailAddressId == this.fromEmailAddressId).fromEmailAddress;
        return x;
      }));
    });
    this.callService(this._crawlerService.submitEmailRequest(requestHeaders, requestData, this.selectedSource.listId, this.selectedSource.crawlRequestId), data => {
    
      if (data.isSuccess && data.crawlRequestId) {
        this.toggleToastMessage('Request submitted! Loading results...')
        //TODO: Fire request that doesnt care about getting it back
        setTimeout(x => {
          this.router.navigate(['/results/' + data.crawlRequestId])
        }, 1000);
      }
      else{
        this.toggleToastMessage('There was a problem with your submission, please try again later.', null, null, 'alert-warning ')
        console.log('mailer request submission error:', { request: { requestHeaders: requestHeaders, requestData: requestData }, response: data })
      }
    })
  }
  returnToFirstStep() {
    this.templates = [];
    this.editTemplate = null;
    this.emailColumnId = null;
    this.disgardChangesModal = false;
    this.editorOutputData = {
      data: [],
      headers: []
    }
    this.stepper.previous();
    this.setStepIndex(0);
  }
}

export interface MailerPageTemplate{
  id?: number;
  template: TemplateRecord;
  requiredColumns: RequestColumn[];
}
export interface MailerPageRequest{
  data: any[];
  template: TemplateRecord;
}
export interface AddingEmailForm {
  fromEmailAddress: string,
  firstName: string,
  lastName: string,
  saving: boolean
}