import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Approutes } from './app-routing';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { ReportingComponent } from './reporting/reporting.component';
import { ApiService } from './_services/api.service';
import { AuthenticatedStorage } from './_helpers/localStorage';
import { SharedModule } from './_common/common.module';
import { LoginModule } from './login/login.module';
import { AppInterceptor } from './_helpers/app-interceptor';
import { ReportingService } from './_services/reporting.service';
// import { CrawlersModule } from './services/crawlers.module';
// import { ResultsModule } from './results/results.module';
import { ResultsComponent } from './results/results.component';
import { ResultsListomponent } from './results/results-list/results-list.component';
import { ResultDataComponent } from './results/result-data/result-data.component';
import { CommodityDataComponent } from './services/commodity-data/commodity-data.component';
import { ExhibitorsbymapComponent } from './services/exhibitorsbymap/exhibitorsbymap.component';
import { EmployeesearchComponent } from './services/employeesearch/employeesearch.component';
import { BusinesslistComponent } from './services/businesslist/businesslist.component';
import { PropertyListingsComponent } from './services/property-listings/property-listings.component';
import { DraganddropDirective } from './_directives/draganddrop.directive';
import { CrawlersComponent } from './services/crawlers.component';
import { ListsComponent } from './lists/lists.component';
import { ResultDetailsComponent } from './results/result-details/result-details.component';
import { TableExportComponent } from './component/table-export/table-export.component';
import { FormsModule } from '@angular/forms';
import { ResultDetailsDataComponent } from './results/result-details-data/result-details-data.component';
import { LocationDataQueryComponent } from './services/location-data-query/location-data-query.component';
import { TableEditorComponent } from './component/table-editor/table-editor.component';
import { TableNewComponent } from './component/table-new/table-new.component';
import { HotTableModule } from '@handsontable/angular';
import { ModalComponent } from './component/modal/modal.component';
import { ListEditDetailsComponent } from './lists/list-edit-details/list-edit-details.component';
import { ListDataComponent } from './lists/list-data/list-data.component';
import { ListDetailsComponent } from './lists/list-details/list-details.component';
import { ListDetailsDataComponent } from './lists/list-details-data/list-details-data.component';
import { ListsListComponent } from './lists/lists-list/lists-list.component';
import { RequiredListComponent } from './component/required-list/required-list.component';
import { AdditionalColumnsComponent } from './component/additional-columns/additional-columns.component';
import { LimitGroupByCountComponent } from './component/limit-group-by-count/limit-group-by-count.component';
import { GroupCountMinimumComponent } from './component/group-count-minimum/group-count-minimum.component';
import { RadiusStartComponent } from './component/radius-start/radius-start.component';
import { CrawlerSettings } from './_helpers/crawlerSettings';
import { SocketService } from './_services/socketService';
import { HubService } from 'ngx-signalr-hubservice';
import { SignalRModule } from 'ng2-signalr';
import { SignalRConfiguration, SignalR } from 'ng2-signalr';
import { environment } from '../environments/environment';
import { RecoverComponent } from './recover/recover.component';
import { PasswordComponent } from './profile/password/password.component';
import { ProfileSettingsComponent } from './profile/profile-settings/profile-settings.component';
import { LoginResponse } from './_models/Responses/Account/loginResponse';
import { MarketplaceDataComponent } from './services/marketplace-data/marketplace-data.component';
import { PageScraperComponent } from './services/page-scraper/page-scraper.component';
import { ContactDataComponent } from './services/contactdata/contactdata.component';
import { EmailerComponent } from './services/emailer/emailer.component';
import { TemplatesComponent } from './templates/templates.component';
import { TemplatesListComponent } from './templates/templates-list/templates-list.component';
import { TemplateDetailsComponent } from './templates/template-details/template-details.component';
// import { GoogleMapsModule, GoogleMap } from '@angular/google-maps'
// import { ProfileModule } from './profile/profile.module';
// import { ProfileSettingsComponent } from './profile/profile-settings/profile-settings.component';

export function createConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  let userData: LoginResponse = new AuthenticatedStorage().userData();
  c.hubName = 'Requestlog';
  c.qs = { user: userData? userData.accountId: null };
  c.url = environment.apiEndPoint + '/api';  
  c.logging = true;
  
  // >= v5.0.0
  c.executeEventsInZone = true; // optional, default is true
  c.executeErrorsInZone = false; // optional, default is false
  c.executeStatusChangeInZone = true; // optional, default is true
  return c;
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    ReportingComponent,
    BusinesslistComponent,
    PageScraperComponent,
    PropertyListingsComponent,
    MarketplaceDataComponent,
    DraganddropDirective,
    CrawlersComponent,
    LocationDataQueryComponent,
    EmployeesearchComponent,
    ContactDataComponent,
    ExhibitorsbymapComponent,
    CommodityDataComponent,
    ListsListComponent,
    ListsComponent,
    ResultsComponent,
    ResultDetailsDataComponent,
    ResultsListomponent,
    ResultDataComponent,
    ResultDetailsComponent,
    TableExportComponent,
    TableEditorComponent,
    TableNewComponent,
    ModalComponent,
    ListEditDetailsComponent,
    ListDataComponent,
    ListDetailsComponent,
    ListDetailsDataComponent,
    RequiredListComponent,
    AdditionalColumnsComponent,
    LimitGroupByCountComponent,
    RadiusStartComponent,
    GroupCountMinimumComponent,
    RecoverComponent,
    PasswordComponent,
    ProfileSettingsComponent,
    EmailerComponent,
    TemplatesComponent,
    TemplatesListComponent,
    TemplateDetailsComponent
  ],
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
    LoginModule,
    SharedModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes),
    PerfectScrollbarModule,
    HotTableModule,
    SignalRModule.forRoot(createConfig),
    // GoogleMapsModule
    
  ],
  exports: [SharedModule],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    ApiService,
    AuthenticatedStorage,
    ReportingService,
    CrawlerSettings,
    SocketService,
    HubService,
    // SignalRClient,
  ],

  bootstrap: [AppComponent]
})

export class AppModule { 
}
