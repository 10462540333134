import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { CrawlerSettings } from '../../_helpers/crawlerSettings';
import { CrawlerProperties } from '../../_models/common/crawlerProperties';
import { last } from '../../../../node_modules/rxjs/operators';
import { LocationData } from '../../_models/common/google';
// import { GoogleMap } from '../../../../node_modules/@angular/google-maps';  //Removed when we uninstalled googlemaps

@Component({
  selector: 'app-radius-start',
  templateUrl: './radius-start.component.html',
  styleUrls: ['./radius-start.component.css']
})
export class RadiusStartComponent implements OnInit {
  @Input() crawlerTypeId: number;
  @Output() valueChanged = new EventEmitter();
  @Output() locationChanged = new EventEmitter<LocationData>();
  // @ViewChild(GoogleMap) map: GoogleMap //Removed when we uninstalled googlemaps
  @ViewChild('map') mapEl: any
  // @ViewChild('map') map: any
  // @ViewChild('mapInfo') infoWindow: any
  public crawlerProperties: CrawlerProperties;
  public inputVal: string;
  public userPostal = '';
  public showMap: boolean;
  public enableMap = false;
  public errorMsg = "";
  public successMsg = "";
  // public latitude: string = null;
  // public longitude: string = null;
  
  public markers = []
  // public infoContent: any;

  public mapZoom: number = 12;
  // public mapCenter: google.maps.LatLngLiteral; //Removed when we uninstalled googlemaps
  public mapCenter: LocationData = { lat: null, lng: null };  //Added when we uninstalled googlemaps
  // public mapOptions: google.maps.MapOptions = {  //Removed when we uninstalled googlemaps
  //   mapTypeId:  'hybrid',
  //   zoomControl: false,
  //   scrollwheel: false,
  //   disableDoubleClickZoom: true,
  //   maxZoom: 15,
  //   minZoom: 8,
  // }

  constructor(public crawlerSettings: CrawlerSettings) {
    this.inputVal = this.userPostal;
    // this.buildMap(); //Removed when we uninstalled googlemaps
  }

  ngOnInit() {
    this.initializeCrawlerProperties();
  }
  initializeCrawlerProperties() {
    this.crawlerProperties = this.crawlerSettings.getCrawlerProperties(this.crawlerTypeId)
    this.inputVal = this.userPostal;
  }
  ngAfterViewInit() {
    this.emitChanges();
  }

  keyup(e) {
    if (e) {
      this.emitChanges();
    }
  }

  emitChanges() {
    this.valueChanged.emit(this.inputVal);
  }
  emitLocation(){
    this.locationChanged.emit(this.mapCenter && this.mapCenter.lat && this.mapCenter.lng ? this.mapCenter : null);
  }
  onBlur(e) {
    if (this.inputVal) {
      //this.buildMap();  //Removed when we uninstalled googlemaps
      this.getCoordinates(this.inputVal);
    }
  }

  getCoordinates(searchText: string) {  //Added when we uninstalled googlemaps
    //Use zipcode to get lat/long
    if (searchText) {
      fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + searchText + '&key=' + 'AIzaSyCpB-i2u3EJxSO-DYfsNhMh_jgHhPmlGms')
        .then(response => response.json())
        .then(data => {
          this.mapCenter = { lat: null, lng: null };
          let firstResult = data.results[0];
          
          // Coordinates found from zipcode so show the map
          if (firstResult) {
            this.mapCenter = {
              lat: firstResult.geometry.location.lat,
              lng: firstResult.geometry.location.lng
            }
            
            this.errorMsg = null;
            this.successMsg = firstResult.formatted_address + ' (' + this.mapCenter.lat + ', ' + this.mapCenter.lng + ')'
          }

          else {
            this.errorMsg = 'Invalid zip code';
            this.successMsg = null;
          }

          this.emitLocation();
        })
    }
    else {
      this.errorMsg = null;
      this.successMsg = null;
      this.emitLocation();
    }
  }

  // buildMap() { //Removed when we uninstalled googlemaps
  //   if (!this.enableMap){
  //     return false;
  //   }
  //   this.showMap = false;

  //   //Use zipcode to get lat/long
  //   if (this.inputVal) {
  //     fetch("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.inputVal + '&key=' + 'AIzaSyCpB-i2u3EJxSO-DYfsNhMh_jgHhPmlGms')
  //       .then(response => response.json())
  //       .then(data => {
  //         let firstResult = data.results[0];
  //         this.mapCenter = {
  //           lat: firstResult.geometry.location.lat,
  //           lng: firstResult.geometry.location.lng
  //         }
  //       // Coordinates found from zipcode so show the map
  //       if (this.mapCenter.lat && this.mapCenter.lng) {
  //         // this.map.position.lat = this.center.lat;
  //         // this.map.position.lng = this.center.lng;
  //         this.setMainMarkerRadius(this.mapCenter, true);
  //         this.mapRefresh();
  //       }

  //       //No results
  //       else{
  //         this.showMap = false;
  //       }
  //       // this.latitude = data.results.geometry.location.lat;
  //       // this.longitude = data.results.geometry.location.lng;
  //       // console.log({latitude, longitude})
  //     })

  //   }
  //   //No postal, hide the map
  //   else{
  //     this.showMap = false
  //   }
  //   // //Use current lat/long
  //   // else {
  //     // if (navigator.geolocation) {
  //   //   navigator.geolocation.getCurrentPosition(position => {
  //   //     this.center = {
  //   //       lat: position.coords.latitude,
  //   //       lng: position.coords.longitude,
  //   //     }

  //   //     //lat/long found, show map
  //   //     if (this.center.lat && this.center.lng){
  //   //       this.showMap = true;
  //   //     }
  //   //   })
  //     // }
  //   // }
  // }

  // mapRefresh(zoom: number = null) {  //Removed when we uninstalled googlemaps
  //   debugger;
  //   this.map.zoom = Number.isInteger(zoom) ? zoom : this.mapZoom;
  //   this.map
  //   this.showMap = true;
  //   google.maps.event.trigger(this.mapEl._elementRef.nativeElement, "resize");
  // }

  // addMarker(location) {  //Removed when we uninstalled googlemaps
  //   this.markers.push({
  //     position: {
  //       lat: location.lat + ((Math.random() - 0.5) * 2) / 10,
  //       lng: location.lng + ((Math.random() - 0.5) * 2) / 10,
  //     },
  //     label: {
  //       color: 'red',
  //       text: '' // 'Origin: ' + (this.markers.length + 1),
  //     },
  //     title: '', //'Marker title ' + (this.markers.length + 1),
  //     info: '', //'Marker info ' + (this.markers.length + 1),
  //     options: {
  //       animation: google.maps.Animation.BOUNCE,
  //     },
  //   })
  // }

  // setMainMarkerRadius(location, clearExistingMarkers: boolean) { //Removed when we uninstalled googlemaps
  //   this.markers = clearExistingMarkers? []: this.markers;
  //   this.markers.push({
  //     position: {
  //       lat: location.lat,
  //       lng: location.lng
  //     },
  //     label: {
  //       color: 'red',
  //       text: '' // 'Origin: ' + (this.markers.length + 1),
  //     },
  //     title: '', //'Marker title ' + (this.markers.length + 1),
  //     info: '', //'Marker info ' + (this.markers.length + 1),
  //     options: {
  //       animation: google.maps.Animation.BOUNCE,
  //     },
  //   })
  //   var sunCircle = {
  //       strokeColor: "#3F51B5",
  //       strokeOpacity: 0.8,
  //       strokeWeight: 2,
  //       fillColor: "#3F51B5",
  //       fillOpacity: 0.35,
  //       center: location,
  //       radius: 15000 // in meters
  //   }; 
  //   var cityCircle = new google.maps.Circle(sunCircle);
  //   cityCircle.bindTo('center', this.markers[0], 'position');
  // }

  // openInfo(marker: MapMarker, content) {
  //   this.infoContent = content;
  //   this.infoWindow.open(marker)
  // }
  // zoomIn() { //Removed when we uninstalled googlemaps
  //   if (this.mapZoom < this.mapOptions.maxZoom) this.mapZoom++
  // }

  // zoomOut() {  //Removed when we uninstalled googlemaps
  //   if (this.mapZoom > this.mapOptions.minZoom) this.mapZoom--
  // }
}
  //   if (this.latitude && this.longitude) {
  //   // var latlng = new google.maps.LatLng(-34.397, 150.644);
  //   var latlng = new google.maps.LatLng(this.latitude, this.longitude);
  //   var mapOptions = {
  //       center: latlng,
  //       zoom: 9,
  //       mapTypeId: google.maps.MapTypeId.ROADMAP
  //   };
  //   var el= this.modal.nativeElement
  //   var map = new google.maps.Map(el, mapOptions);

  //   var marker = new google.maps.Marker({
  //       map: map,
  //       position: latlng
  //   });

  //   var sunCircle = {
  //       strokeColor: "#3F51B5",
  //       strokeOpacity: 0.8,
  //       strokeWeight: 2,
  //       fillColor: "#3F51B5",
  //       fillOpacity: 0.35,
  //       map: map,
  //       center: latlng,
  //       radius: 15000 // in meters
  //   };
  //   var cityCircle = new google.maps.Circle(sunCircle);
  //   cityCircle.bindTo('center', marker, 'position');
  //   this.showMap = true;
  // }
  // else{
  //   this.showMap = false;
  // }
// }

// }
