import { Component, OnInit, AfterViewInit, ViewChild, Output, Input } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { ListRecord } from '../_models/Requests/Lists/listRecord';
import { BasePage } from '../shared/basePage';
import { AccountRoles } from '../_enums/accountRoles';
import { Router } from '@angular/router';
import { ReportingService } from '../_services/reporting.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { TableNewComponent } from '../component/table-new/table-new.component';
import * as XLSX from 'xlsx';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormUtilities } from '../_utilities/formUtilities';
import { NewList, ListData } from '../_models/Requests/Lists/newList';
import { TableUtil } from '../_utilities/tableUtilities';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.css']
})
export class ListsComponent extends BasePage implements OnInit {  
  constructor(public _router: Router, public _reportingService: ReportingService) {
    super(AccountRoles.User, null, _router)
  } 

  ngOnInit() {

  }
}