import { Component, ViewChild, AfterViewInit, OnInit, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrawlRequest } from '@models/Requests/Results/crawlRequest';
import { BasePage } from '@shared/basePage';
import { AccountRoles } from '@enums/accountRoles';
import { Router } from '@angular/router';
import { ReportingService } from '@services/reporting.service';
import { MatSort, MatPaginator, MatPaginatorModule } from '@angular/material';
import { EventEmitter } from '@angular/core';
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html'
})
export class ResultsComponent extends BasePage implements OnInit {  
  constructor(public _router: Router, public _reportingService: ReportingService) {
    super(AccountRoles.User, null, _router)
  } 

  ngOnInit() {

  }
}
