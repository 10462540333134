import { Component, OnInit, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { BasePage } from '../../shared/basePage';
import { AccountRoles } from '../../_enums/accountRoles';
import { ReportingService } from '../../_services/reporting.service';
import { CrawlDetails } from '../../_models/Requests/Results/crawlDetails';
import { ActivatedRoute, Router } from '@angular/router';
import { CrawlerType } from '../../_enums/crawlerTypes';
import { SocketService } from '../../_services/socketService';
import { CrawlerUtilities } from '../../_utilities/crawlerUtiltiies';
import { TableUtil } from '../../_utilities/tableUtilities';
import { CrawlStatus } from '../../_enums/crawlStatus';
import { CommonUtil } from '../../_utilities/common';
import { ResultDetails, TableOutput } from '../../_models/common/tableData';
import { Observable, of } from 'rxjs';
import { CrawlerService } from '../../_services/crawlers.service';
import { BaseResponse } from '../../_models/Responses/baseResponse';
import { MatSnackBar } from '../../../../node_modules/@angular/material';
import { HttpXsrfCookieExtractor } from '../../../../node_modules/@angular/common/http/src/xsrf';

@Component({
  selector: 'app-result-details',
  templateUrl: './result-details.component.html',
  styleUrls: ['./result-details.component.css']
})
export class ResultDetailsComponent extends BasePage implements OnInit, OnChanges {
  @Input() crawlRequestID: number;
  @Input() gridData: TableOutput;
  @Output() dataChanged = new EventEmitter()
  
  public request: CrawlDetails;
  public editingRequest: CrawlDetails = new CrawlDetails();
  public crawlActionsModalText: string;
  public crawlActionsModalVisible: boolean = false;
  public crawlActionShowRemoveAll: boolean = false;
  public removeAllDataBeforeCrawl: boolean = false;
  public requestRowsAttempted: number;
  public rowsWithData: number;
  public dtLastUpdated: string;
  public editing: boolean;
  public saving: boolean;
  
  private socketDataPresent:boolean;
  private refreshLoop: ()=> void = null;
  // private actionObs: (request: any) => Observable<any> = null;
  public crawlActionId: number;
  constructor(private _reportingService: ReportingService
    , private socketService: SocketService
    , private activatedRoute: ActivatedRoute
    , private crawlerService: CrawlerService
    , public router: Router
    , snackbar: MatSnackBar) {
    super(AccountRoles.User, null, router, snackbar);
    this.isLoading = true;
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.socketDataPresent = true;
      this.crawlRequestID = params['id'] || this.crawlRequestID;
      this.getData();
    })

    //Subscribe to socket/data changes
    this.socketService.onQueueItemCompleted.subscribe(data => {
      this.socketDataPresent = true;
      if (data && data.crawlRequestId == this.crawlRequestID){
        this.getData(false);
      }
    });
  }

  // crawlDataUpdatedLoop() {
  //   if (!this.request.dtCompleted)
  //     setTimeout(x => {
  //       this.callService(this._reportingService.getCrawlDataDateLastUpdated(this.crawlRequestID), data => {
  //         this.dtLastUpdated = data;
  //         this.crawlDataUpdatedLoop();
  //       });

  //     }, 10000)
  // }
  ngOnChanges(changes: SimpleChanges) {
    const gridData = changes['gridData'];
    if (gridData && gridData.currentValue) {
      this.gridData = gridData.currentValue;  //probably not needed here since we only want to update the refreshLoop
      this.requestRowsAttempted = this.gridData.data.filter(x=> x.crawlStatus.indexOf(CrawlStatus[CrawlStatus.Complete].toString()) > -1 || x.crawlStatus.indexOf(CrawlStatus[CrawlStatus.HasErrors].toString()) > -1 || x.crawlStatus.indexOf(CrawlStatus[CrawlStatus.Failed].toString()) > -1).length;
      this.rowsWithData = this.gridData.rowsWithDataCount;

      this.refreshLoop = !(this.gridData && this.gridData.data) || (!CrawlerUtilities.isCompletedRequest(this.gridData.data))? this.refreshIfInComplete : null;
    }
  }
  getData(firstLoad: boolean = true){
    this.callService(this._reportingService.getCrawlDetails(this.crawlRequestID), data => {
      this.request = data;
      this.editingRequest = data;
      this.request.crawlerType = CrawlerType[this.request.crawlerTypeId];
      this.isLoading = false;
      this.dataChanged.emit(this.request);
      // this.crawlDataUpdatedLoop();
      this.intializeRefreshLoop(30000);
      
    });

    this.callService(this._reportingService.getCrawlDataDateLastUpdated(this.crawlRequestID), data => {
      this.dtLastUpdated = data;
    });
  }
 
  intializeRefreshLoop(waitMs: number) {
    this.refreshLoop = (!this.request.dtCompleted && (!(this.gridData && this.gridData.data) || (!CrawlerUtilities.isCompletedRequest(this.gridData.data)))) ? this.refreshIfInComplete : null;

    if (this.refreshLoop) {
      setTimeout(data => {
        //Refresh data every 10 seconds if socket refresh is still failing
        if (this.refreshLoop) {
          this.refreshLoop();
        }
      }, waitMs)
    }
  }
  
  refreshIfInComplete() {
    if (this.socketService.connectionIsEstablished && !this.socketDataPresent) {
      let gridDataSource: any = this.gridData && this.gridData.data ? this.gridData : null;
      if (gridDataSource && (!this.request.dtCompleted)) {
        if (!CrawlerUtilities.isCompletedRequest(gridDataSource)) {
          this.getData(false);
        }
      }
    }
  }
   
  resultsPerRecord(): any{
    return this.request && this.request.totalRows && this.rowsWithData && this.gridData && this.gridData.data? (Math.round(this.gridData.data.length / this.rowsWithData)) : ''
  }

  dtTriggered(): string{
    if (this.request && this.request.dtCreated){
      return this._reportingService.dtTriggered(this.request);
    }
    return '';
  }

  forceStop(){
    this.crawlActionId = 1;
    this.toggleActionModal(true, 'stop', 'No more idle rows will be processed.', false);
    
  }
  forceStart(){
    this.crawlActionId = 2;
    this.toggleActionModal(true, 'start', 'Any idle rows will be procesed.', false);
  }
  forceRecrawl(){
    this.crawlActionId = 3;
    this.removeAllDataBeforeCrawl = false;  //reset options
    this.toggleActionModal(true, 'recrawl', 'All request rows will be processed.', true);
  }
  toggleActionModal(visible: boolean, actionText: string, suffixText: string = null, showRemoveAllCheckbox: boolean = false){
    this.crawlActionsModalText = 'Are you sure you want to ' + actionText + ' this crawl request? ' + (suffixText || '');
    this.crawlActionsModalVisible = visible;
    this.crawlActionShowRemoveAll = showRemoveAllCheckbox;
  }
  submitAction() {
    if (this.crawlActionId) {
      let request = {
        crawlRequestId: this.crawlRequestID,
        clearOldData: this.removeAllDataBeforeCrawl
      };

      switch (this.crawlActionId) {
        case 1:
          this.callService(this.crawlerService.submitForceStopRequest(request), data => {
            let res: BaseResponse = data;
            this.actionSubmitted(res);
          });
          break;
        case 2:
          this.callService(this.crawlerService.submitForceStartRequest(request), data => {
            let res: BaseResponse = data;
            this.actionSubmitted(res);
          });
          break;
        case 3:
          this.callService(this.crawlerService.submitRecrawlRequest(request), data => {
            let res: BaseResponse = data;
            this.actionSubmitted(res);
          });
          break;
      }

    }
    else {
      console.log('No crawl action supplied');
    }
  }
  actionSubmitted(res: BaseResponse){
    if (res.isSuccess) {
      this.toggleToastMessage(res.errorMsg)
      this.crawlActionId = null;
      this.crawlActionsModalVisible = false;
      this.reloadCurrentRoute();
      // this.router.navigate(['/results/' + request.crawlRequestId], { queryParams: { 'refresh': 1 } })
    }
    else {
      console.log('crawl action error', res)
    }
  }

  startEdit(){
    this.editing = true;
    return true;
  }
  stopEdit(){
    this.editing = false;
    this.editingRequest = {...this.request};
    return true;
  }
  saveEdit(){
    this.saving = true;
    this.crawlerService.updateCrawlRequest(this.editingRequest).pipe().subscribe(data=>{
      this.snackBar.open('Saved', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      this.saving = false;
      this.editing = false;
    })
  }
}
