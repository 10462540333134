import { Directive, OnInit, ElementRef, Renderer } from '@angular/core';
import { AuthenticatedStorage } from '@app/_helpers/localStorage';
@Directive({
  selector: '[unAuthenticated]'
})
export class unAuthenticatedDirective {
  private unAuthenticated: boolean = true;
  private targetEl: any;

  constructor(private _elemRef: ElementRef, private authenticated: AuthenticatedStorage) {
    this.targetEl = _elemRef.nativeElement;
    let userData = this.authenticated.userData();
    if (userData) {
      this.unAuthenticated = !(userData.isSuccess);
    }
    else{
      this. unAuthenticated = true;
    }
    
    if (!this.unAuthenticated) {
      this.targetEl.parentNode.removeChild(this.targetEl);
    }
  }
}