import { CrawlerType } from "../_enums/crawlerTypes";
import { CrawlerProperties } from "../_models/common/crawlerProperties";

export const RequestAddtionalColumnSuffix = '_AdditionalColumn';
// export const RequestColumnDisplaySuffix = ' (Request)';

export const AllCrawlerProperties: CrawlerProperties[] = [
    {
        crawlerTypeId: CrawlerType.BusinessData,
        recordGroupLimit: null,
        groupCountMinimum: null,
        unitName: null,
        selectColumns: [{ id: 1, name: 'Business name', required: true }, { id: 2, name: 'State', required: true }]
    },
    {
        crawlerTypeId: CrawlerType.LocationQuery,
        recordGroupLimit: 100,
        groupCountMinimum: null,
        unitName: 'businesses-per-query',
        selectColumns: null
    },
    {
        crawlerTypeId: CrawlerType.ExhibitorData,
        recordGroupLimit: null,
        unitName: null,
        selectColumns: null
    },
    {
        crawlerTypeId: CrawlerType.EmployeeData,
        recordGroupLimit: 30,
        groupCountMinimum: null,
        unitName: 'employees-per-business',
        selectColumns: [{ id: 1, name: 'Business name', required: true }, { id: 2, name: 'Titles', required: false }, { id: 3, name: 'Employee Size', required: false }]
    },
    {
        crawlerTypeId: CrawlerType.CommodityData,
        recordGroupLimit: null,
        groupCountMinimum: null,
        unitName: null,
        selectColumns: null
    },
    {
        crawlerTypeId: CrawlerType.PropertyListings,
        recordGroupLimit: null,
        groupCountMinimum: null,
        unitName: null,
        selectColumns: [{ id: 1, name: 'QueryURL', required: true }]
    },
    {
        crawlerTypeId: CrawlerType.MarketplaceData,
        recordGroupLimit: null,
        groupCountMinimum: null,
        unitName: null,
        selectColumns: [{ id: 1, name: 'Search', required: true }, { id: 2, name: 'Latitude', required: true }, { id: 3, name: 'Longitude', required: true }, { id: 4, name: 'PriceMin', required: false }, { id: 5, name: 'PriceMax',  required: false }]
    },
    {
        crawlerTypeId: CrawlerType.PageScraper,
        recordGroupLimit: null,
        groupCountMinimum: null,
        unitName: null,
        selectColumns: [{ id: 1, name: 'PageURL', required: true }]
    },
    {
        crawlerTypeId: CrawlerType.ContactData,
        recordGroupLimit: 10,
        groupCountMinimum: null,
        unitName: 'contacts-per-business',
        selectColumns: [{ id: 1, name: 'Company Names', required: false }, { id: 2, name: 'Domains', required: false }, { id: 3, name: 'Job Titles', required: false }, { id: 4, name: 'Departments', required: false }]
    },
    {
        crawlerTypeId: CrawlerType.Emailer,
        recordGroupLimit: null,
        groupCountMinimum: null,
        unitName: 'emailer',
        selectColumns: [{ id: 1, name: 'Email Address', required: true }]
    },
]
export class CrawlerSettings {
    getCrawlerProperties(crawlerTypeId: number): CrawlerProperties {
        return AllCrawlerProperties.find(x => x.crawlerTypeId == crawlerTypeId);
    }
    returnRequestPropNameSuffix(inc: string){
        return RequestAddtionalColumnSuffix + (inc? '_' + inc : '');
    }
}
