import { Component, ViewChild, AfterViewInit, OnInit, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrawlRequest } from '@models/Requests/Results/crawlRequest';
import { BasePage } from '@shared/basePage';
import { AccountRoles } from '@enums/accountRoles';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportingService } from '@services/reporting.service';
import { MatSort, MatPaginator, MatPaginatorModule, MatSnackBar } from '@angular/material';
import { EventEmitter } from '@angular/core';
import { NewList } from '../../_models/Requests/Lists/newList';
import { EditorOutputData } from '../../_models/common/editorData';
import { TableOutputExt } from '../../_models/common/tableData';

@Component({
  selector: 'app-list-details-data',
  templateUrl: './list-details-data.component.html'
})  

export class ListDetailsDataComponent extends BasePage {
  public listId: number;
  public listOutputData: TableOutputExt = new TableOutputExt();
  public editorOutputData: EditorOutputData = new EditorOutputData();
  public editorFirstChange: boolean;
  public editorDiscardWarning: boolean;
  public changesNotSavedMsg = 'Changes to this list have not been saved!';
  public crawlActionsModalVisible: boolean = false;
  
  constructor(public _router: Router, public _reportingService: ReportingService, private activatedRoute: ActivatedRoute, private snackbar:MatSnackBar ) {
    super(AccountRoles.User, null, _router, snackbar)
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.listId = params['id'];
      this.isLoading = true;
      this.isSubmitting = false;
    });
  }
  
  listDataChanged(data){
    this.listOutputData.columns = data.columnSettings;
    this.listOutputData.data = data.gridData;
    this.listOutputData.render = true;
  }
  editorDataChanged(e){
    this.editorOutputData = e;
    if (!this.editorFirstChange) {
      this.editorFirstChange = true;
    }
    else{
      this.listOutputData.isChanged = true;
    }
  }
  editorLoaded(e){
    setTimeout(x=> {
      this.isLoading = false;
    })
  }
  saveChanges(){
    this.isSubmitting = true;
    let newData: NewList = {
      listId: this.listId,
      listName: null, 
      description: null,
      originalFileName: null,
      isDeleted: null,
      isPrivate: null,
      headersData: this.editorOutputData.headers.map(c=> c.name), //TODO: get from class
      listData: this.editorOutputData.data //TODO: get from class
    };
    this.callService(this._reportingService.saveList(newData), x => {
      this.isSubmitting = false;
      if (x.listId > 0) {
        this.editorFirstChange = false;
        this.listOutputData.isChanged = false;
        this.toggleToastMessage('Changes saved!');
      }
      else{
        this.toggleToastMessage('There was a problem saving your changes. Please try again later', null, null, 'alert-warning');
      }
    })
  }
  cancelChanges(){
    this.editorDiscardWarning = true;
    // 1. Are you sure you want to disgard changes?
    //2. #editorTemplate.cancelChanges()
    //3. (Editor will use chached version of data)
  }
  
}
