export class CrawlDetails {
    public crawlRequestId: number;
    public listId: number;
    public dtCreated: string;
    public createdById: number;
    public crawlerName: string;    
    public creatorName: string;
    public crawlerTypeId: number;
    public crawlerType: string;
    public modifiedById: string;
    public dtCompleted: string;
    public dtForcedStart: string;
    public dtForcedEnd: string;
    public dtForcedRecrawl: string;
    public completedCount: number;
    public totalRows: number;
    public failedCount: number;
    public errorCount: number;
    public requestHeaders: string;
    public responseHeaders: string;
    public details: string;
}