import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
// import { TableData } from '@models/common/tableData';
import { CrawlerService  } from '@services/crawlers.service';
import { AccountRoles } from '@enums/accountRoles';
import { Router } from '@angular/router';
import { BasePage } from '@app/shared/basePage';
import { CrawlerType } from '../../_enums/crawlerTypes';
import { MatSnackBar, MatHorizontalStepper } from '@angular/material';
import { ReportingService } from '../../_services/reporting.service';
import { Observable } from 'rxjs';
import { NewList } from '../../_models/Requests/Lists/newList';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { ISearchCrawlerPage } from '../../shared/icrawlerpage';
import { SearchCrawlerPage } from '../../shared/searchServicePage';

@Component({
  selector: 'app-location-data-query',
  templateUrl: './location-data-query.component.html',
  styleUrls: ['./location-data-query.component.css','./../../../assets/css/crawler.common.css'],
  encapsulation: ViewEncapsulation.None
})
export class LocationDataQueryComponent extends SearchCrawlerPage implements ISearchCrawlerPage, OnInit {
  constructor(public _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar, public _reportingService?: ReportingService) {
    super(_crawlerService, router, snackbar, _reportingService);
    this.crawlerType = CrawlerType.LocationQuery;
  }
  ngOnInit() {
  }

  editorHeaderColumnButtonClicked(request) {
    // if (this.checkedRowData.indexOf(request) == -1) {
    //   this.checkedRowData.push(request);
    // }

    // //Clear all request data except  result data
    // this.requestHeaderData = [];
    // this.requestListData = [];
    this.selectedSource.listId = null;

    //Loop through result rows and generate request 
    let totallbls = this.query.nativeElement.querySelectorAll('label.label');

    // //Generate request headers
    // this.requestHeaderData[i.toString()] = request.name,


    //   //Generate request data
    //   this.requestListData[i.toString()] = request.data;

    //Add label to query editor
    let shortClassName = request.name.replace(' ', '');
    let existingLbls = $(this.query.nativeElement).find('label.' + shortClassName);
    if (existingLbls.length == 0) {
      let lbl = document.createElement('label');
      lbl.className = 'label label-info ' + shortClassName;
      lbl.innerHTML = '<i class="fa fa-columns" aria-hidden="true"></i>&nbsp;[' + request.displayName + ']'
      lbl.setAttribute('contenteditable', 'false');

      //If first label add to beggining, else add to end
      if (totallbls.length == 0) {
        this.query.nativeElement.prepend(lbl)
      }
      else {
        this.query.nativeElement.append(' ')
        this.query.nativeElement.append(lbl)
      }
    }

    // //remove tags not present
    // if (totallbls.length > 0) {
    //   totallbls.forEach(el => {
    //     let hasClass = false;
    //     this.requestHeaderData.forEach(header => {
    //       if ($(el).hasClass(header)) {
    //         hasClass = true;
    //       }
    //     })

    //     if (!hasClass) {
    //       $(el).remove();
    //     }
    //   })
    // }


    this.headersSelected = true;
    //If columns match our select list length, presume successful
    // if (this.checkedRowData.length > 0) {
    //   this.headersSelected = true;
    // }
    // else {
    //   this.headersSelected = false;
    // }

  }
 
  
  

}
