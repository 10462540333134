import { Directive, ElementRef, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import * as $ from 'jquery';

@Directive ({
    selector: '[flash]'
  })
  export class FlashDirective  implements OnChanges {
    element: HTMLElement;

    @Input() flash: boolean;
    @Input() preventReflash: boolean = true;
    public changeCount: number = 0;
    constructor(_elementRef: ElementRef) {
      this.element = _elementRef.nativeElement;
    }

    ngOnChanges(changes: SimpleChanges) {
      const flashProp = changes['flash'];
      const allowReflashProp = changes['allowReflash'];
      if (allowReflashProp && (allowReflashProp.currentValue || allowReflashProp.currentValue == false)) {
        this.preventReflash = allowReflashProp.currentValue
      }

      if (flashProp && flashProp.currentValue || flashProp.currentValue == false) {

        //Check if new value has value and is different than previous vaue and check if we are preventing first change
        if (flashProp.currentValue != flashProp.previousValue && (!this.preventReflash || this.changeCount == 0)) {
          this.flash = flashProp.currentValue
          this.changeCount++;
          this.toggleFlash()
        }
      }
  }

    toggleFlash() {
      let className = 'flash'
      
      //Reflash allowed
      if (!this.preventReflash){

        //If flash is false we will remove the class it so it can be retoggled again
        if (this.element.classList.contains('flash')){
          this.element.classList.remove(className)
        }
      }
      
      //If flash is is true we add the class in a new thread to allow class removal if necessary
      if (this.flash) {
        setTimeout(x=> {
          this.element.classList.add(className)
        }, 50)
      }
      
    }

  }