import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule, FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthenticatedDirective } from '../_directives/authenticated.directive';
import { unAuthenticatedDirective } from '../_directives/unAuthenticated.directive';
import { LoadingDirective } from '../_directives/loading.directive';
import { TimeAgoPipe } from 'time-ago-pipe';
import { HotTableModule } from '@handsontable/angular';
import { MatAutocompleteModule,MatBadgeModule,MatBottomSheetModule,MatButtonModule,MatButtonToggleModule,MatCardModule,
  MatCheckboxModule,MatChipsModule,MatDatepickerModule,MatDialogModule,MatExpansionModule,MatGridListModule,MatIconModule,
  MatInputModule,MatListModule,MatMenuModule,MatNativeDateModule,MatPaginatorModule,MatProgressBarModule,MatProgressSpinnerModule,MatRadioModule,MatRippleModule,
  MatSelectModule,MatSidenavModule,MatSliderModule,MatSlideToggleModule,MatSnackBarModule,MatSortModule,MatTableModule, MatTabsModule, MatToolbarModule,
  MatTooltipModule, MatStepperModule } from '@angular/material';
import { TableExportComponent } from '../component/table-export/table-export.component';
import { ProfileImageDirective } from '../_directives/profileImage.directive';
import { InfiniteScrollerDirective } from '../_directives/infinite-scroller.directive';
import { SocketService } from '../_services/socketService';
import { HubConnection } from '@aspnet/signalr'
import { FlashDirective } from '../_directives/flash.directive';
import { FlashChangeDirective } from '../_directives/flashChange.directive';
import { PasswordComponent } from '../profile/password/password.component';
import { TimeZoneOffsetPipe } from '../_pipes/timezoneOffset';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: false,
  suppressScrollX: true               
};


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule
    //HotTableModule, Doesnt work unless its in App.Module
  ],
  exports: [
    RouterModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    AuthenticatedDirective,
    unAuthenticatedDirective,
    ProfileImageDirective,
    LoadingDirective,
    FlashDirective,
    FlashChangeDirective,
    InfiniteScrollerDirective,
    TimeAgoPipe,
    TimeZoneOffsetPipe
  ],
  declarations: [ 
    AuthenticatedDirective,
    unAuthenticatedDirective,
    ProfileImageDirective,
    LoadingDirective,
    FlashDirective,
    FlashChangeDirective,
    InfiniteScrollerDirective,
    TimeAgoPipe,
    TimeZoneOffsetPipe
    //TableEditorComponent DOESNT WORK UNLESS ITS IN App.module
  ],
  entryComponents:[
    
  ],
  providers:[ SocketService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ]
})
export class SharedModule { }
