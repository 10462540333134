import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// import { TableData } from '@models/common/tableData';
import { CrawlerService  } from '@services/crawlers.service';
import { AccountRoles } from '@enums/accountRoles';
import { Router } from '@angular/router';
import { BasePage } from '@app/shared/basePage';
import { CrawlerType } from '../../_enums/crawlerTypes';
import { MatSnackBar, MatHorizontalStepper } from '@angular/material';
import { NewList } from '../../_models/Requests/Lists/newList';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { ReportingService } from '../../_services/reporting.service';
import { ISelectionCrawlerPage } from '../../shared/icrawlerpage';
import { SelectionCrawlerPage } from '../../shared/selectionServicePage';
import { CrawlerSettings } from '../../_helpers/crawlerSettings';

@Component({
  selector: 'app-page-scraper',
  templateUrl: './page-scraper.component.html',
  styleUrls: ['./page-scraper.component.css','./../../../assets/css/crawler.common.css'],
  encapsulation: ViewEncapsulation.None
})
export class PageScraperComponent extends SelectionCrawlerPage implements ISelectionCrawlerPage, OnInit {
  public editColRenderersToSkipCount: number = 0;
  public fileToUpload: File[] = [];
  public addTokens: any[] = [{ //TODO: turn to class
    name: 'click_element',
    class: 'badge badge-pill badge-danger',
    text: 'Click element',
    additionalFields: {
      displayName: false
    }
  },
  {
    name: 'get_linkurl',
    class: 'badge badge-light badge info',
    text: 'Get link URL',
    additionalFields: {
      displayName: true
    }
  },
  {
    name: 'get_innertext',
    class: 'badge badge-light text-info',
    text: 'Get Inner Text',
    additionalFields: {
      displayName: true
    }
  },
  // {
  //   name: 'get_attribute',
  //   class: 'badge badge-light text-warning',
  //   text: 'Get Attribute Value',
  //   additionalFields: {
  //     displayName: true,
  //     attribute: true  //NEED TO ADD SUPPORT FOR THIS
  //   }
  // },
  {
    name: 'delay',
    class: 'badge badge-light text-success',
    text: 'Delay(ms)',
    additionalFields: {
      displayName: false
    }
  }]

  public addActions: any[] = [{ //TODO: turn to class
    name: 'goto_url',
    class: 'badge badge-pill badge-info',
    text: 'Goto Page',
    valuePlaceholder: 'URL',
    additionalFields: {
      displayName: false
    }

  }]
  public logonActionsAdded: any[] = [];
  public tokensAdded: any[] = [];

  constructor(public _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar, public _reportingService?: ReportingService) {
    super(_crawlerService, router, snackbar, _reportingService);
    
    this.crawlerType = CrawlerType.PageScraper;
    this.crawlerSettings = new CrawlerSettings().getCrawlerProperties(this.crawlerType);
  }

  ngOnInit() {
  }
 
  addAction(action: any, addToArray) {
    addToArray.push({ ...action });
    // switch (action) {
    //   case 'click_element':
    //     break;
    //   case 'get_linkurl':
    //     break;
    //   case 'get_innertext':
    //     break;
    //   case 'get_attribute':
    //     break;
    //   case 'delay':
    //     break;
    // }
  }
  removeAction(actionIndex: number){
    this.tokensAdded.splice(actionIndex, 1);
  }
  validateTokenSelectors(): number{
    let errors: number = 0;
    for(let i = 0; i < this.tokensAdded.length; i++){
      if (!this.tokensAdded[i].value){
        errors++;
      }
    }
    return errors;
  }

  validateTokenDisplayNames(): number{
    let errors: number = 0;
    for(let i = 0; i < this.tokensAdded.length; i++){
      let validate = this.addTokens.findIndex(x=> x.additionalFields.displayName && x .name == this.tokensAdded[i].name) > -1;
      if (validate && !this.tokensAdded[i].displayName){
        errors++;
      }
    }
    return errors;
  }
  validateActionSelectors(): number{
    let errors: number = 0;
    for(let i = 0; i < this.logonActionsAdded.length; i++){
      if (!this.logonActionsAdded[i].value){
        errors++;
      }
    }
    return errors;
  }

  validateActionDisplayNames(): number{
    let errors: number = 0;
    for(let i = 0; i < this.logonActionsAdded.length; i++){
      let validate = this.addActions.findIndex(x=> x.additionalFields.displayName && x .name == this.logonActionsAdded[i].name) > -1;
      if (validate && !this.logonActionsAdded[i].displayName){
        errors++;
      }
    }
    return errors;
  }
  
  submitPageScraper(): boolean {
    let tokenSelectorErrors = this.validateTokenSelectors();
    let tokenDisplayNameErrors = this.validateTokenDisplayNames();
    let actionSelectorErrors = this.validateTokenSelectors();
    let actionDisplayNameErrors = this.validateTokenDisplayNames();

    if (tokenSelectorErrors > 0 || actionSelectorErrors > 0) {
      let identifier = (actionSelectorErrors > 0 ? 'action': 'token');

      if (tokenSelectorErrors == this.tokensAdded.length) {
        this.submitErrorMsg = 'All ' + identifier + 's must have selectors entered';
        return false;
      }
      else if (tokenSelectorErrors == 1) {
        this.submitErrorMsg = 'There is 1 ' + identifier + ' missing a selector. Please fill it or remove the ' + identifier ;
        return false;
      }
      else {
        this.submitErrorMsg = 'There are ' + tokenSelectorErrors + ' ' + identifier + 's missing selectors. Please fill each one or remove those ' + identifier + 's';
        return false;
      }
    }
    if (tokenDisplayNameErrors > 0 || actionDisplayNameErrors) {
      let identifier = (actionDisplayNameErrors > 0 ? 'action': 'token');

      if (tokenDisplayNameErrors == this.tokensAdded.filter(x => this.addTokens.findIndex(y => y.name == x.name && y.additionalFields.displayName) > -1).length) {
        this.submitErrorMsg = 'You are missing all of your required ' + identifier + ' display names';
        return false;
      }
      else if (tokenDisplayNameErrors == 1) {
        this.submitErrorMsg = 'There is one ' + identifier + ' missing a display name';
        return false;
      }
      else {
        this.submitErrorMsg = 'There are ' + tokenDisplayNameErrors + ' ' + identifier + 's missing display names. Please fill each one or remove those ' + identifier + 's';
        return false;
      }
    }
    this.submitErrorMsg = '';
    this.customRequestProperties = [];
    this.customRequestProperties.push(
      { name: 'Tokens', values: this.tokensAdded.map(x => { return '{type: "' + x.name + '", name: "' + (x.displayName || '') + '", value: "' + x.value + '" }' }) },
      { name: 'LogonActions', values: this.logonActionsAdded.map(x => { return '{type: "' + x.name + '", name: "' + (x.displayName || '') + '", value: "' + x.value + '" }' }) },
      );
    this.confirmSubmit(true);
    return true;
  }
}
