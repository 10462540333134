import { ColumnSettings } from "../_models/common/editorData";
import { ColumnData } from "../_models/common/commonData";

export class EditorUtilities {
    static fixContextColumnInsert(hotInstance, columns: ColumnSettings[]) {
        if (hotInstance) {
            hotInstance.updateSettings({
                contextMenu: {
                    callback: (key, options) => { },
                    items: {
                        'row_above': {},
                        'row_below': {},
                        "hsep1": "---------",
                        "col-left": {
                            name: "Insert column left",
                            callback: (x => {
                                var getCol = hotInstance.getSelected()[1];
                                var getColSet = columns.length;
                                columns.push({data: columns.length.toString(), displayName: columns.length.toString(), type: 'text', width: 150, dataFormat: null});
                                this.arraymove(columns, getColSet, getCol);
                                hotInstance.updateSettings({
                                    columns: columns
                                })
                            }),
                            //disabled: false
                        },
                        "col-right": {
                            name: "Insert column right",
                            callback: (x => {
                                var getCol = hotInstance.getSelected()[1];
                                var getColSet = columns.length;
                                columns.push({data: columns.length.toString(), displayName: columns.length.toString(), type: 'text', width: 150, dataFormat: null});
                                this.arraymove(columns, getColSet, getCol + 1);
                                hotInstance.updateSettings({
                                    columns: columns
                                })
                            }),
                            //disabled: false
                        },
                        'hsep2': '---------',
                        'remove_row': {},
                        'remove_col': {},
                        'hsep3': '---------',
                        'undo': {},
                        'redo': {},
                        'hsep4': '---------',
                        'make_read_only': {},
                        'hsep5': '---------',
                        'alignment': {},
                        'hsep6': '---------',
                        'copy': {},
                        'cut': {}
                    }
                }
            })
        }
    }

    static arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }
    static trimBlankData(dataset) {

        let maxColToAdd = 0;
        let blankRows = false;
        //Trim excess data  columns if there are blank values (from new tbl component)
        dataset.forEach(r => {
            if (r.length) {
                r.forEach((x, i) => {
                    if (x) {
                        maxColToAdd = i;
                        blankRows = true;
                    }
                });
            }
        })
    }
    static formatPropertyNameStr(value: string): string {
        return value.replace(' ', '').replace(/[^\w\s]/gi, ''); //remove spaces and symbols for prop name
    }
    
    static parseDBJSON(data: any): any {
        return typeof (data) == 'string' ? JSON.parse(data) : data;
    }
    static SortColumnsByIndex(columns: ColumnData[]): ColumnData[] {
        return columns.sort((a, b) => (a.index > b.index) ? 1 : -1)
      }

    static InsertColumnsIntoData(data: any[], refColumns: ColumnSettings[], addColumnCount: number): any[] {
        let newData = JSON.parse(JSON.stringify(data));
        //Create column settings for extra rows
        let columnsToAdd: ColumnSettings[] = [];
        for (let i = 0; i < addColumnCount; i++) {
            let colName = (refColumns.length + i).toString();
            let column: ColumnSettings = {
                data: colName,
                displayName: colName,
                type: 'text',
                width: 150,
                dataFormat: null
            };

            columnsToAdd.push(column);
        };

        //Add properties to each record
        columnsToAdd.forEach(col => {
            newData.forEach(rec => {
                rec[col.data] = '';
            });
            refColumns.push(col);  //shouldnt be by reference, return new column settings object with data? LIKE EDITOR
        })
        
        return newData;
    }
}