import { _CommonOutput, ColumnData } from "./commonData";

export class EditorInputData extends _CommonOutput{
    columns: ColumnSettings[]
    data: any[]
}
export class EditorOutputData{
    headers: ColumnData[]
    data: any[];
}
export class ColumnSettings{
    data: string;
    displayName: string;
    type: string;
    width: number;
    dataFormat: string;   //numericFormat: x.type == 'numeric' { pattern: '0.00'} '0.00%'
}
