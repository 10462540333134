import { HttpClient, HttpParams, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Inject, Injector } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { environment } from '@root/environments/environment';

//import { ErrorManagementService } from './errorManagement.service';

@Injectable()
export class ApiService{
    protected apiUrl: string = environment.apiEndPoint;
    constructor(
        private http: HttpClient,
        private injector: Injector,
        // private errorManagementService: ErrorManagementService
    ) {
    }

    private get router(): Router {
        return this.injector.get(Router);
    }
    genParams(params: {} ){
        let httpParams = new HttpParams();
        Object.keys(params).forEach((element,index) => {
            let paramName = element;
            let paramVal = params[paramName]
            httpParams.append(paramName, paramVal);
            // paramStr+= paramName+ '=' + encodeURIComponent(paramVal);
        });

        return httpParams;
    }
    generateParametersStr(params: {} ){
        let paramStr = '';
        Object.keys(params).forEach((element,index) => {
            let paramName = element;
            let paramVal = params[paramName]
            if (index == 0){
                paramStr+= '?';
            }
            else{
                paramStr+='&';
            }
            paramStr+= paramName+ '=' + encodeURIComponent(paramVal);
        });

        return paramStr;
    }

    // Get(url: string, params?: {}, headers?: HttpHeaders, ): Observable<Response> {
    //     let requestOptions = new RequestHeaders();
    //     let paramStr = this.generateParameters(params);
    //     if (headers != null) {
    //         for (var index = 0; index < headers.keys.length; index++) {

    //             var key = headers.keys[index];
    //             requestOptions.headers.append(key, headers[key]);
    //         }
    //     }
    //     let endPoint = this.getFullUrl(url);

    //     return this.http.get(endPoint + paramStr, requestOptions).pipe(map(
    //         res => {
    //             let data: any = res;
    //             if (data.status < 200 || data.status >= 300) {
    //                 throw new Error('This request has failed ' + data.status);
    //             }
    //             return res;
    //         }
        
    //     ), catchError(this.logError.bind(this)));

    // }

    // Post(url: string, body: {}): Observable<Response> {
    //     let requestOptions = new RequestHeaders();
    //     requestOptions.headers.append('Content-Type', 'application/json');
    //     let bodyToSend = JSON.stringify(body);
    //     let endPoint = this.getFullUrl(url);
    //     return this.http.post(endPoint, bodyToSend, requestOptions).pipe(map(
    //         res => {
    //             let data: any = res;
    //             if (data.status < 200 || data.status >= 300) {
    //                 throw new Error('This request has failed ' + data.status);
    //             }
    //             return res;
    //         }
    //     ), catchError(this.catchError.bind(this)));
    // }

    // PostImage(url: string, body: any): Observable<Response> {
    //     let requestOptions = new RequestHeaders();
    //     //requestOptions.headers.append( 'Content-Type','application/json');
    //     let endPoint = this.getFullUrl(url);
    //     return this.http.post(endPoint, body, requestOptions).pipe(map(
    //         res => {
    //             let data: any = res;
    //             if (data.status < 200 || data.status >= 300) {
    //                 throw new Error('This request has failed ' + data.status);
    //             }
    //             return res;
            
    //     }
    //     ), catchError(this.catchError.bind(this)));
    // }

    // UnauthorizedPost(url: string, body: {}): Observable<Response> {
    //     let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //     let options = new RequestHeaders();
    //     options.headers.append(headers);
    //     let bodyToSend = JSON.stringify(body);
    //     let endPoint = this.getFullUrl(url);
    //     return this.http.post(endPoint, bodyToSend, options).pipe(map(
    //         res => {
    //             let data: any = res;
    //             if (data.status < 200 || data.status >= 300) {
    //                 throw new Error('This request has failed ' + data.status);
    //             }
    //             return res;

    //         }), catchError(this.logError.bind(this)));
    // }

    // logError(e: Response): Observable<Response> {
    //     console.log('Error occured', e);
    //     return Observable.throw('Error Occured');
    // }
    // getFullUrl(url: string) {
    //     return this.apiUrl + '/' + url;
    // }


    // catchError(e: Response): Observable<Response> {
    //     if (e.status == 401) {
    //         this.router.navigate(['login'], { queryParams: { returnUrl: this.router.url } })
    //         return EMPTY;
    //     } else if (e.status == 404) {
    //         this.router.navigate(['pages/item-not-found']);
    //         return EMPTY;
    //     } else {
    //         return null;
    //         // return this.errorManagementService.handleApiError(e);
    //     }
    // }
}

