import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { httpOptions, apiUrl } from './http.service';
import { LocationData } from '../_models/common/google';
import { ApiService } from './api.service';
// import { Question, Option } from './_models/question';
@Injectable({
    providedIn: 'root'
})
export class CrawlerService {
    constructor(private http: HttpClient, private apiService: ApiService) {}

    // parseMapUrl(mapUrl: string) {
    //     let url = apiUrl + '/api/questions';
    //     return this.http.get<any>(apiUrl+  '/api/upload/findShowMapCompanies?mapPageUrl=' + mapUrl, httpOptions )
    // }

    uploadBusinessCrawlList(files: File[], payload: any) {      //TODO: DO WE Need API URL?? Why we using it here and not elsewhere
        let url = apiUrl + '/api/questions';
        let formData: FormData = new FormData();
        files.forEach(file => {
          formData.append(file.name, file, file.name);
        });
        formData.append('uploadBody', JSON.stringify(payload));
    
        return this.http.post<any>(apiUrl + '/api/upload/uploadAndParseList', formData, httpOptions)
    }
    
    // uploadEmployeeBusinessCrawlList(files: File[], payload: any) {
    //     let url = apiUrl + '/api/questions';
    //     let formData: FormData = new FormData();
    //     files.forEach(file => {
    //       formData.append(file.name, file, file.name);
    //     });
    //     formData.append('uploadBody', JSON.stringify(payload));
    
    //     return this.http.post<any>(apiUrl + '/api/upload/uploadEmployeeBusinessCrawlList', formData, httpOptions)
    // }
    scrapeCommodityData(){
        return this.http.post<any>(apiUrl + '/api/upload/scrapeCommodities', {}, httpOptions)
    }
    submitCrawlRequest(crawlerTypeId, requestHeaderData, requestListData, recordGroupLimit, listId, crawlRequestId, searchOrigin: LocationData = null, iMinimumGroupCount: number = null){
        let request = {
            crawlerTypeId: crawlerTypeId,
            requestHeaderData: requestHeaderData,
            requestListData: requestListData,
            recordGroupLimit: recordGroupLimit,
            minimumGroupCount: iMinimumGroupCount,
            listId: listId,
            crawlRequestId: crawlRequestId,
            searchOrigin: searchOrigin? searchOrigin.lat + ',' + searchOrigin.lng : null
        };
        // let formData: FormData = neresponsew FormData();
        // formData.append('request', JSON.stringify(request));
        return this.http.post<any>('/api/services/submitCrawlRequest', request, httpOptions)
    }
    submitEmailRequest(requestHeaderData, requestListData, listId, crawlRequestId){
        let request = {
            requestHeaderData: requestHeaderData,
            requestListData: requestListData,
            listId: listId,
            crawlRequestId: crawlRequestId,
        };
        // let formData: FormData = neresponsew FormData();
        // formData.append('request', JSON.stringify(request));
        return this.http.post<any>('/api/services/submitEmailRequest', request, httpOptions)
    }

    submitForceStartRequest(payload: any){
        return this.http.post<any>('/api/services/submitForceStartRequest' + this.apiService.generateParametersStr(payload), null, httpOptions)
    }
    submitForceStopRequest(payload: any){
        return this.http.post<any>('/api/services/submitForceStopRequest' + this.apiService.generateParametersStr(payload), null, httpOptions)
    }
    submitRecrawlRequest(payload: any){
        return this.http.post<any>('/api/services/submitRecrawlRequest' + this.apiService.generateParametersStr(payload), null, httpOptions)
    }
    updateCrawlRequest(payload: any){
        return this.http.post<any>('/api/reporting/crawlRequest' + this.apiService.generateParametersStr({ crawlRequestId: payload.crawlRequestId, details: payload.details }), null, httpOptions)
    }

}