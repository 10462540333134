import { Component, AfterViewInit } from '@angular/core';
import { BasePage } from '@app/shared/basePage';
import { AccountRoles } from '../_enums/accountRoles';
import { Router } from '@angular/router';
@Component({
  templateUrl: './crawlers.component.html',
  styleUrls: ['./crawlers.component.css']
})
export class CrawlersComponent extends BasePage implements AfterViewInit {
  public crawlerRoutes: any
  constructor(public router: Router) {
    super(AccountRoles.User, null, router)
    this.crawlerRoutes = [{ title: 'Business Data', path: 'businesslist', details: 'Retrieve business data and shipping rates from a list of businesses', matIcon: 'business' },
    { title: 'Location Data Query', path: 'locationquery', details: 'Retrieve business data based on a Google Maps search query', iconClass: "fa fa-search" },
    { title: 'Exhibitors By Map', path: 'exhibitorsbymap', details: 'Retrieves booth and business information from a mapyourshow URL', iconClass: 'fa fa-globe' },
    // { title: 'Employee Search', path: 'employeesearch', details: 'Retrieve business contact information', matIcon: 'contacts' },
    // { title: 'Contact Data Search', path: 'employeesearch', details: 'Retrieve business contact information', matIcon: 'contacts' },
    { title: 'Commodity Data', path: 'commodity-data', details: 'Retrieve commodity categories and shipping classes', iconClass: 'fa fa-truck' }]
  }

  ngAfterViewInit() { }
}
