import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login.component';
import { LoginService } from '../_services/login.service';
import { SharedModule } from '../_common/common.module';

export const routes = [
  { path: 'login', component: LoginComponent, pathMatch: 'full'  }
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
  ],
  exports: [LoginComponent],
  providers: [LoginService],
})
export class LoginModule { }
