import { _CommonOutput, ColumnData } from "./commonData";

export class TableOutput{
    columns: ColumnData [] = [];
    data: any [] = [];
    rowsWithDataCount: number;
}
export class TableOutputExt extends _CommonOutput{ 
    columns: ColumnData [] = [];
    data: any [] = [];
}

export class TableData {
    public headers: TableHeader[];
    public rowData: any[]
}
export  class TableHeader{
    text: string;
    dataField: string;
    minWidth?: string;
    maxWidth?: string
    width?: string;
    dataType?: string = 'text';  
    textAlign?: string = 'left';  
    resizable?: boolean = true;
}
export class RowData{
    rowNum: number;
    data: any[];
}

export class HeaderSelection{
    id: string;
    text:string;
    colIndex: number;
    valid:boolean
    required: boolean;
}
export class HeaderChecked{
    name: string
    displayName: string;
    data: any[];
}
export class HeaderSettings{
    
}
export class ResultDetails{
    data: any[];
    columns: ResultColumn[]
    rowsWithRecordsCount: number;
}
export class ResultColumn{
    class: string;
    displayName: string;
    isAdditional: boolean;
    isRequest: boolean
    isStatic: boolean
    name: string;
    type: string;
    width: number;
}
export class RequestCustomParameter{
    name: string;
    values: string[];
}