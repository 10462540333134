import { Directive, OnInit, ElementRef, Renderer } from '@angular/core';
import { AuthenticatedStorage } from '@app/_helpers/localStorage';
@Directive({
  selector: '[authenticated]'
})
export class AuthenticatedDirective {
  private isAuthenticated: boolean = false;
  private targetEl: any;

  constructor(private _elemRef: ElementRef, private authenticated: AuthenticatedStorage) {
    this.targetEl = _elemRef.nativeElement;
    let userData = this.authenticated.userData();
    if (userData) {

      this.isAuthenticated = (userData.isSuccess);
    }

    if (!this.isAuthenticated) {
      this.targetEl.parentNode.removeChild(this.targetEl);
    }
  }
}