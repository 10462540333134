import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { HeaderSelection } from '../../_models/common/tableData';

@Component({
  selector: 'app-required-list',
  templateUrl: './required-list.component.html',
  styleUrls: ['./required-list.component.css']
})
export class RequiredListComponent implements OnInit {
  @Input() data: HeaderSelection[] = [];
  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    const dataProp = changes['data'];
  
    if (dataProp && dataProp.currentValue) {
      this.data = dataProp.currentValue;
    }
  }
}
