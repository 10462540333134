import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { CrawlerService } from '../_services/crawlers.service';
import { ReportingService } from '../_services/reporting.service';
import { NewList } from '../_models/Requests/Lists/newList';
import { EditorUtilities } from '../_utilities/editorUtilities';
import { CrawlerPage } from './crawlerPage';
import { ISearchCrawlerPage, SubmitData } from './icrawlerpage';
import { CrawlRequest } from '../_models/Requests/Results/crawlRequest';

export class SearchCrawlerPage extends CrawlerPage implements ISearchCrawlerPage {
  @ViewChild('query') public query;
  public crawlerType: number;
  public searchQueryVal = "";

  constructor(public _crawlerService: CrawlerService, public router: Router, public snackbar?: MatSnackBar, public _reportingService?: ReportingService) {
    super(_crawlerService, router, snackbar, _reportingService);
  }
  submit() {
    let newData = this.mapSubmitData();

    //Foreach checke ROW ADD FROM Previous data (OutputData)  RENAME THIS
    this.callService(this._crawlerService.submitCrawlRequest(this.crawlerType, newData.headers, newData.data, this.recordGroupLimit, this.selectedSource.listId, this.selectedSource.crawlRequestId, this.searchOrigin, this.groupCountMinimum), data => {

      if (data.isSuccess && data.crawlRequestId) {
        this.toggleToastMessage('Request submitted! Loading results...')
        //TODO: Fire request that doesnt care about getting it back
        setTimeout(x => {
          this.router.navigate(['/results/' + data.crawlRequestId])
        }, 1000);
      }
    })
  }

  mapSubmitData(): SubmitData {
    let queryColName = 'query';
    let searchQueryTagTxt = this.formatSearchQueryText();
    let insertedQueryTags = this.parseSearchQueryTags(searchQueryTagTxt);
    let newHeaders = [];
    let newData = [];

    let colCount = insertedQueryTags.length;
    let additionalColCount = this.checkedRowData.length;
    let insertedBlankRow = false;

    //Add query columns as first header
    newHeaders.push(queryColName);

    //Add formatted record data
    var existingRequestData = this.editorOutputData.data;// JSON.parse(JSON.stringify(this.editorOutputData.requestData));
    
    //No query tags were inserted so we arent going to use the data incase there are blanks
    if (colCount == 0){
      existingRequestData = [];
    }

    //NO rows exist, so we will treat this as a one off search and insert a blank column
    if (existingRequestData.length == 0){
      let newRec = {};
      this.editorOutputData.headers.forEach(header=> {
        newRec[header.name] = "";
      })
      existingRequestData.push(newRec)
    }

    //Loop through data and format each row 
    existingRequestData.forEach((rec, recIndex) => {
      let newRec = {};
      let blanklColCount = 0;
      let blankAdditonalRowCount = 0;
      //Add query property
      newRec['0'] = searchQueryTagTxt;

      //Replace inserted labels with column data in query text
      insertedQueryTags.forEach(element => {
        let propValue = rec[element.name];
        let displayTag = '[' + element.displayName + ']';
        //propValue = //(rec.name ? rec.name : rec);

        //Check if cell is blank value
        if (!propValue) {
          blanklColCount++;
        }
        newRec['0'] = newRec['0'].replace(displayTag, ' ' + propValue + ' ');
      });

      //Remove double spaces (todo: move to formUtilities.ts)
      newRec['0'] = newRec['0'].replace(/\s{2,}/g,' ')

      //Add addition columns to be included as properties
      this.checkedRowData.forEach((c, checkedIndex) => {
        let dataColIndex = this.editorOutputData.headers.indexOf(this.editorOutputData.headers.find(x => x.name == c.name));
        let newIndex = (checkedIndex + 1);  //Has to come after search col index
        let newVal = rec[dataColIndex] || rec[c.name];  //index or value propname
        newRec[newIndex.toString()] = newVal;

         //Check if cell is blank value
         if (!newVal){
          blankAdditonalRowCount++;
        }
      })

      //Make sure we arent inserting a blank column OR if we inserted a blank placeholder row
      if ((blanklColCount + blankAdditonalRowCount) != (colCount + additionalColCount) || (blanklColCount + blankAdditonalRowCount + colCount + additionalColCount) == 0) {
        newData.push(newRec);
      }
    });

    //Add formatted header data
    this.checkedRowData.forEach((c) => {

      //Add request suffix
      // let newName = EditorUtilities.formatPropertyNameStr(c.displayName);// + this.staticSettings.returnRequestPropNameSuffix('1');

      //Check for duplicate header names
      let newHeaderData = this.formatRequestAdditionalColumnNameStr(newHeaders, c.displayName, c.name);
      newHeaders = newHeaderData;
    })

    return {
      headers: newHeaders,
      data: newData
    }
  }
  resultRowClicked(e: CrawlRequest) {
    //If there is an existing record, clear the row so that we can reload the results data component/fire resultsChanged()
    if (this.selectedSource.crawlRequestId) {
      this.selectedSource.crawlRequestId = null;
      setTimeout(x => {
        if (this.selectResultAsSource(e)) {
          this.setQueryText();
        }
      });
      return true;
    }

    else if (this.selectResultAsSource(e)) {
      this.setQueryText();
      return true;
    }

    return false;
  }

  resultRowDoubleClicked(e: CrawlRequest){
    if (this.resultRowClicked(e)) {
      this.resultRowClicked(e)
      this.setStepIndex(1);
    }
    this.stepper.next();
  }

  listRowClicked(e: NewList) {
    if (this.selectListAsSource(e)) {
      this.setQueryText();
    }
  }
  
  listRowDoubleClicked(e: NewList) {
    this.listRowClicked(e);
    this.setStepIndex(1);
    this.stepper.next()

  }

  setQueryText() {

    //add content of search query text
    setTimeout(x => {
      this.query.nativeElement.innerHTML = this.searchQueryVal;
    }, 250);
  }

  listsAdded(lists){
    if (lists.length == 1) {
      if (this.selectListAsSource(lists[0])){
        this.setQueryText();
      }
    }
    this.lists.refreshGrid();
  }

  formatSearchQueryText() {
    var elem = document.createElement('div');
    elem.innerHTML = this.query.nativeElement.innerHTML;
    return elem.innerText.trim();
  }
  parseSearchQueryTags(formattedText) {
    let currentTag = '';
    let isCurrentTag = false;
    let tagArr = [];
    for (var i = 0; i < formattedText.length; i++) {
      let x = formattedText.charAt(i);
      if (x == '[') {
        isCurrentTag = true;
      }
      else if (x == ']') {
        isCurrentTag = false;
        let propertyName = this.editorOutputData.headers.find(x => x.displayName == currentTag).name;
        tagArr.push({ name: propertyName, displayName: currentTag }); //TODO: turn into class
        currentTag = '';
      }
      else if (isCurrentTag) {
        currentTag += x;
      }
    }
    return tagArr;
  }
}
