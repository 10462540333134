import { Directive, OnInit, ElementRef, Renderer } from '@angular/core';
import { AuthenticatedStorage } from '../_helpers/localStorage';
import { Placeholders } from '../_helpers/placeholders';
@Directive({
  selector: '[profileImage]'
})
export class ProfileImageDirective {
  private isAuthenticated: boolean = false;
  private targetEl: any;

  constructor(private _elemRef: ElementRef, private authenticated: AuthenticatedStorage) {
    this.targetEl = _elemRef.nativeElement;
    this.targetEl.setAttribute('onError',  "this.src='" + Placeholders.ProfileImage + "'");
  }
}