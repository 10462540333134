import { Directive, ElementRef, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import * as $ from 'jquery';

@Directive({
  selector: '[flashChange]'
})
export class FlashChangeDirective implements OnChanges {
  element: HTMLElement;

  @Input() flashChange: any;
  @Input() skipChangeCount: number = 1;
  public changeCount: number = 0;

  constructor(_elementRef: ElementRef) {
    this.element = _elementRef.nativeElement;
  }

  ngOnChanges(changes: SimpleChanges) {
    const flashChangeProp = changes['flashChange'];
    const skipChangeCountProp = changes['skipChangeCount'];

    if (skipChangeCountProp && Number.isInteger(skipChangeCountProp.currentValue)) {
      this.skipChangeCount = skipChangeCountProp.currentValue;
    }
    //Object to be checked for a change
    if (flashChangeProp) {
      this.flashChange = flashChangeProp.currentValue;

      //Object needs to be converted to JSON to compare old value
      if (flashChangeProp.currentValue && typeof (flashChangeProp.currentValue) == 'object' && typeof (flashChangeProp.previousValue) == 'object') {
        if (JSON.stringify(flashChangeProp.currentValue) != JSON.stringify(flashChangeProp.previousValue)) {  //We could also use returnPropertyMatch() - CommonUtil

          //Check if we are skipping changes beforew
          if (this.changeCount >= this.skipChangeCount) {
            this.toggleFlash();
          }
          this.changeCount++;
        }
      }
      
      //Compare old/new value if value is not object
      else if (flashChangeProp.currentValue && flashChangeProp.currentValue != flashChangeProp.previousValue) {
        if (this.changeCount >= this.skipChangeCount) {  //doesnt do the trick, still firing (TOOD: Count number of times change happens thru console.log()
          this.toggleFlash();
        }
        this.changeCount++;
      }
    }
  }

  toggleFlash() {
    let className = 'flash'
    let hasFlashClass = this.element.classList.contains('flash');

    //Remove class so we can toggle it again
    if (hasFlashClass) {
      this.element.classList.remove(className)
    }

    setTimeout(x => {
      this.element.classList.add(className)
    }, 50);
  }
}