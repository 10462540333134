import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of, forkJoin } from 'rxjs';
import { map } from "rxjs/operators";
import * as _ from 'lodash';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthenticatedStorage } from '@app/_helpers/localStorage'
import { BaseResponse } from '@models/Responses/baseResponse';
import { ResultsResponse } from '../_models/Responses/Results/resultsResponse';
import { CrawlDataResponse } from '../_models/Responses/Results/crawlDataResponse';
import { ListsResponse } from '../_models/Responses/Lists/listsResponse';
import { NewList } from '../_models/Requests/Lists/newList';
import { httpOptions } from './http.service';
import { CrawlDetails } from '../_models/Requests/Results/crawlDetails';
import { ListDetails } from '../_models/Requests/Lists/listDetails';
import { CrawlRequest } from '../_models/Requests/Results/crawlRequest';
import { TemplatesResponse } from '../_models/Responses/Templates/templatesResponse';
import { TemplateRecord } from '../_models/Requests/Templates/templateRecord';
import { ToEmailResponse } from '../_models/Responses/Account/toEmailResponse';

@Injectable()
export class ReportingService {

    constructor(private apiService: ApiService, private http: HttpClient, private authenticatedStorage: AuthenticatedStorage) {

    }

    getResults(filters: any = {}) {       //is ResultsFilter (type) c#, needs typescript model
        let url = 'api/reporting/results';
        return this.http.get<ResultsResponse>(url + this.apiService.generateParametersStr(filters));
    }
    GetCrawlData(crawlRequestId: number, crawlDataId: number = null) {       //is ResultsFilter (type) c#, needs typescript model
        let url = 'api/reporting/crawlData';
        return this.http.get<CrawlDataResponse>(url + '?crawlRequestId=' + crawlRequestId + '&crawlDataId=' + crawlDataId);
    }
    getCrawlDetails(crawlRequestId: number) {       //is ResultsFilter (type) c#, needs typescript model
        let url = 'api/reporting/crawlDetails';
        return this.http.get<CrawlDetails>(url + '?crawlRequestId=' + crawlRequestId);
    }
    getCrawlDataDateLastUpdated(crawlRequestId: number) {       //is ResultsFilter (type) c#, needs typescript model
        let url = 'api/reporting/crawlDataLastUpdated';
        return this.http.get<CrawlDetails>(url + '?crawlRequestId=' + crawlRequestId);
    }
    getLists(filters: any = {}){
        let url = 'api/reporting/lists/list';
        return this.http.get<ListsResponse>(url + this.apiService.generateParametersStr(filters));
    }
    getListDetails(listId: number){
        let url = 'api/reporting/lists/details';
        return this.http.get<ListDetails>(url + '?listId=' + listId);
    }
    getTemplates(filters: any = {}) {
        let url = 'api/reporting/templates/list';
        return this.http.get<TemplatesResponse>(url + this.apiService.generateParametersStr(filters)).map(x => {
            x.templates.forEach(t => {
                t.tags = t.tags ? JSON.parse(t.tags.toString()) : t
            })
            return x;
        });
    }  
    saveTemplate(data: any) {
        let request = {
            templateId: data.templateId,
            templateName: data.templateName,
            subject: data.subject,
            tags: data.tags,
            templateData: data.templateData,
            crawlerTypeId: data.crawlerTypeId,
            listId: data.listId,
            isActive: data.isActive
        }
        return this.http.post<any>('/api/reporting/templates/save', request, httpOptions)
    }
    createTemplate(data: any) {
        let request = {
            templateName: data.templateName,
            subject: data.subject,
            tags: data.tags,
            templateData: data.templateData,
            crawlerTypeId: data.crawlerTypeId,
            listId: data.listId,
            isActive: data.isActive
        }
        return this.http.post<any>('/api/reporting/templates/create', request, httpOptions)
    }
    getMailerAccountEmails(isActive: boolean = true){
        let url = 'api/account/emailAddresses';
        return this.http.get<ToEmailResponse>(url + this.apiService.generateParametersStr({ isActive: isActive }));
    }
    addMailerForwardAddress(data: { fromEmailAddress: string, firstName: string, lastName: string, isActive: boolean }){
        return this.http.post<any>('api/account/addForwardAddress', data, httpOptions)
    }
    saveList(data: NewList){
        return this.http.post<any>('/api/reporting/lists/save', data, httpOptions)
    }
    getAllData(crawlRequestId: number): Observable<any[]> {
        return forkJoin([this.getCrawlDetails(crawlRequestId), this.GetCrawlData(crawlRequestId)])
    }
    renameObjectProperty(obj, old_key, new_key) {
        //check if old key exists
        if (typeof (obj[old_key]) != 'undefined') { //(obj[old_key] === null || obj[propName] === undefined) {

            //get first null prop and rename to new_key
            if (obj[old_key] === null) {
                delete obj[old_key];
                obj[new_key] = '';
                return;
            }
            // old key has value so check if old key = new key before setting
            else if (old_key != new_key) {
                Object.defineProperty(obj, new_key, // modify old key 
                    // fetch description from object 
                    Object.getOwnPropertyDescriptor(obj, old_key));
                delete obj[old_key];                // delete old key 
            }
        }

        //object is undefined
        else {
            obj[new_key] = '';
        }


        return obj;
    }

  dtTriggered(row: CrawlRequest): any{
    let dt = new Date(row.dtCreated);
    let dtForcedRecrawl = new Date(row.dtForcedRecrawl);
    let dtForcedStart = new Date(row.dtForcedStart);
    if (dtForcedRecrawl > dt){
      dt = dtForcedRecrawl;
    }
    if (dtForcedStart > dt){
      dt = dtForcedStart;
    }
    return dt;
  }

}