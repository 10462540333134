import { Component, OnInit, AfterViewInit, ViewChild, Output, Input } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { ListRecord } from '../../_models/Requests/Lists/listRecord';
import { BasePage } from '../../shared/basePage';
import { AccountRoles } from '../../_enums/accountRoles';
import { Router } from '@angular/router';
import { ReportingService } from '../../_services/reporting.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';
import { TableNewComponent } from '../../component/table-new/table-new.component';
import * as XLSX from 'xlsx';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormUtilities } from '../../_utilities/formUtilities';
import { NewList, ListData } from '../../_models/Requests/Lists/newList';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { TableUtil } from '../../_utilities/tableUtilities';

@Component({
  selector: 'app-lists-list',
  templateUrl: './lists-list.component.html',
  styleUrls: ['./lists-list.component.css']
})
export class ListsListComponent extends BasePage implements OnInit, AfterViewInit {
  public dataSource: MatTableDataSource<ListRecord>;
  public displayedColumns: string[] = ['listId', 'listName', 'creatorName', 'dtCreated'];
  public newListTabs: any[] = [{ id: 0, title: 'Upload', enabled: true }, { id: 1, title: 'New', enabled: true }];
  public newListSource: number = 0;
  public newListData: ListData;
  public newListStep: number = 0;
  public uploadLists: Array<UploadList>;
  public newListsValid: boolean = false;
  public savedLists: NewList[] = [];
  public focusSavedLists: boolean = false;
  public selectedRow: ListRecord;
  public newTblEditorLoaded: boolean = false;
  public allowedExtensions: string[] = ['xls', 'xlsx', 'csv'];
  
  public newListModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false
  };
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('new') new: any;
  @ViewChild('tbl') tblEl: any;
  @Input() preventRouting: boolean = false;
  @Input() allowInsertListColumns: boolean = false;
  @Output() onRowClicked = new EventEmitter<ListRecord>();
  @Output() onRowDblClicked = new EventEmitter<ListRecord>();
  @Output() onListsAdded = new EventEmitter<NewList[]>();
  constructor(public _router: Router, private modalService: NgbModal, private reportingService: ReportingService, public snackbar: MatSnackBar) {
    super(AccountRoles.User, null, _router, snackbar);
  }

  ngOnInit() {
    //Move default selected to top
    // this.newListTabs.sort((a,b)=> (a.id == this.selectedRow)? 1 : -1);
  }
  ngAfterViewInit() {
    this.refreshGrid();
  }
  changeSourceTab(source) {
    this.newListSource = (source.enabled ? source.id : this.newListSource)
    //Clear new list loading
    if (this.newListSource != 1){
      this.newTblEditorLoaded = false;
    }
  }

  refreshGrid(focusListId: number = null) {
    this.populateGrid(focusListId);
  }
  newTblEditorOnLoad(e) {
    if (e) {
      setTimeout(x => {
        this.newTblEditorLoaded = true
      })
    }
  }
  listDetailsFormChanged(form: FormGroup, usingUploadList: UploadList = null) {
    //Uploading files
    var existingForm;
    if (usingUploadList) {
      existingForm = this.uploadLists.find(x => x == usingUploadList);
    }

    //Creating new list
    else {
      this.uploadLists = [];
      
      let newData: NewList = this.returnListFromFormData(form, null, null, null);
      
      // let newList: UploadList = { form: this.returnFormData(newData, true), file: null };
      let newList: UploadList = this.setListFormData(null, newData, null, true, false);
      
      this.uploadLists.push(newList);
      existingForm = this.uploadLists[0];
    }

    //set default values for form
    this.setListFormData(existingForm,
      this.returnListFromFormData(form, null, null, (existingForm.file && existingForm.file.name ? existingForm.file.name.value : null)),
      existingForm.file,
      (form.valid),
      true
    );

    //Subscribe to value changes
    form.valueChanges.subscribe(x => {
      let formIsValid = (existingForm.form != null ? existingForm.form.isValid : false);
      this.setListFormData(existingForm, x, existingForm.file, formIsValid, false);  //We dont set 'isValid' value here, instead we just keep it as is so it get set below in validaiton status changes
    })

    //Subscribe to validation status changes
    form.statusChanges.subscribe(x => {
      let formIsValid = (existingForm.form != null ? existingForm.form.data : null);
      this.setListFormData(existingForm, formIsValid, existingForm.file, FormUtilities.isValid(x), true);
    })

    // else {
    //   form.valueChanges.subscribe(x=> { debugger;
    //     this.uploadLists[0].form.data = x;
    //   })
    //   form.statusChanges.subscribe(x=> { debugger;
    //     this.uploadLists[0].form.isValid = FormUtilities.isValid(x);
    //   })
    // }
  }

  returnListFromFormData(form: FormGroup, headersData, listData, originalFileName): NewList {
    return {
      listId: form.controls.listId.value,
      listName: form.controls.listName.value,
      description: form.controls.description.value,
      isPrivate: form.controls.isPrivate.value,
      isDeleted: form.controls.isDeleted.value,
      headersData: headersData,
      listData: listData,
      originalFileName: originalFileName
    }
  }
  setListFormData(existingForm: UploadList, formData: any, formUploadfile: File, formIsValid: boolean, validateLists: boolean){
    if (!existingForm){
      existingForm = new UploadList();
    }
    existingForm.form = this.returnFormData(formData, formIsValid)
    existingForm.file = formUploadfile;
    
    if (validateLists) {
      this.newListsValid = this.newListFormsValid();
    }

    return existingForm;
  }
  returnFormData(formData: any, formIsValid: boolean): UploadForm {
    return {
      data: formData,
      isValid: formIsValid,
    };
  }
  newListFormsValid() {
    return (this.uploadLists.filter(x => x.form != null && x.form.isValid).length == this.uploadLists.length)
  }
  saveLists() {
    this.isSubmitting = true;
    let requestHeaders = [];
    let requestData = [];
    let newUploadLists: NewList[] = [];
    this.selectedRow = null;
    //Format new list data for each source
    //Upload:
    if (this.newListSource == 0) {
      this.uploadLists.forEach(list => {
        let newList: NewList = {
          listId: list.form.data.listId,
          listName: list.form.data.listName,
          description: list.form.data.description,
          originalFileName: list.file.name,
          isPrivate: list.form.data.isPrivate,
          isDeleted: list.form.data.isDeleted,
          headersData: null,
          listData: null
        };

        let fileReader = new FileReader();
        fileReader.onload = e => {
          let data = (<FileReader>e.target).result
          let workbook = XLSX.read(data, {
            type: "binary"
          })

          //Filter out any blank sheets
          let sheetsWithRows = workbook.SheetNames.filter(sheet =>
            XLSX.utils.sheet_to_json(workbook.Sheets[sheet]).length > 0
          );
          //Treat each sheet as a seperate list
          sheetsWithRows.forEach(sheet => {
            let workSheet = workbook.Sheets[sheet];
            let rowData = XLSX.utils.sheet_to_json(workSheet); //headers of sheeet or first row? we need first row check
            let listName = list.form.data.listName;
            let headersData = TableUtil.returnHeaderNamesFromArray(rowData);
            
            newList.headersData = []
            newList.listData = [];

            //If more than one sheet, we will add sheet name to each list
            if (sheetsWithRows.length > 1) {
              listName += ' - ' + sheet;
            }

            //Get Unique list of headers used in any of the objects in the array
            headersData.forEach((header, i)=> {

              //Make sure columns arent numeric, since it would create duplicates upon renaming them when we render the list data              
              let numericColHeader = parseInt(header);

              //Make sure number exists as column within header length, otherwise it wont be confused with header indexes
              if (numericColHeader && numericColHeader - 1 < headersData.length) {
                  
                  //If number is integer rename with alpha-numeric letter
                  let newColName = TableUtil.returnAlphabetLetterFromWholeNumber(numericColHeader);
                  rowData.map(obj => this.reportingService.renameObjectProperty(obj, header, newColName)); 
                  headersData[i] = newColName;
                }
            });

            //For some reason blank columns arent showing up as properties so we will ensure that it happens
            rowData.forEach(row => { TableUtil.addMissingPropertyNames(row, headersData) });
            //rowData.forEach(row=> { newList.listData.push(TableUtil.mapObjectToIndexProperties(row) ) }); //We dont want to use numbers anymorez
            
            newList.listData = rowData;
            newList.listName = listName;
            newList.headersData = headersData;

            newUploadLists.push(newList)
            
          })

          //Save each new list
          newUploadLists.forEach((newData, i) => {
            //if last list, emit data and close
            this.saveListData(newData, false, (i == newUploadLists.length - 1 ? true : false));
          });
        }

        //Read file and trigger save
        fileReader.readAsBinaryString(list.file);
      })
    }

    //New:
    else if (this.newListSource == 1) {
      // let queryColName = 'query';
      let newHeaders = [];
      let newData = [];
      let maxColIndexWithValue = 0;

      //Insert blank row if no records exist
      if (this.newListData.listData.length == 0){
        let newRec = {};
        this.newListData.headersData.forEach((header,i)=> {
          newRec[i.toString()] = "";
        })
        this.newListData.listData.push(newRec);
      }
      
      //Convert from array of strings to object
      this.newListData.listData.forEach((rec, recIndex) => {
        let newRec = {};
        let rowBlankCellCount = 0;
        this.newListData.headersData.forEach((header, i) => {
          let newValue = rec[i.toString()];
          let isFirstRow = (recIndex == 0); //We will allow the first row to be blank
          let rowHasAtleastOneValue = (rowBlankCellCount < i);  //If first cell of the 2+ row is blank, any cell index afterword would have an index = rowBlankCellCount and wouldnt be created

          //Make sure we are atleast adding one row and checking if there is a cell value before trimming blank rows
          if (newValue || isFirstRow || rowHasAtleastOneValue) {
            let newHeader = header
            newRec[newHeader] = newValue;

            if (newRec[newHeader] && i > maxColIndexWithValue) {
              maxColIndexWithValue = i;
            }
          }
          else {
            rowBlankCellCount++;
          }
        })
        //Blank cells count must not match header count (blank row)
        if (rowBlankCellCount != this.newListData.headersData.length) {
          newData.push(newRec);
        }
      })

      //Parse through headers and remove any related to blank rows
      this.newListData.headersData.forEach((header, i) => {
        // if (i <= maxColIndexWithValue) {
          newHeaders.push(header);
        // }
      })

      newUploadLists.push({
        listId: this.uploadLists[0].form.data.listId,
        listName: this.uploadLists[0].form.data.listName,
        description: this.uploadLists[0].form.data.description,
        headersData: newHeaders,
        listData: newData,
        isDeleted: this.uploadLists[0].form.data.isDeleted,
        isPrivate: this.uploadLists[0].form.data.isPrivate
      })

      //Save and emit new list
      this.saveListData(newUploadLists[0], true, true)

    }

  }
  saveListData(newData: NewList, focusOnListId: boolean, lastList: boolean) {
    this.callService(this.reportingService.saveList(newData), x => {
      if (x.listId > 0) {
        newData.listId = x.listId;
        this.savedLists.push(newData);
      }
      if (lastList) {  //TODO: Refactor with requestDataFromMultipleSources() above 
        this.onListsAdded.emit(this.savedLists);
        // this.onListsAdded.emit({listId: 0, requestHeaders: requestHeaders, requestData: requestData}); //TODO: refactor turn to class
        this.toggleToastMessage(this.savedLists.length + ' new list' + (this.savedLists.length > 1 ? 's' : '') + ' has been created!');
        this.close(true);
        this.focusSavedLists = true;
        let focusListId = (focusOnListId? x.listId : null);
        this.refreshGrid(focusListId);
        
        this.uploadLists = [];
        
      }
    })
  }
  rowIsSelected(row: ListRecord) {
    let rowIsSelected = (this.selectedRow && this.selectedRow.listId == row.listId)
    let rowWasCreated = (this.focusSavedLists && this.savedLists.map(x => x.listId).indexOf(row.listId) > -1)
    if (rowIsSelected || rowWasCreated) {
      return true;
    }
    return false;
  }
  // public requestDataFromMultipleSources(): Observable<any[]> {
  //   let response1 = this.http.get(requestUrl1);
  //   let response2 = this.http.get(requestUrl2);
  //   let response3 = this.http.get(requestUrl3);
  //   // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
  //   return forkJoin([response1, response2, response3])
  // }
  close(completed: boolean = false) {
    this.newListData = null;
    // this.savedLists = [];
    this.resetNewListForm();
    this.modalService.dismissAll((completed ? 'completed' : 'canceled'));
  }
  resetNewListForm() {
    // this.newListForm.reset();
    this.isSubmitting = false;
    //this.fileList = [];
    this.newListStep = 0;
    this.newListSource = 0;
  }
  openNewList(content) {
    this.modalService.open(content, this.newListModalOptions);
  }

  newListDataChanged(data) {
    this.newListData = { listId: null, headersData: data.headers, listData: data.data }; //TODO: Turn to class
  }

  rowClicked(row:ListRecord) {
    this.selectedRow = row;
    this.onRowClicked.emit(row);
    this.selectedRow = row;

    if (!this.preventRouting) {
      this.routeList(row.listId);
    }
  }
  rowDoubleClicked(row: ListRecord){
    this.onRowDblClicked.emit(row);
  }
  onFilesChange(fileList: Array<File>) {
    this.uploadLists = [];
    fileList.forEach(file => {
      this.uploadLists.push({
        form: null,
        file: file,
        details: {
          listName: (fileList.length == 1)? FormUtilities.removeFileNameExtension(file.name) : null
        }
      })
    })
  }
  nextStep() {
    //Final step, save editor if source = new
    if (this.newListStep == 0 && this.newListSource == 1) {
      if (this.new) {
        this.new.emitChanges();
      }
      else{
        console.log('new list emit attempted at next, failed');
      }
    }
    this.newListStep++;
  }
  routeList(listId) {
    this._router.navigate(['/lists/' + listId])
  }
  populateGrid(focusListId: number = null) {
    setTimeout(x => {
      this.isLoading = true;
      this.callService(
        this.reportingService.getLists(),
        res => {
          if (!this.sort.active) {
            this.sort.sort({ id: 'listId', start: 'desc', disableClear: false });
          }
          this.dataSource = new MatTableDataSource(res.lists);

          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          //Select row from new data
          if (focusListId){
            this.rowClicked(this.dataSource.data.find(x=> x.listId == focusListId))
          }
          this.isLoading = false;
        });
    })

  }
}
export class UploadList {
  public form: UploadForm = { data: null, isValid: false };
  public file: File;
  public details?: any = null;
}
export class UploadForm {
  data: any;
  isValid: boolean;
}