export enum CrawlerType{
    BusinessData = 1,
    LocationQuery = 2,
    ExhibitorData = 3,
    EmployeeData = 4,
    CommodityData = 5,
    PropertyListings = 6,
    MarketplaceData = 7,
    PageScraper = 8,
    ContactData = 9,
    Emailer = 10
}