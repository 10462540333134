import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CrawlerSettings } from '../../_helpers/crawlerSettings';
import { CrawlerProperties } from '../../_models/common/crawlerProperties';

@Component({
  selector: 'app-limit-group-by-count',
  templateUrl: './limit-group-by-count.component.html',
  styleUrls: ['./limit-group-by-count.component.css']
})
export class LimitGroupByCountComponent implements OnInit {
  @Input() crawlerTypeId: number;
  public crawlerProperties: CrawlerProperties;
  public inputVal: number;
  @Output() valueChanged = new EventEmitter();
  constructor(public crawlerSettings: CrawlerSettings) {
  }

  ngOnInit() {
    this.initializeCrawlerProperties();
  }
  initializeCrawlerProperties(){
    this.crawlerProperties = this.crawlerSettings.getCrawlerProperties(this.crawlerTypeId)
    this.inputVal = this.crawlerProperties.recordGroupLimit;
  }
  ngAfterViewInit() {
    this.emitChanges();
  }
  
  inputChange(e) {
    if (e) {
      if (this.inputVal != null) {
        if ((this.inputVal < 1 || this.inputVal < this.crawlerProperties.recordGroupLimit) && this.inputVal.toString() != '-') {
          this.inputVal = this.crawlerProperties.recordGroupLimit;
        }
      }
      this.emitChanges();
    }
  }

  emitChanges() {
    this.valueChanged.emit(this.inputVal);
  }
}
