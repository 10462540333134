import { Component, ViewChild, AfterViewInit, OnInit, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CrawlRequest } from '../../_models/Requests/Results/crawlRequest';
import { AccountRoles } from '@enums/accountRoles';
import { Router } from '@angular/router';
import { ReportingService } from '@services/reporting.service';
import { MatSort, MatPaginator, MatPaginatorModule } from '@angular/material';
import { EventEmitter } from '@angular/core';
import { CrawlStatus } from '../../_enums/crawlStatus';
import { BasePage } from '../../shared/basePage';
import { Xliff } from '@angular/compiler';

@Component({
  selector: 'app-results-list',
  templateUrl: './results-list.component.html'
})
export class ResultsListomponent extends BasePage implements OnInit, AfterViewInit {
  public dataSource: MatTableDataSource<CrawlRequest>;
  public displayedColumns: string[] = ['crawlRequestId', 'crawlerType', 'dtTriggered', 'createdBy', 'dtCompleted', 'details'];
  public selectedRow: any = {};
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() preventRouting: boolean = false;

  @Output() onRowClicked = new EventEmitter<CrawlRequest>();
  @Output() onRowDblClicked = new EventEmitter<CrawlRequest>();

  constructor(public _router: Router, public _reportingService: ReportingService) {
    super(AccountRoles.User, null, _router);
    this.isLoading = true;
  } 

  populateGrid(firstLoad: boolean = false) {
    this.isLoading = !firstLoad;
    this.callService(this._reportingService.getResults(), data => {
      this.isLoading = false;
      
      if (!this.sort.active) {
        this.sort.sort({ id: 'dtTriggered', start: 'desc', disableClear: false });
      }

      this.dataSource = new MatTableDataSource(data.results.map(x => { return { ...x, dtTriggered: this._reportingService.dtTriggered(x) } }));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    });
  }
  ngOnInit() {

  }
  rowClicked(row:CrawlRequest){
    this.selectedRow = row;
    this.onRowClicked.emit(row)

    if (!this.preventRouting){
      this.routeCrawlDetails(row.crawlRequestId);
    }
  }
  rowDoubleClicked(row: CrawlRequest){
    this.onRowDblClicked.emit(row);
  }
  requestHasError(crawlStatusId: number): boolean{
    return false; //(crawlStatusId & CrawlStatus.HasErrors)
  }
  rowIsSelected(row: CrawlRequest): boolean{
    let rowIsSelected = (this.selectedRow && this.selectedRow.crawlRequestId == row.crawlRequestId)
    if (rowIsSelected){
      return true;
    }
    return false;
  }

  ngAfterViewInit() {
    this.populateGrid();
  }
  routeCrawlDetails(crawlRequestId){
    this._router.navigate(['/results/' + crawlRequestId])
  }
}
