import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';

import { AccountRoles } from '../../_enums/accountRoles';
import { RoleGroups } from '../../_enums/roleGroups';
import { UserAccount } from '../../_models/Responses/Account/userAccountsResponse';
import { MatSnackBar } from '@angular/material';
import { ComponentBase } from '../../_base/component.base';
import { AccountService } from '../../_services/account.service';
import { AuthenticatedStorage } from '../../_helpers/localStorage';
import { LoginResponse } from '../../_models/Responses/Account/loginResponse';
import { BasePage } from '../../shared/basePage';
import { LoginService } from '../../_services/login.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent extends BasePage implements OnInit {
  public roleGroups = []
  public vesselStatuses = [];
  public submitForm: FormGroup;
  public roleGroupName: string = 'none'
  public errorMsg: string = '';
  public buildAccountRoles = [];

  @Output() onFormClose = new EventEmitter();
  // this.accountRoles = this.adminService.getAccountRoles();
  // this.roleGroups = this.listingService.getRoleGroups();
  constructor(public fb: FormBuilder,
    public router:Router,
    public snackBar: MatSnackBar,
    private loginService: LoginService,
    public authenticatedStorage: AuthenticatedStorage) {
    super(AccountRoles.User, false, router, snackBar);
    this.buildAccountRoles = this.loginService.GetAccountRoles();
    this.initializeForm();

      if (this.userData.accountId) {
        this.callService(this.loginService.getProfileDetails(), data => {
          let roles = this.buildRoles(data.accountRoleId);
          this.roleGroupName = this.returnFormRoleGroups(roles['controls'])
          this.submitForm = this.fb.group(this.returnFormControls(data.firstName, data.lastName, data.emailAddress, data.isActive, roles))
        })
      }
  }
  ngOnInit() {
  }

  initializeForm() {
    this.submitForm = this.fb.group(this.returnFormControls())
  }

  returnFormControls(firstName = null, lastName = null, emailAddress = null, isActive = true, roles = this.buildRoles()) {
    return {
      firstName: [firstName, Validators.required],
      lastName: [lastName, Validators.required],
      emailAddress: [emailAddress, Validators.email],
      // password: [null, !this.userData.accountId?  Validators.required : null],
      // isActive: [isActive, Validators.required],
      // forcePasswordReset: [true, Validators.required],
      roles: roles
    }
  }
  forcePasswordChange(e) {
    if (e.checked) {
      this.submitForm.controls.password.clearValidators();
    }
    else {
      this.submitForm.controls.password.setValidators(Validators.required)
    }
  }
  submitDisabled() {
    return !this.submitForm.valid;
  }
  buildRoles(accountRoleId: number = null) {
    const arr = this.buildAccountRoles.map(role => {
      return this.fb.group({
        id: role.id,
        name: role.name, //remove isSelected?
        roleGroupId: role.roleGroupId,
        isSelected:((accountRoleId & parseInt(role.id)) == parseInt(role.id))
      })
    })
    
    return this.fb.array(arr);
  }
  cancel() {

    this.onFormClose.emit(true);
    this.submitForm.reset();
    if (this.userData.accountId) {
      this.router.navigate(['/admin/accounts']);
    }
  }
  save() {
    let accountRoleId = 0;
    this.submitForm['controls'].roles['controls'].filter(x => {
      if (x.value.isSelected) {
        accountRoleId += x.value.id
      }
    });
    // let details = {
    //   accountId: this.userData.accountId,
    //   emailAddress: this.submitForm['controls'].emailAddress.value,
    //   firstName: this.submitForm['controls'].firstName.value,
    //   lastName: this.submitForm['controls'].lastName.value,
    //   password: this.submitForm['controls'].password.value,
    //   accountRoleId: accountRoleId,
    //   forcePasswordReset: this.submitForm['controls'].forcePasswordReset.value,
    //   isActive: this.submitForm['controls'].isActive.value
    // }
    // this.errorMsg = '';
    // this.isLoading = true;
    // this.callService(this.accountService.setAccountDetails(details), data => {
    //   this.isLoading = false;
    //   if (data.isSuccess) {
    //     this.errorMsg = 'Success';
    //           this.snackBar.open('Profile changes saved!', '×', {
    //             verticalPosition: 'top',
    //             duration: 5000,
    //           });  
    //     this.router.navigate(['/admin/accounts/' + data.returnCode])
    //   }
    //   else {
    //     this.errorMsg = data.errorMsg;
    //   }
    // });
  }
  returnFormRoleGroups(roles) {
    let groups = [];
    roles.filter(x => {
      let groupName = RoleGroups[x.value.roleGroupId];
      if (groupName && x.value.isSelected && groups.indexOf(groupName) == -1) {
        groups.push(groupName);
      }
    })
    return groups.join(', ')
  }

}
