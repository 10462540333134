import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';
import Handsontable from 'handsontable';
import { HotTableComponent } from '@handsontable/angular';
import { Observable } from 'rxjs';
import { KeyCodes } from '../../_helpers/keyCodes';
import { EditorUtilities } from '../../_utilities/editorUtilities';
import { TableUtil } from '../../_utilities/tableUtilities';

@Component({
  selector: 'app-table-new',
  templateUrl: './table-new.component.html',
  styleUrls: ['./table-new.component.css']
})
export class TableNewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() columns: any[] = [];
  @Input() initialRowCount: number = 5
  @Input() initialColumnCount: number = 5
  @Input() allowInsertColumn: boolean = true;
  @Output() loaded = new EventEmitter();
  @Output() dataChanged = new EventEmitter();

  @ViewChild('hot') hotEl: any;
  public hot: any;
  public isInitialized: boolean = false;
  public settings: any = {};
  public data: any[];
  public colNames:any = [];
  
  constructor() {
  }
  ngAfterViewInit() {
    if (!(this.isInitialized && this.columns && this.columns.length > 0)){
      for(var i = 0; i < this.initialColumnCount; i++){
        let char = TableUtil.returnAlphabetLetterFromIndex(i);
        this.columns.push({ data: char, displayName: char, type: 'text', width: 100, dataFormat: null }) //TODO: Make Class
      }
      this.isInitialized = this.initializeTable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const columnsProp = changes['columns'];

    if (columnsProp && columnsProp.currentValue) {
      this.columns = columnsProp.currentValue.map(x => { return { data: x.name, displayName: x.displayName, width: 100 } }) //TODO: Make Class
      if (!this.isInitialized && this.columns) {
        this.isInitialized = this.initializeTable();
      }
    }
  }

  public emitChanges() {
    if (this.hot) {
      let data = this.hot.getData();
      //Get max columns
      // let requestHeaders = this.hot.getColHeader();
      this.dataChanged.emit({ headers: this.colNames, data: data });    //TODO: TURN to class
    }
    else{
      this.dataChanged.emit({ headers: this.colNames, data: [] });    //TODO: TURN to class
    }
  }
  initializeTable() {
    const _self = this;
    let headers = this.columns.map(x => x.displayName);
    
    //initialize column names
    this.colNames = headers;
    
    //Add new blank rows of data
    this.data = [];
    
    for (let i = 0; i < this.initialRowCount; i++) {
      let row = {};
      this.columns.forEach(c => {
        row[c.data] = ''
      });
      this.data.push(row);
    }

    this.settings = {
      afterInit: x => {
        this.loaded.emit(true);
        this.emitChanges();
        setTimeout(x=> {
          $('.nav-link.btn.btn-primary.active')[0].click();
        },200)
      },
      scrollCallback: x=>{
      },
      afterChange: x=>{
        this.emitChanges();
      },
      afterRemoveRow: x=>{
        this.emitChanges();
      },
      afterPaste: x=>{
        this.emitChanges();
      },
      licenseKey:'non-commercial-and-evaluation',
      data: this.data,
      // Handsontable.helper.createSpreadsheetData(this.initialRowCount, this.columns.length),
      colHeaders: colIndex=>{
          return this.inputHeaderRenderer(_self, colIndex );
      },  
      // colHeaders: headers,
      rowHeaders: true,
      columns: this.columns,  
      columnSorting: true,
      outsideClickDeselects: false,
      search: true,
      fixedRowsTop: 1,
      allowInsertRow: true,
      allowRemoveColumn: this.allowInsertColumn,
      allowInsertColumn: this.allowInsertColumn, //Doesnt work so we use fixContextColumnInsert
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      filters: true,
      renderAllRows: true,
      dropdownMenu: true,
      width: '70%',
      minSpareRows: 1,
    };
    // this.hot = this.hotEl.hotInstance;
    this.hot = new Handsontable(this.hotEl.nativeElement, this.settings
    );
    if (this.allowInsertColumn) {
      EditorUtilities.fixContextColumnInsert(this.hot, this.columns);
    }
    setTimeout(x => {
      //For some reason this class is causing issues
      $('.ht_master').addClass('ht_master2');
      $('.ht_master').removeClass('ht_master');
      this.selectAndEditCell(0,1);

    }, 100)
    return true;
  }
 
  keysPressed(isKeyDown:boolean = false) {
    setTimeout(x => {
      if (this.isInitialized) {
        let fields = this.hotEl.nativeElement.querySelectorAll('.inp-col-name');
        let keyDownCode = parseInt(document.getElementById('hdnKeyDownCode').getAttribute('value'));
        let focusElementIndex = parseInt(document.getElementById('hdnFocusIndex').getAttribute('value'));
        let elementToSwitchTo = null;
        this.colNames = [];

        for (let i = 0; i < fields.length; i++) {
          let curr = i;
          let value = fields[i].value;

          //Format col values
          if (i >= this.columns.length) {
            curr = curr - this.columns.length;
            this.colNames[curr] = value;
          }
          else {
            this.colNames.push(value);
          }
          this.columns[curr].displayName = value;
          this.columns[curr].name = value;

          //Check if keycode is special
            if (isKeyDown && keyDownCode == KeyCodes.KEY_TAB && curr == focusElementIndex) {
              elementToSwitchTo = i + 1;
            }
            else if ((keyDownCode == KeyCodes.KEY_ENTER || keyDownCode ==   KeyCodes.KEY_RETURN) && curr == focusElementIndex) {
              elementToSwitchTo = curr;
            }
        }

        //Check if we are moving focus to other element
        if (elementToSwitchTo != null) {
          //focus header
          if (keyDownCode == KeyCodes.KEY_ENTER || keyDownCode == KeyCodes.KEY_RETURN){
            this.selectAndEditCell(0, elementToSwitchTo);
          }
          //focus cell
          else{
            $(fields)[elementToSwitchTo].select();
          }
        }

        this.emitChanges();
      }
    }, 50)

  }
  selectAndEditCell(row:number, col:number, triggerEdit: boolean = false){
    this.hot.selectCell(row, col);
    if (triggerEdit) {
      this.hot.getActiveEditor().beginEditing();
    }
    return true;
  }
  inputHeaderRenderer(_self, colIndex) {
    let el = document.createElement('input');
    el.className = 'inp-col-name error';
    el.style.paddingLeft = '10px';
    el.style.paddingRight = '10px';
    el.style.width = '100px'
    el.setAttribute('value', this.columns[colIndex].displayName);
    el.setAttribute('onkeyup', 'document.getElementById("hdnKeyDownCode").value = event.keyCode; document.getElementById("inputChange").click()');
    el.setAttribute('onkeydown', 'document.getElementById("hdnKeyDownCode").value = event.keyCode; document.getElementById("keydownChange").click()');
    el.setAttribute('onfocus', 'document.getElementById("hdnFocusIndex").value = ' + colIndex + ';');
    return el.outerHTML;

  }
  ngOnInit() {

  }
}
