import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import * as _ from 'lodash';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AccountRoles } from '../_enums/accountRoles';
import { LoginResponse } from '../_models/Responses/Account/loginResponse';

@Injectable()
export class AuthenticatedStorage {
    private userKey = 'authenticatedUser';

    public userData(): LoginResponse {
        get: {
            let authenticatedUserString = localStorage.getItem(this.userKey);
            if (authenticatedUserString) {
                return JSON.parse(authenticatedUserString)
            }
        }
        set: data => {
            this.setData(data);
        }
    }

    setData(userData: LoginResponse){
        localStorage.setItem(this.userKey, JSON.stringify(userData));
    }
    clearData(){
        localStorage.clear();
    }
}