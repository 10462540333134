import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthenticatedStorage } from '../_helpers/localStorage';
import { AccountRoles } from '../_enums/accountRoles'
import { LoginResponse } from '../_models/Responses/Account/loginResponse';
import { MatSnackBar } from '@angular/material';
import { ComponentBase } from '../_base/component.base';
import { BaseResponse } from '../_models/Responses/baseResponse';
import { RoutesHelper } from '../_helpers/routes';

export class BasePage extends ComponentBase implements OnInit {
    public userData: LoginResponse;
    public accountRoles: AccountRoles;
    public router?: Router;
    public snackBar: MatSnackBar;
    public authenticated: AuthenticatedStorage = null;
    
    // Only pass in constructor objects if you need to use them in the constructor of the implmeneting class,
    // otherwise they will be loaded after
    constructor(public _roles?: number, public _isSystemUser?: boolean, public _router?: Router, private _snackbar?: MatSnackBar) {
        super();
        if (!this.authenticated){
            this.authenticated = new AuthenticatedStorage()
        }
        if (_router){
            this.router = _router;
        }
        if (_snackbar){
            this.snackBar = _snackbar;
        }
        if (!this.userData) {
            this.userData = this.authenticated.userData();
        }

        //Authentication check
        if (_roles == AccountRoles.User && !(this.userData && (this.userData.accountRoleId && AccountRoles.User)) ){
            this.router.navigate(['/login'], {queryParams: { red: document.location.pathname}} )
        }

        //System user group check
        else if (_isSystemUser && !(this.userData && this.userData.isSystemUser)){
            console.log("Restricted request (admin)", document.location.pathname);
            throw new Error('Restricted request(admin)');
            this.authenticated.clearData();
            this.router.navigate(['/login'])
        }

        //Role check
        else if (_roles > 0) {
            if (!(this.userData.accountRoleId && _roles)){
                console.log("Unathorized request (role)", document.location.pathname)
                throw new Error('Unathorized request(role)');
                this.authenticated.clearData();
                this.router.navigate(['/login'])
            }
        }

        if (this.userData && this.userData.forcePasswordReset && document.location.pathname != '/recover'){
            this.router.navigate(['/recover'])
        }
    }
    toggleToastMessage(message: string, duration?: number, verticalPosition?, panelClass?: string) {
        if (!message) {
            message = 'Saved!';
        }
        if (!verticalPosition) {
            verticalPosition = 'top';
        }
        if (!duration) {
            duration = 5000;
        }
        if (!panelClass){
            panelClass = 'success'
        }

        this.snackBar.open(message, '×', {
            verticalPosition: verticalPosition,
            duration: duration,
            panelClass: panelClass
        });
    }

    ngOnInit() {  
    }

    refreshRoute() {
        let routeHelper: RoutesHelper = new RoutesHelper(this.router);
        routeHelper.reloadCurrentRoute();
        // this.router.navigate([document.location.pathname], { queryParams: { refresh: 1 } });    //doesnt work
    }
    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        let routeHelper: RoutesHelper = new RoutesHelper(this._router);
        routeHelper.reloadCurrentRoute();
    }
}
