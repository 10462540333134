
export class ListData{
    public listId: number = null;
    public headersData: any;
    public listData: any;
    // public dtCreated?: string;
    // public creatorName?: string;
}
export class NewList extends ListData{
    public listName: string;
    public description: string;
    public originalFileName?: string;
    public isDeleted: boolean;
    public isPrivate: boolean;
}