import { Component, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { NewList } from '../../_models/Requests/Lists/newList';
import { FormUtilities } from '../../_utilities/formUtilities';

@Component({
  selector: 'app-list-edit-details',
  templateUrl: './list-edit-details.component.html',
  styleUrls: ['./list-edit-details.component.css']
})
export class ListEditDetailsComponent implements OnInit, OnChanges {
  public newListForm: FormGroup;
  @Input() details: any = {}; //TODO: Turn To Class
  @Output() form:any = new EventEmitter<FormGroup>();
  constructor(private fb: FormBuilder) {
    this.newListForm = this.returnIntializedForm();
   }

  ngOnInit() {
    this.form.emit(this.newListForm); //emit once and subscribe to changes
    this.newListForm.markAsTouched()
  }

  ngOnChanges(changes: SimpleChanges) {

    const detailsProp = changes['details'];
    if (detailsProp && detailsProp.currentValue) {
      this.details = detailsProp.currentValue;
      this.newListForm = this.returnIntializedForm(this.details);
      FormUtilities.triggerValidation(this.newListForm)
    }
  }

  returnIntializedForm(details: NewList = new NewList()): FormGroup{
    return this.fb.group(this.returnNewListFormControls(details))
  }
  returnNewListFormControls(details: NewList) {
    return {
      listId: [details.listId, null],
      listName: [details.listName, Validators.required],
      description: [details.description, null],
      isPrivate: [details.isPrivate, null],
      isDeleted: [details.isDeleted, null],
    }
  }
  resetForm(){
    this.newListForm.reset();
  }
}
