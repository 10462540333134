import { Component, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ROUTES, COMPONENT_DEMO_ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '@root/environments/environment';
import { AuthenticatedStorage } from '../../_helpers/localStorage';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  userData;
  public sidebarnavItems: any[];

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private authenticatedStorage: AuthenticatedStorage
  ) {
    this.userData = this.authenticatedStorage.userData();
   }
  // End open close
  ngOnInit() {
    if (!environment.production) {
      this.sidebarnavItems = ROUTES.concat(COMPONENT_DEMO_ROUTES).filter(sidebarnavItem => sidebarnavItem);
    }
    else {
      this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    }

    //Filter role based items //TODO: Make this DB based with navIds or RouteIDs
    this.sidebarnavItems = this.sidebarnavItems.filter(x=> !x.restrictedRoleId || ( (this.userData || {}).accountRoleId & x.restrictedRoleId) );
  }
}
