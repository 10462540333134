import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import * as _ from 'lodash';
import { LoginResponse } from '@models/Responses/Account/loginResponse';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthenticatedStorage } from '@app/_helpers/localStorage'
import { BaseResponse } from '@models/Responses/baseResponse';
import { UserAccount } from '../_models/Responses/Account/userAccountsResponse';
import { AccountRoles } from '../_enums/accountRoles';
import { RoleGroups } from '../_enums/roleGroups';

@Injectable()
export class LoginService {

    constructor(private apiService: ApiService, private http: HttpClient, private authenticatedStorage: AuthenticatedStorage) {

    }

    login(emailAddress: string, password: string, rememberMe: boolean) {
        let url = 'api/account/login';
        let param = { emailAddress: emailAddress, password: password, rememberMe: rememberMe };
        return this.http.get<LoginResponse>(url + this.apiService.generateParametersStr(param)).pipe(
            map(data => {
                if (data.access_token && data.isSuccess) {
                    this.authenticatedStorage.setData(data);
                }
                return data;
            }
        ))
    }

    logout(redirect: boolean = true) {
        let url = 'api/account/logout';
        let paramStr = '?uidAuthToken=' + this.authenticatedStorage.userData().access_token;
        return this.http.post<BaseResponse>(url + paramStr, {}).pipe(
            map(data => {
                this.authenticatedStorage.clearData()
                if (redirect) {
                    if (document.location.pathname.indexOf('/account') > -1 || document.location.pathname.indexOf('/recover') > -1) {
                        document.location.href = '/'
                    }
                    else {
                        document.location.href = document.location.pathname;
                    }
                }
            })
        )
    }

    resetPassword(newPassword: string, currentPassword: string, uidReset: string = null){
        let url = 'api/account/resetPassword';
        return this.http.post<BaseResponse>(url + this.apiService.generateParametersStr({newPassword: newPassword, currentPassword: currentPassword, uidReset: uidReset}), {} ).pipe(
            map(data => {
                if (data.isSuccess) {
                    let newData = this.authenticatedStorage.userData();
                    newData.forcePasswordReset = false;
                    this.authenticatedStorage.setData(newData);
                }
                return data;
            }
        ))
    }
    submitPasswordReset(emailAddress: string){
        let url = 'api/account/submitResetPassword';
        return this.http.post<BaseResponse>(url + this.apiService.generateParametersStr({emailAddress: emailAddress}), {});
    }
    
    getProfileDetails(){
        let url = 'api/account/getAccountDetails';
        return this.http.get<UserAccount>(url + this.apiService.generateParametersStr({ accountId: this.authenticatedStorage.userData().accountId }));
    }
    public GetAccountRoles() {
        return [
            { id: AccountRoles.User, name: AccountRoles[AccountRoles.User], roleGroupId: RoleGroups.Application, isSelected: false },
            { id: AccountRoles.Reporting, name: AccountRoles[AccountRoles.Reporting], roleGroupId: RoleGroups.System, isSelected: false },
            { id: AccountRoles.ApplicationAdministrator, name: AccountRoles[AccountRoles.ApplicationAdministrator], roleGroupId: RoleGroups.System, isSelected: false },
            { id: AccountRoles.Developer, name: AccountRoles[AccountRoles.Developer], roleGroupId: RoleGroups.System, isSelected: false },
            { id: AccountRoles.BetaTester, name: AccountRoles[AccountRoles.BetaTester], roleGroupId: RoleGroups.System, isSelected: false  }
        ]
    }
    getRolesStr(accountRoleId: number){
        let roles = [];
        this.GetAccountRoles().forEach(role=>{
            if (accountRoleId & role.id){
                roles.push(role.name)
            }
        })
        return roles.join(',')
    }
}