import { Component, OnInit } from '@angular/core';
import { SocketService } from './_services/socketService';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  connecting = false;
  connected = false;
  sending = false;
  enteredName = false;
  username: string;
  message: string;

  messages: any[] = [];
  
  
  //Initialize socketService
  constructor(private socketService: SocketService) {
  }
  ngOnInit() {
    // this.connecting = true;
    // this.connected = await this.hubService.connect({ url: environment.apiEndPoint + '/signalr' }).toPromise();
    // this.connecting = false;
    // this.socketService.onQueueItemCompleted.subscribe((chatMessage: any) => this.receiveServerPost(chatMessage));
  }
  
  
  // async sendMessage() {
  //   this.sending = true;
  //   const chatMessage = await this.socketService.sendMessage(this.username, this.message).toPromise();
  //   this.messages.push(chatMessage);
  //   this.message = "";
  //   this.sending = false;
  // }
  // receiveServerPost(message: any) {
  //   this.ngZone.run(() => {
  //     this.messages.push(message);
  //   });
  // }

}
