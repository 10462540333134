
import { Pipe, PipeTransform } from '@angular/core';
/*
 * Converts a datetime string to and from UTC
 * Takes an exponent argument that defaults to false.
 * Usage:
 *   value | toUTC
 * Example:
 *   {{ '09/02/2020 01:34:31' | toUTC: false }}
 *   formats to: 09/01/2020 18:34:31
*/
@Pipe({ name: 'timeZoneOffset' })
export class TimeZoneOffsetPipe implements PipeTransform {
    transform(value: string, toUTC: boolean = false): any {
        if (!value){
            return value;
        }
        let date = new Date(value);
        let offset = date.getTimezoneOffset();
        
        //Converting from UTC to local timezone
        if (!toUTC) {
            date.setMinutes(date.getMinutes() - offset);
        }

        //Converting from local timezone to UTC
        else {
            date.setMinutes(date.getMinutes() + offset);
        }
        return date;
    }
}

