import { RouteInfo } from './sidebar.metadata';
import { AccountRoles } from '../../_enums/accountRoles';
export const COMPONENT_DEMO_ROUTES = //TODO: Add Client management section for establishing client data across all services
  [{
    path: '',
    title: 'UI Components',
    iconClass: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '/component/accordion',
    title: 'Accordion',
    iconClass: 'mdi mdi-equal',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/alert',
    title: 'Alert',
    iconClass: 'mdi mdi-message-bulleted',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/carousel',
    title: 'Carousel',
    iconClass: 'mdi mdi-view-carousel',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/dropdown',
    title: 'Dropdown',
    iconClass: 'mdi mdi-arrange-bring-to-front',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/modal',
    title: 'Modal',
    iconClass: 'mdi mdi-tablet',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/pagination',
    title: 'Pagination',
    iconClass: 'mdi mdi-backburger',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/poptool',
    title: 'Popover & Tooltip',
    iconClass: 'mdi mdi-image-filter-vintage',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/progressbar',
    title: 'Progressbar',
    iconClass: 'mdi mdi-poll',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/rating',
    title: 'Ratings',
    iconClass: 'mdi mdi-bandcamp',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/tabs',
    title: 'Tabs',
    iconClass: 'mdi mdi-sort-variant',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/timepicker',
    title: 'Timepicker',
    iconClass: 'mdi mdi-calendar-clock',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/buttons',
    title: 'Button',
    iconClass: 'mdi mdi-toggle-switch',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/cards',
    title: 'Card',
    iconClass: 'mdi mdi-blur-radial',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  }]

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Data Services',
    iconClass: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '/services/businesslist',
    title: 'Business Data',
    matIcon: 'business',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/services/locationquery',
    title: 'Location Data Query',
    iconClass: 'fa fa-search',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/services/exhibitorsbymap',
    title: 'Exhibitors By Map',
    iconClass: 'fa fa-globe',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/services/employeesearch',
  //   title: 'Employee Data',
  //   matIcon: 'contacts',
  //   class: '',
  //   label: '',
  //   labelClass: '',
  //   extralink: false,
  //   submenu: []
  // },
 {
    path: '/services/contactsearch',
    title: 'Contact Data',
    matIcon: 'contacts',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/services/commodity-data',
    title: 'Commodity Data',
    iconClass: 'fa fa-truck',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/services/property-listings',
    title: 'Property Listings',
    iconClass: 'fa fa-home',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/services/marketplace-data',
    title: 'Marketplace Data',
    iconClass: 'fas fa-search-dollar',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/services/page-scraper',
    title: 'Page Scraper',
    iconClass: 'fab fa-safari',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Contact Services',
    iconClass: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '/services/emailer',
    title: 'Email',
    matIcon: 'email',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/templates',
    title: 'Templates',
    matIcon: 'article',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Data Results',
    iconClass: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '/results',
    title: 'View All Results',
    iconClass: 'mdi mdi-gauge',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/lists',
    title: 'View Lists',
    iconClass: 'fa fa-list-alt',
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '',
    title: 'Admin',
    restrictedRoleId: AccountRoles.ApplicationAdministrator,
    iconClass: '',
    class: 'nav-small-cap',
    label: '',
    labelClass: '',
    extralink: true,
    submenu: []
  },
  {
    path: '/accounts',
    title: 'Account Management',
    iconClass: 'fa fa-users',
    restrictedRoleId: AccountRoles.ApplicationAdministrator,
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/organizations',
    title: 'Organizations',
    iconClass: 'fa fa-users',
    restrictedRoleId: AccountRoles.ApplicationAdministrator,
    class: '',
    label: '',
    labelClass: '',
    extralink: false,
    submenu: []
  },
];
