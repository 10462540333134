import * as XLSX from 'xlsx';
import { MatTableDataSource } from '@angular/material';
import { RequestAddtionalColumnSuffix } from '../_helpers/crawlerSettings';

export class TableUtil {
  static exportToExcel(tableEl: string, name?: string) {
    let timeSpan = new Date().toISOString();
    let prefix = name || "ExportResult";
    let fileName = `${prefix}-${timeSpan}`;
    let wb = XLSX.utils.table_to_book(tableEl, <XLSX.Table2SheetOpts>{ sheet: prefix });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
  static returnSpreadsheetFile(data) {
    return XLSX.read(data)
  }
  static jsonToExcel(json: any[], name?: string) {
    let timeSpan = new Date().toLocaleDateString() + '_' + new Date().toLocaleTimeString();
    let prefix = name || "ExportResult";
    let fileName = `${prefix}-${timeSpan}`;
    let ws = XLSX.utils.json_to_sheet(json, <XLSX.JSON2SheetOpts>{ sheet: prefix });
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);

    // XLSX.utils.json_to_sheet(json, <XLSX.JSON2SheetOpts>{ sheet: prefix });
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
  static returnSpreadsheetFilePath(filePath) {
    return XLSX.readFile(filePath)
  }
  static previewExcelFile(workbook: XLSX.WorkBook) {
    let previewBodies = []
    let sheetCount: number = workbook.SheetNames.length;
    for (var i = 0; i < sheetCount; i++) {
      var sheet: XLSX.WorkSheet = workbook.Sheets[i]
      previewBodies.push(XLSX.utils.sheet_to_html(sheet))
    }
    return previewBodies;
  }
  static insertArrayAt(array, index, arrayToInsert) {
    Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
    return array;
  }
  static flattenObjectTypeProperty(record: any, propertyName: string, childPropertyName) {
    //flatten  column with its property
    let ret = Object.assign({}, record)
    if (ret[propertyName]) {
      let newVal = ret[propertyName][childPropertyName];
      ret[propertyName] = newVal;
    }
    return ret;
  }

  static getCrawlDataGridParentRow(dataSource: MatTableDataSource<any>,  crawlDataId: number, crawlStatusIdBit: number = null){
    if (dataSource && dataSource.data && dataSource.data.length > 0){
      let row = dataSource.data.find(x => x.crawlDataId == crawlDataId)
      if (crawlStatusIdBit){  
        return ((row.crawlStatus.id & crawlStatusIdBit) == crawlStatusIdBit)? row: null;
      }
      return row;
    
    }
    return null;
    
  }
  static getDataIndexByRowElementIndex(rowElIndex: number, pageIndex: number, pageSize: number) {
    return (rowElIndex + (pageSize * (pageIndex + 1)) - pageSize)
  }

  static columnNameHasAdditionalSuffix(colName: string){
    return (colName && colName.indexOf(RequestAddtionalColumnSuffix) > -1 && colName.split(RequestAddtionalColumnSuffix).length > 0)  //==1 prevents duplicates
  }

  static columnNameHasIncrimentSuffix(colName: string) {

    //Prefix check first
    if (!this.columnNameHasAdditionalSuffix(colName)) {
      return false;
    }

    //Number detected at end
    if (colName.length > (colName.indexOf(RequestAddtionalColumnSuffix) + RequestAddtionalColumnSuffix.length))
      return true;

    //No number present
    else {
      return false;
    }
  }

  static returnIncrimentSuffix(colName): string{
    if (!this.columnNameHasIncrimentSuffix(colName)){
      return '';
    }
    
    //Number detected at end
    return colName.substring((colName.indexOf(RequestAddtionalColumnSuffix) + RequestAddtionalColumnSuffix.length), colName.length);
  }

  static mapObjectToIndexProperties(obj: any): any {
    let newObj = {};
    if (!obj) {
      return obj;
    }
  
    Object.keys(obj).forEach((prop, i) => {
      newObj[i.toString()] = obj[prop];
    })
    return newObj;
  }
  static addMissingPropertyNames(obj: any, headersData: string[]) {
    headersData.forEach(prop => {
      if (typeof (obj[prop.toString()]) == 'undefined') {
        obj[prop] = null
      }
    })
  }
  static returnHeaderNamesFromArray(arr: any[]): string[] {
    let propNames = [];
    arr.forEach(x => {
      Object.keys(x).forEach(prop => {
        if (propNames.indexOf(prop) == -1) {
          propNames.push(prop)
        }
      })
    });
    return propNames;
  }

  /**
* Takes a column index and returns the corresponding column letter/name.
* @param {number} num  The index number to convert to a column letter/name.
* @return {string}  The column name.
*/
  static returnAlphabetLetterFromIndex(index: number) {  //Can use zero, limited to 'Z'
    return this.returnAlphabetLetterFromWholeNumber(index + 1)
  }

  /**
 * Takes a positive integer and returns the corresponding column letter/name.
 * @param {number} num  The positive integer to convert to a column letter/name.
 * @return {string}  The column name.
 */
  static returnAlphabetLetterFromWholeNumber(number: number) { //Can NOT use zero, NOT limited to 'Z'
    for (var ret = '', a = 1, b = 26; (number -= a) >= 0; a = b, b *= 26) {
      let numStr = ((number % b) / a).toString();
      ret = String.fromCharCode(parseInt(numStr) + 65) + ret;
    }
    return ret;

  }

  // static columnNameHasRequestDisplayText(colName: string){
  //   return (colName.indexOf(RequestColumnDisplaySuffix) == (colName.length - RequestColumnDisplaySuffix.length))
  // }
}