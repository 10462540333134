import { Injectable, OnDestroy, EventEmitter } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import * as _ from 'lodash';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthenticatedStorage } from '@app/_helpers/localStorage'
// import { BaseResponse } from '@models/Responses/baseResponse';
import { ResultsResponse } from '../_models/Responses/Results/resultsResponse';
import { CrawlDataResponse } from '../_models/Responses/Results/crawlDataResponse';
import { ListsResponse } from '../_models/Responses/Lists/listsResponse';
import { NewList } from '../_models/Requests/Lists/newList';
import { httpOptions } from './http.service';

import { SignalRModule, SignalR, SignalRConfiguration, IConnectionOptions, BroadcastEventListener } from 'ng2-signalr';
import { HubSubscription } from 'ngx-signalr-hubservice';
import { QueueItemCompleted, QueueItemStarted } from '../_models/Responses/Results/socketHubs';
@Injectable({ providedIn: 'root' })
// @Hub({hubName: 'DALHub'})

export class SocketService implements OnDestroy {
    // messageReceived = new EventEmitter<any>();
    public connectionIsEstablished: boolean;
    public onQueueItemCompleted = new EventEmitter<QueueItemCompleted>();   //TODO: Change to subject or BehaviorSubject as its better to subscribe to across the whole application
    public onQueueItemStarted = new EventEmitter<QueueItemStarted>();
    private _hubConnection;
    constructor(private _signalR: SignalR) {
        this.createConnection();
    }

    createConnection() {
        // let options: IConnectionOptions = { url: environment.apiEndPoint + '/api/sockets',  };
        this._signalR.connect().then((c) => {   //connect(options)
            this._hubConnection = c;
            this.connectionIsEstablished = true;
            this._hubConnection.listenFor('QueueItemCompleted').subscribe(data => {
                this.onQueueItemCompleted.next(JSON.parse(data));
            });

            this._hubConnection.listenFor('QueueItemStarted').subscribe(data => {
                this.onQueueItemStarted.next(JSON.parse(data));
            });
        });
    }
    // sendMessage(message: any) {
    //     this._hubConnection.invoke('NewMessage', message);
    // }

    //   public sendMessage(username: string, message: string): Observable<ChatMessage> {
    //     return this.hubWrapper.invoke<boolean>("sendMessage", username, message).pipe(map(_ => {
    //       return {
    //         username,
    //         message
    //       };
    //     }));
    //   }

    ngOnDestroy() {
        // this._hubConnection.stop();
    }
}