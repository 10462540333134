import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { CommonUtil } from '../../_utilities/common';
import { ColumnData } from '../../_models/common/commonData';

@Component({
  selector: 'app-additional-columns',
  templateUrl: './additional-columns.component.html',
  styleUrls: ['./additional-columns.component.css']
})
export class AdditionalColumnsComponent implements OnInit {
  @Input() headerData: ColumnData[];    //TODO: Turn to class and use in app-additonal-columns AND Crawlers (in editorOutputData.headers)
  // @Input() appendSuffix: string;
  @Output() changed = new EventEmitter<ColumnData[]>();
  
  public checkedRowData: ColumnData[];

  constructor() {
    this.checkedRowData = [];
  }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    const dataProp = changes['headerData'];
    // const suffixProp = changes['appendSuffix'];
    
    // if (suffixProp && suffixProp.currentValue) {
    //   this.appendSuffix = suffixProp.currentValue;
    // }
    
    //Make sure data has value that isnt equal to last
    if (dataProp && dataProp.currentValue && !CommonUtil.objectContainsChild(dataProp.currentValue, dataProp.previousValue) ) {
      this.headerData = dataProp.currentValue;
    }
  }
  isChecked(header: ColumnData){
    return (this.checkedRowData.find(x=> x.name == header.name) != null)
  }
  columnChecked(e, header: ColumnData) {
    let existingIndex = this.checkedRowData.indexOf(header);
    // let newCols;
    if (e.checked) {
      if (existingIndex == -1) {
        this.checkedRowData.push(header)
      }
    }
    else {
      if (existingIndex > -1) {
        this.checkedRowData.splice(existingIndex, 1);// this.checkedRowData = this.checkedRowData.filter((x,i)=> i != existingIndex);
      }
    }
    
    // this.changed.emit(newCols);
    // //Appending suffix before emitting //Moved to CrawlerPage.ts - submit()
    // if (this.appendSuffix) {
    //   newCols = this.checkedRowData.map(c => {
    //     let newObj: ColumnData = JSON.parse(JSON.stringify(c)); //reinitializing to prevent changing our own version by reference
    //     newObj['displayName'] = newObj['displayName'] + this.appendSuffix;
    //     return newObj;
    //   })

    // }

    // //No suffix to add
    // else{
    //   newCols = this.checkedRowData;
    // }
    
    this.changed.emit(this.checkedRowData);
  }
  checkAll(e) {
    this.checkedRowData = [];
    if (e.checked) {
      this.headerData.forEach(x => { this.checkedRowData.push(x) })
    }
  }
}
