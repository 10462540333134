import { Directive, ElementRef, Input, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import * as $ from 'jquery';

@Directive ({
    selector: '[loading]'
  })
  export class LoadingDirective  implements OnChanges {
    public spinnerSrc: string = '../assets/images/loader.svg';
    public spinnerSrcWhite: string = '../assets/images/loader-white.svg';
    public isImage: boolean = false;
    public isButton: boolean = false;
    public instantFade: boolean = false;
    element: HTMLElement;
    spinnerContainer: any;
    watermarkContainer: any;
    loadingSettings: LoadingSettings = new LoadingSettings();

    @Input() loading: boolean;
    constructor(_elementRef: ElementRef) {
      this.element = jQuery(_elementRef.nativeElement)[0] as HTMLElement;
      let settingsAttr = this.element.getAttribute('loadingSettings');    //Why not use a directive? so we dont have to keep stringifying

      if (settingsAttr){
        this.loadingSettings = JSON.parse(settingsAttr) || this.loadingSettings;
      }
      
      if (this.element.tagName == 'IMG'){
        this.intiailizeImage();
      }
      else if (this.element.tagName == 'LABEL'){
        this.initializeLabel();
      }
      else if (this.element.tagName == 'BUTTON'){
        this.initializeButton();
      }
      else{
        this.initializeGenericContainer()
      }
      
    }
   
    initializeLabel(){
      this.instantFade = true;
      let img  = document.createElement('img');
      img.style.width = '25px';
      img.style.height = '25px';
      img.style.display = 'none';
      img.src = this.spinnerSrc;
      this.spinnerContainer = img;
      this.watermarkContainer = {};
      this.element.appendChild(this.spinnerContainer);
    }
    
    initializeButton(){
      this.instantFade = true;
      this.isButton = true;
      let img  = document.createElement('img');
      img.style.width = '25px';
      img.style.height = '25px';
      img.style.display = 'none';
      img.className = 'pull-left';
      img.style.marginTop = '20%';
      img.style.marginLeft = '10%';
      img.src = this.spinnerSrcWhite;
      
      this.spinnerContainer = img;
      this.watermarkContainer = {};
      this.element.appendChild(this.spinnerContainer);
    }
  
    intiailizeImage(){
      this.isImage = true;
      this.element.setAttribute('src', this.spinnerSrc)
    }
    ngOnChanges(changes: SimpleChanges){
      const spinningLoaderProp = changes['loading'];
      if (spinningLoaderProp && (spinningLoaderProp.currentValue || spinningLoaderProp.currentValue == false)) {
        this.loading = spinningLoaderProp.currentValue

        let settingsAttr = this.element.getAttribute('loadingSettings');    //Why not use a directive? so we dont have to keep stringifying

        if (settingsAttr){
          this.loadingSettings = JSON.parse(settingsAttr) || this.loadingSettings;
        }

        if (!this.isImage) {
          this.toggleSpinner()
        }

      }
    }
  
    toggleSpinner() {
      //Show spinner
      if (this.loading) {
        let fadeCount = (this.instantFade ? 0 : 100);
        $([this.spinnerContainer, this.watermarkContainer]).fadeIn(fadeCount);
        
        //if button then hide button content/text
        if (this.isButton){
          this.element.style.textIndent = '-9000px'
        }
      }
      //Hide spinner
      else {
        let fadeCount = (this.instantFade ? 0 : 400);
        $([this.spinnerContainer, this.watermarkContainer]).fadeOut(fadeCount)

        //if button then show button content/text
        if (this.isButton){
          this.element.style.textIndent = '';
        }
      }
    }

    initializeGenericContainer(){
     
      var container = document.createElement('div');
      var watermark = document.createElement('div');
  
      container = this.addComonStyle(container)
      watermark = this.addComonStyle(watermark)
  
      container.className = 'spinner-loader';
      watermark.className = 'spinner-watermark';
  
      watermark.style.backgroundColor = 'black';
      watermark.style.opacity = '.5';
  
      if (this.loadingSettings.showSpinner) {
        var img = document.createElement('img');
        img.src = this.spinnerSrc;
        img.className = 'spinner-loader'
        img.style.maxWidth = '80px'
        img.style.maxWidth = '80px'
        img.style.marginLeft = "25%";
        img.style.marginRight = "25%";
        container.appendChild(img);
      }
      // $(container).append('<mat-spinner></mat-spinner>');
      // $(container).append('<svg focusable="false" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100" style="width: 100px; height: 100px;"><!----><circle cx="50%" cy="50%" r="45" class="ng-star-inserted" style="animation-name: mat-progress-spinner-stroke-rotate-100; stroke-dasharray: 282.743px; stroke-width: 10%;"></circle><!----></svg>');
      this.spinnerContainer = container;
      this.watermarkContainer = watermark;
  
      this.element.appendChild(this.spinnerContainer);
      this.element.appendChild(this.watermarkContainer);
    }
  
    addComonStyle(el){
      el.style.display = 'none'
      el.style.position = 'absolute';
      el.style.top = '0';
      el.style.right = '0';
      el.style.width = '100%';
      el.style.height = '100%';
      el.style.zIndex = '181';//  8 is body. 159 is handsontable headers,180 is handsontable rows */
      el.style.textAlign = 'center'
  
      return el;
    }
  }
  
  export class LoadingSettings{
    showSpinner: boolean = true;
  }